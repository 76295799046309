/* eslint-disable react/no-multi-comp */
/* eslint-disable jsdoc/require-param */
/* eslint-disable no-nested-ternary */
/**
 * @module PlanSubmitter
 */
import { Box } from '@mui/material';
import { useAuth } from 'auth';
import { MissingNarratedAudioDay } from 'context';
import { submissionStatuses } from 'helpers/constants';

import { TransferButton } from '../AdminBar/Buttons/TransferButton';
import { ApproveAllButton } from '../approve-all-button';
import { ExportPlanButton, UnlinkPlanButton } from '../Buttons';
import SubmitPlanButton from '../submit-plan-button';
import { Plan } from '../types';

interface PlanSubmitterProps {
  getMissingNarratedAudioDays?: () => Array<MissingNarratedAudioDay> | null;
  plan: Plan.Plan;
  planDays: Array<Plan.Day>;
  planLanguage: Language;
}

/**
 * Module for submitting, exporting and approving plans.
 *
 * @returns {ReactElement} - Buttons for specific state.
 */
export default function PlanSubmitter({
  plan,
  planDays,
  planLanguage,
  getMissingNarratedAudioDays,
}: PlanSubmitterProps) {
  const { user } = useAuth();

  if (!user) {
    return null;
  }

  const planStatus = plan.overall_status;

  return (
    <Box display='flex' flexDirection='row'>
      {user.can('edit_unpublished:plan_day') && planStatus === submissionStatuses.SUBMITTED ? (
        <ApproveAllButton plan={plan} planStatus={planStatus} />
      ) : user.can('create:plan_export') && planStatus !== submissionStatuses.DRAFT ? (
        <ExportPlanButton planId={plan.id} publishable={plan.publishable} />
      ) : (
        <SubmitPlanButton
          getMissingNarratedAudioDays={getMissingNarratedAudioDays}
          plan={plan}
          planDays={planDays}
          planLanguage={planLanguage}
        />
      )}
      {/* TransferButton and UnlinkButton is rendered here for now, but will be moved the AdminBar */}
      {user.can('administrate:all_permissions') && <TransferButton planId={plan.id} />}
      {user.can('administrate:all_permissions') && plan.source_id ? <UnlinkPlanButton planId={plan.id} /> : null}
    </Box>
  );
}
