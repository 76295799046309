/** @module DevotionalContentBlocks */
import { Box, LinearProgress, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DirectUpload } from '@rails/activestorage';
import { actionTypes, BlockEditor, blockReducer, defaultBlockTypes } from '@youversion/mui-block-editor';
import { AsyncButton, RoundedButton, useAlert } from '@youversion/react';
import { statusTypes } from '@youversion/utils';
import { attachFile, isAttachmentFileType } from 'api/active-storage';
import { convertHtmlToBlocks } from 'api/plans';
import { useAuth } from 'auth';
import { AudioBlockText } from 'components/Plans/devotional-content-blocks/audio-block-text';
import { API_ADDRESS, FEATURE_ENABLE_YV_VIDEO, FILE_MAX_BYTE_SIZE, IMAGEPROXY_URL } from 'helpers/constants';
import { usePreloadBlockData } from 'helpers/content-blocks/use-preload-block-data';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Plan } from '../types';

const useStyles = makeStyles(() => ({
  loadedBlockEditorWrapper: {
    // Hard-coded this value based on 4-5 block buttons.
    // Other solutions weren't obvious, but it did seem like it was going
    // to be implementation specific in the short term, so I'm accounting
    // for this space here in Partner Portal.
    // See: https://lifechurch.atlassian.net/browse/PP-522
    minHeight: 230,
  },
}));

export interface DevotionalContentBlocksType {
  /** An array of devotional content blocks to render. */
  blocks: Array<Plan.DevotionalContentBlocks>;
  /** Function to sync block state with its parent component. */
  onChange: (blocks: Array<Plan.DevotionalContentBlocks>) => void;
  /** The meta data associated with a plan. */
  planMeta: Plan.PlanMeta;
}

const ENV_FEATURE_ENABLE_YV_VIDEO = FEATURE_ENABLE_YV_VIDEO;

const DevotionalContentBlocks: React.FC<DevotionalContentBlocksType> = ({
  blocks: sourceBlocks,
  onChange,
  planMeta,
}) => {
  const { t } = useTranslation(['plan_days', 'common']);
  const [query] = useSearchParams();
  const classes = useStyles();
  const { user } = useAuth();
  const [blocks, blocksDispatch] = React.useReducer(blockReducer, sourceBlocks);

  const { throwAlert } = useAlert();

  const loadingStatus = usePreloadBlockData({
    callback: processedBlocks =>
      blocksDispatch({
        callback: () => {
          return processedBlocks;
        },
        actionType: '',
        blockId: '',
        content: {},
        contentParams: {},
        supportedBlocks: {},
      }),
    initialBlocks: sourceBlocks,
    planStatus: planMeta.status,
  });

  const PARAMS_FEATURE_ENABLE_YV_VIDEO = query.get('FEATURE_ENABLE_YV_VIDEO');

  const renderVideoBlock = useMemo(() => {
    let renderYvBlock = false;

    if (ENV_FEATURE_ENABLE_YV_VIDEO === '1') {
      renderYvBlock = true;
    }

    // params overrides env.
    if (PARAMS_FEATURE_ENABLE_YV_VIDEO === '0') {
      renderYvBlock = false;
    }

    if (PARAMS_FEATURE_ENABLE_YV_VIDEO === '1') {
      renderYvBlock = true;
    }

    return {
      VIDEO: !renderYvBlock,
      YV_VIDEO: renderYvBlock,
    };
  }, [PARAMS_FEATURE_ENABLE_YV_VIDEO]);

  /**
   * This syncs block state with its parent component.
   */
  React.useEffect(() => {
    onChange(blocks);
  }, [blocks, onChange]);

  async function handleConvertHtmlToBlocks() {
    try {
      if (sourceBlocks[0]?.content?.html) {
        const response = await convertHtmlToBlocks(sourceBlocks[0]?.content?.html);
        blocksDispatch({
          actionType: actionTypes.update,
          callback: (prevState: Array<Plan.DevotionalContentBlocks>) => {
            const prevStateCopy = [...prevState];
            prevStateCopy.shift();
            return [...response, ...prevStateCopy];
          },
          blockId: '',
          content: {},
          contentParams: {},
          supportedBlocks: {},
        });
      }
    } catch (error) {
      if (error instanceof Error) {
        throw error;
      }
    }
  }

  /**
   * Handle uploading a file to AWS then posting to rails.
   *
   * @param {object} file - The file object.
   *
   * @returns {Promise<object>} - The block editor file data. `{file_id, source_url, ...others}`.
   */
  const handleFileUpload = React.useCallback(
    async (file: File) => {
      return new Promise((resolve, reject) => {
        const fileType = file.type.split('/')[0];
        if (fileType === 'image' && file.size > FILE_MAX_BYTE_SIZE) {
          const errorMessage = t('common:image_upload_size_failed', {
            size: '2MB',
          });
          throwAlert({
            id: 'file_validation_failed',
            key: 'file_validation_failed',
            message: errorMessage,
            timeout: 3000,
            type: statusTypes.ERROR,
          });
          return reject(Error(errorMessage));
        }

        const url = `${API_ADDRESS}/v1/direct_uploads`;
        const upload = new DirectUpload(file, url);

        upload.create(async (error, blob) => {
          if (error) {
            reject(new Error(error.message));
          } else {
            const fileBaseType = blob.content_type.split('/')[0];

            if (isAttachmentFileType(fileBaseType)) {
              throwAlert({
                id: 'file_uploaded_name',
                key: blob.signed_id,
                message: t('plan_days:plan_day_edit.devotional_content.file_being_uploaded_message', {
                  filename: blob.filename,
                }),
                timeout: 3000,
                type: statusTypes.INFO,
              });
            } else {
              reject(new Error(`Invalid file type ${fileBaseType}.`));
            }
            switch (fileBaseType) {
              // Cases are not alphabetized in order to take advantage of switch/case redundancy duplication strategy.
              case 'image': {
                try {
                  const fileAttachment = await attachFile({
                    fileType: fileBaseType,
                    organizationId: planMeta.primaryOrganizationId,
                    signedId: blob.signed_id,
                  });
                  if (fileAttachment) {
                    resolve({
                      display_url: `${IMAGEPROXY_URL}/${fileAttachment.file_url}`,
                      file_id: fileAttachment.id,
                      source_url: fileAttachment.file_url,
                    });
                  }
                } catch (railsError) {
                  if (railsError instanceof Error) {
                    reject(railsError);
                  }
                }
                break;
              }
              case 'audio':
              case 'yv_video':
              case 'video': {
                try {
                  const fileAttachment = await attachFile({
                    fileType: fileBaseType,
                    organizationId: planMeta.primaryOrganizationId,
                    signedId: blob.signed_id,
                  });
                  if (fileAttachment) {
                    resolve({
                      file_id: fileAttachment.id,
                      source_url: fileAttachment.file_url,
                    });
                  }
                } catch (railsError) {
                  if (railsError instanceof Error) {
                    reject(railsError);
                  }
                }
                break;
              }
              default:
                reject(
                  new Error(
                    t('plan_days:plan_day_edit.devotional_content.unsupported_file_type_message', {
                      fileType: blob.content_type,
                    })
                  )
                );
            }
          }
        });
      });
    },
    [planMeta.primaryOrganizationId, t, throwAlert]
  );

  const customConfig = {
    blockTypes: {
      audio: {
        ...defaultBlockTypes.audio,
        addLabel: t('plan_days:plan_day_edit.devotional_content.audio.add_label'),
        dropAreaDownloadText:
          planMeta.status === 'approved'
            ? t('plan_days:plan_day_edit.devotional_content.audio.preview_message')
            : t('plan_days:plan_day_edit.devotional_content.audio.download_file_content'),
        dropAreaEmptyText: <AudioBlockText />,
        dropAreaActiveFileNotSupportedText: t(
          'plan_days:plan_day_edit.devotional_content.audio.unsupported_file_type_message'
        ),
        dropAreaActiveUploadText: t('plan_days:plan_day_edit.devotional_content.audio.active_upload_message'),
        dropAreaEmptyUploadButtonLabel: t(
          'plan_days:plan_day_edit.devotional_content.audio.upload_button_label'
        ).toUpperCase(),
        dropAreaDownloadButtonLabel: t('plan_days:plan_day_edit.devotional_content.audio.download_file_label'),
        dropAreaErrorRetryLabel: t('plan_days:plan_day_edit.devotional_content.audio.retry_failed_upload_button_label'),
        dropAreaPendingText: t('plan_days:plan_day_edit.devotional_content.audio.pending_message'),
        dropAreaUploadingText: t('plan_days:plan_day_edit.devotional_content.audio.uploading_message'),
      },
      image: {
        ...defaultBlockTypes.image,
        addLabel: t('plan_days:plan_day_edit.devotional_content.image.add_label'),
        dropAreaEmptyText: t('plan_days:plan_day_edit.devotional_content.image.drag_and_drop_message'),
        dropAreaDownloadText:
          planMeta.status === 'approved'
            ? t('plan_days:plan_day_edit.devotional_content.image.preview_message')
            : t('plan_days:plan_day_edit.devotional_content.image.download_file_content'),
        dropAreaActiveFileNotSupportedText: t(
          'plan_days:plan_day_edit.devotional_content.image.unsupported_file_type_message'
        ),
        dropAreaActiveUploadText: t('plan_days:plan_day_edit.devotional_content.image.active_upload_message'),
        dropAreaEmptyUploadButtonLabel: t(
          'plan_days:plan_day_edit.devotional_content.image.upload_button_label'
        ).toUpperCase(),
        dropAreaDownloadButtonLabel: t('plan_days:plan_day_edit.devotional_content.image.download_file_label'),
        dropAreaErrorRetryLabel: t('plan_days:plan_day_edit.devotional_content.image.retry_failed_upload_button_label'),
        dropAreaPendingText: t('plan_days:plan_day_edit.devotional_content.image.pending_message'),
        dropAreaUploadingText: t('plan_days:plan_day_edit.devotional_content.image.uploading_message'),
      },
      readonly: {
        ...defaultBlockTypes.readonly,
      },
      soundcloud_audio: {
        ...defaultBlockTypes.soundcloud_audio,
      },
      text: {
        ...defaultBlockTypes.text,
        addLabel: t('plan_days:plan_day_edit.devotional_content.text.add_label'),
        defaultPlaceholder: t('plan_days:plan_day_edit.devotional_content.text.placeholder'),
        toolbarOptions: {
          blockType: {
            inDropdown: true,
            options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'Blockquote'],
          },
          inline: {
            options: ['bold', 'italic'],
          },
          list: {
            inDropdown: true,
            options: ['unordered', 'ordered'],
          },
          options: ['inline', 'blockType', 'list', 'link'],
          textAlign: { inDropdown: true },
        },
      },
      video: {
        ...defaultBlockTypes.video,
        addLabel: t('plan_days:plan_day_edit.devotional_content.video.add_label'),
        dropAreaEmptyText: t('plan_days:plan_day_edit.devotional_content.video.drag_and_drop_message'),
        dropAreaDownloadText:
          planMeta.status === 'approved'
            ? t('plan_days:plan_day_edit.devotional_content.video.preview_message')
            : t('plan_days:plan_day_edit.devotional_content.video.download_file_content'),
        disableCreate: !renderVideoBlock.VIDEO,
        dropAreaActiveFileNotSupportedText: t(
          'plan_days:plan_day_edit.devotional_content.video.unsupported_file_type_message'
        ),
        dropAreaActiveUploadText: t('plan_days:plan_day_edit.devotional_content.video.active_upload_message'),
        dropAreaEmptyUploadButtonLabel: t(
          'plan_days:plan_day_edit.devotional_content.video.upload_button_label'
        ).toUpperCase(),
        dropAreaDownloadButtonLabel: t('plan_days:plan_day_edit.devotional_content.video.download_file_label'),
        dropAreaErrorRetryLabel: t('plan_days:plan_day_edit.devotional_content.video.retry_failed_upload_button_label'),
        dropAreaPendingText: t('plan_days:plan_day_edit.devotional_content.video.pending_message'),
        dropAreaUploadingText: t('plan_days:plan_day_edit.devotional_content.video.uploading_message'),
      },
      yv_video: {
        ...defaultBlockTypes.yv_video,
        addLabel: t('plan_days:plan_day_edit.devotional_content.video.add_label'),
        dropAreaEmptyText: t('plan_days:plan_day_edit.devotional_content.video.drag_and_drop_message'),
        dropAreaDownloadText:
          planMeta.status === 'approved'
            ? t('plan_days:plan_day_edit.devotional_content.video.preview_message')
            : t('plan_days:plan_day_edit.devotional_content.video.download_file_content'),
        disableCreate: !renderVideoBlock.YV_VIDEO,
        dropAreaActiveFileNotSupportedText: t(
          'plan_days:plan_day_edit.devotional_content.video.unsupported_file_type_message'
        ),
        dropAreaActiveUploadText: t('plan_days:plan_day_edit.devotional_content.video.active_upload_message'),
        dropAreaEmptyUploadButtonLabel: t(
          'plan_days:plan_day_edit.devotional_content.video.upload_button_label'
        ).toUpperCase(),
        dropAreaDownloadButtonLabel: t('plan_days:plan_day_edit.devotional_content.video.download_file_label'),
        dropAreaErrorRetryLabel: t('plan_days:plan_day_edit.devotional_content.video.retry_failed_upload_button_label'),
        dropAreaPendingText: t('plan_days:plan_day_edit.devotional_content.video.pending_message'),
        dropAreaUploadingText: t('plan_days:plan_day_edit.devotional_content.video.uploading_message'),
      },
      youtube_video: {
        ...defaultBlockTypes.youtube_video,
        addLabel: t('plan_days:plan_day_edit.devotional_content.yt_video.add_label'),
        disableCreate: user ? !user.can('plan_day:content_blocks:youtube_video_block:embed') : true,
        inputPlaceholder: t('plan_days:plan_day_edit.devotional_content.yt_video.placeholder'),
        label: t('plan_days:plan_day_edit.devotional_content.yt_video.label'),
        previewHeading: t('plan_days:plan_day_edit.devotional_content.yt_video.preview_heading'),
        addVideoButtonLabel: t('plan_days:plan_day_edit.devotional_content.yt_video.add_video_button_label'),
      },
    },
    strings: {
      addBlock: t('plan_days:plan_day_edit.devotional_content.add_block'),
      moveBlock: t('plan_days:plan_day_edit.devotional_content.move_block'),
      deleteBlockLabel: t('plan_days:plan_day_edit.devotional_content.delete_block_label'),
      confirmDeleteBlockButtonLabel: t('plan_days:plan_day_edit.devotional_content.confirm_delete_block_button_label'),
      confirmDeleteBlockContent: t('plan_days:plan_day_edit.devotional_content.confirm_delete_block_content'),
      denyDeleteBlockButtonLabel: t('plan_days:plan_day_edit.devotional_content.deny_delete_block_button_label'),
      moveBlockLabel: t('plan_days:plan_day_edit.devotional_content.move_block'),
    },
    handleFileUpload,
  };
  /**
   * Displayed when editor content is loaded successfully and ready.
   */
  if (loadingStatus === statusTypes.RESOLVED) {
    return (
      <div className={classes.loadedBlockEditorWrapper}>
        {sourceBlocks?.length && sourceBlocks[0].type === 'readonly' ? (
          <Box display='flex' justifyContent='center'>
            <AsyncButton
              color='primary'
              component={RoundedButton}
              idle={t('plan_days:plan_day_edit.devotional_content.convert_to_editable_block.idle')}
              onClick={handleConvertHtmlToBlocks}
              options={{
                disableUpdateOnSuccess: true,
              }}
              pending={t('plan_days:plan_day_edit.devotional_content.convert_to_editable_block.loading')}
              rejected={t('plan_days:plan_day_edit.devotional_content.convert_to_editable_block.rejected')}
              variant='outlined'
            />
          </Box>
        ) : null}
        <BlockEditor blocks={sourceBlocks} config={customConfig} dispatch={blocksDispatch} />
      </div>
    );
  }
  if (loadingStatus === statusTypes.PENDING) {
    return (
      <React.Fragment>
        <Box alignItems='center' display='flex' flexDirection='column' justifyContent='center' width='100%'>
          <Box mb={2} width='50%'>
            <LinearProgress />
          </Box>
          <Typography variant='subtitle1'>
            {t('plan_days:plan_day_edit.devotional_content.loading_content_blocks')}
          </Typography>
        </Box>
      </React.Fragment>
    );
  }
  if (loadingStatus === statusTypes.REJECTED) {
    return (
      <React.Fragment>
        <Box alignItems='center' display='flex' flexDirection='column' justifyContent='center' width='100%'>
          <Typography variant='subtitle1'>
            {t('plan_days:plan_day_edit.devotional_content.error_loading_content_blocks')}
          </Typography>
        </Box>
      </React.Fragment>
    );
  }
  return null;
};

export default DevotionalContentBlocks;
