/* eslint-disable jsdoc/require-param */
/**
 * @module Pagination
 */
import { Box, Fade, Pagination, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FetchOrganizationMemberships } from 'api/organization_memberships/types';
import { ReactElement } from 'react';
import { useSearchParams } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  pagination: {
    alignSelf: 'stretch',
    borderBlockStart: `1px solid ${theme.palette.divider}`,
  },
}));

interface PaginationComponentProps {
  /** Indicates status of fetching organization-membership. */
  dataIsPending: boolean;
  /** Function to get pagination string. */
  getPaginationString: () => string;
  /** Organization-membership pagination data. */
  meta?: FetchOrganizationMemberships.OrganizationMembershipsMeta;
}

/**
 * Returns the pagination component.
 *
 * @returns {ReactElement} - Returns jsx.
 */
export function PaginationComponent({ dataIsPending, meta, getPaginationString }: PaginationComponentProps) {
  const [query, setQuery] = useSearchParams();
  const classes = useStyles();

  return (
    <Fade in={!dataIsPending}>
      <Box
        alignItems='center'
        className={classes.pagination}
        data-testid='organization-membership-grid-pagination-bar'
        display='flex'
        flexGrow={1}
        justifyContent='space-between'
        width='100%'
      >
        <Pagination
          count={meta?.total_pages ?? 0}
          onChange={(event, newPage) => {
            query.set('page', newPage.toString());
            setQuery(query);
          }}
          page={meta?.current_page ?? 0}
        />
        <Box alignItems='center' display='flex' justifyContent='flex-end' pr={2}>
          <Typography color='textSecondary' variant='body1'>
            {!dataIsPending ? getPaginationString() : ''}
          </Typography>
        </Box>
      </Box>
    </Fade>
  );
}
