/**
 * @module PhotoAttachment
 */
import { CircularProgress } from '@mui/material';
import fallbackUserAvatar from 'assets/fallback_user_avatar.svg';
import { createFactory, ReactElement } from 'react';
import ImageLoader from 'react-imageloader';

import styles from '../misc/Misc.module.scss';

interface PhotoAttachmentProps {
  isLoading?: boolean;
  placeholder?: ReactElement;
  url?: string;
}

export function PhotoAttachment({ isLoading, placeholder, url }: PhotoAttachmentProps) {
  const isFallbackAvatar = url === '/fallback_user_avatar.svg';
  const imgProps = {
    style: {
      height: isFallbackAvatar ? 90 : '100%',
      width: isFallbackAvatar ? 90 : '100%',
    },
  };

  let output = (
    <ImageLoader
      imgProps={imgProps}
      preloader={() => <CircularProgress />}
      src={url ?? ''}
      style={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
      }}
      wrapper={createFactory('div')}
    >
      <img
        alt='user avatar'
        data-testid='profile-fallback-user-avatar'
        src={fallbackUserAvatar}
        style={{
          height: '90px',
          width: '90px',
        }}
      />
    </ImageLoader>
  );

  if (!url) {
    output = placeholder ?? <img alt='user avatar' src={fallbackUserAvatar} />;
  }

  if (isLoading) {
    output = <CircularProgress />;
  }

  return (
    <div className={styles.photoAttachWrapper} data-testid='profile-user-avatar'>
      {output}
    </div>
  );
}
