import { Box } from '@mui/material';
import { BASE_TITLE } from 'helpers/constants';
import { FC, ReactNode } from 'react';
import Helmet from 'react-helmet';

interface Props {
  children: ReactNode;
}
const SignUpFormWrapper: FC<Props> = ({ children }) => {
  return (
    <Box display='flex' flexDirection='column' height='100%' justifyContent='space-between'>
      <Helmet title={`${BASE_TITLE} | Sign Up`} />

      {children}
    </Box>
  );
};

export default SignUpFormWrapper;
