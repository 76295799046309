/* eslint-disable jsdoc/require-param */
import { useTranslation } from 'react-i18next';

import { GroupsList } from './groups-list';
import { GroupsSelect } from './groups-select';
import styles from './styles.module.scss';

interface GroupsSelectProps {
  /** Available groups. */
  groups: Array<Group>;
  /** Function to refetch group membership data.  */
  refetchGroupMemberships: () => void;
  /** User data. */
  user: User;
  /** Groups user belongs to. */
  userGroupMemberships: Array<GroupMembership>;
}

/**
 * Add a group to user.
 *
 * @returns {ReactElement} - Returns jsx.
 */
export function ManageGroupMemberships({
  user,
  groups,
  userGroupMemberships,
  refetchGroupMemberships,
}: GroupsSelectProps) {
  const hasGroupMemberships = userGroupMemberships.length !== 0;
  const hasGroups = groups.length !== 0;
  const { t } = useTranslation('users');

  return (
    <div className={styles.groupsContainer}>
      <h3>{t('groups.groups')}</h3>
      {hasGroupMemberships ? <GroupsList memberships={userGroupMemberships} refetch={refetchGroupMemberships} /> : null}
      {hasGroups ? (
        <GroupsSelect groups={groups} refetch={refetchGroupMemberships} user={user} userGroups={userGroupMemberships} />
      ) : null}
    </div>
  );
}
