/**
 * @module LoadingOverlay
 */
import { Box, Typography } from '@mui/material';
import bibleLensLoadingLottie from 'assets/lottie/bible-lens-loading.min.json';
import Lottie from 'lottie-react';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Returns a lottie loader.
 *
 * @returns {ReactElement} - Returns jsx.
 */
export function LoadingOverlay() {
  const { t } = useTranslation('plans');

  return (
    <Box alignItems='center' display='flex' height='100%' justifyContent='center' position='absolute' width='100%'>
      <div>
        <Lottie
          animationData={bibleLensLoadingLottie}
          aria-label={t('plans_loader')}
          style={{ filter: 'invert(1)', height: 100, width: 100 }}
        />
        <Typography align='center' color='inherit' variant='body1'>
          {t('loading_plans')}
        </Typography>
      </div>
    </Box>
  );
}
