/**
 * @module GroupEdit
 */
import { Box, TextField } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useAlert } from '@youversion/react';
import { useGetGroup, useUpdateGroup } from 'api/groups';
import formStyles from 'components/Groups/Form/styles.module.scss';
import styles from 'components/Groups/Groups.module.scss';
import LoaderOverlay from 'components/LoaderOverlay';
import { PaneContent } from 'components/misc';
import { Button, Toolbar } from 'components/Navigation/Toolbar';
import { Form, Formik } from 'formik';
import { API_STATUS } from 'helpers';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

/**
 * Edit Group component.
 *
 * @returns {ReactElement} - Returns Jsx.
 */

const EditGroupSchema = Yup.object().shape({
  description: Yup.string().required("Please fill in the group's description."),
  name: Yup.string().required("Please fill in the group's name."),
});

export function GroupEdit() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { throwAlert } = useAlert();
  const { status: groupLoadingStatus, data: group } = useGetGroup(id);
  const queryClient = useQueryClient();

  const { mutate: updateGroup, status: updateLoadingStatus } = useUpdateGroup();
  const editGroup = (formValues: { description: string; name: string }) => {
    if (id) {
      updateGroup(
        {
          data: formValues,
          groupId: id,
        },
        {
          onError: () => {
            throwAlert({
              id: 'edit_group_error',
              key: 'edit_group_error',
              message: 'Failed to edit group',
              timeout: 3000,
              type: 'error',
            });
          },
          onSuccess: () => {
            queryClient.invalidateQueries(['group', id]);
            throwAlert({
              id: 'edit_group_success',
              key: 'edit_group_success',
              message: `Group '${formValues.name}' edited successfully`,
              timeout: 3000,
              type: 'success',
            });
            navigate(`/groups/${id}`);
          },
        }
      );
    }
  };

  const isUpdating = updateLoadingStatus === API_STATUS.LOADING;

  if (!group || groupLoadingStatus !== API_STATUS.SUCCESS) {
    return <LoaderOverlay />;
  }

  return (
    // TODO for new ELP - This following component is repeated in `groups/new/index.tsx`. Abstract these into a `components/Groups/Form`.
    <PaneContent className={styles.paneContent}>
      <h1>Edit Group</h1>
      <Formik
        initialValues={{
          description: group?.description ?? '',
          name: group?.name ?? '',
        }}
        onSubmit={editGroup}
        validationSchema={EditGroupSchema}
      >
        {({ values, errors, setFieldValue }) => (
          <Form className={formStyles.form}>
            <Toolbar>
              <Button
                disabled={Object.values(values).some(current => !current) || isUpdating}
                type='submit'
                variant='active'
              >
                Save
              </Button>
              <Button onClick={() => navigate(-1)} type='button'>
                Cancel
              </Button>
            </Toolbar>
            <Box mb={5}>
              <TextField
                aria-label='Group Name'
                error={Boolean(errors.name)}
                fullWidth={true}
                helperText={errors.name}
                id='name'
                label='Group Name'
                name='groupName'
                onChange={event => setFieldValue('name', event.target.value)}
                placeholder='Group Name'
                type='text'
                value={values.name}
                variant='outlined'
              />
            </Box>
            <Box>
              <TextField
                aria-label='Group Description'
                error={Boolean(errors.description)}
                fullWidth={true}
                helperText={errors.description}
                id='description'
                label='Group Description'
                minRows={6}
                multiline={true}
                name='groupDescription'
                onChange={event => setFieldValue('description', event.target.value)}
                placeholder='Group Description'
                value={values.description}
                variant='outlined'
              />
            </Box>
          </Form>
        )}
      </Formik>
    </PaneContent>
  );
}
