import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from 'helpers/constants';
import { fetchClient } from 'helpers/transport';

export * from './group';
export * from './organization';
export * from './user';

/**
 * Gets all permissions.
 *
 * @param {Array<string>} realms - The permission realm. This can be a group, organization, or user.
 *
 * @throws {Error} - Throws an error if there's a problem with the API response.
 *
 * @returns {Promise<object>} - All permissions for the given realm(s).
 */
export async function getAllPermissions(realms: Array<string>) {
  const realmsParam = realms.map(realm => `realms[]=${realm}`).join('&');

  const response = await fetchClient<{ data: Array<PermissionItem> }>(`/v1/permissions?${realmsParam}`);
  return response.parsedBody.data;
}

export const useGetAllPermissions = (realms: Array<string>) =>
  useQuery([QUERY_KEYS.ALL_PERMISSIONS, realms], () => getAllPermissions(realms));
