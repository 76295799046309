/* eslint-disable sort-keys */

import i18n from 'helpers/i18n';

// Environment variables
export const API_ADDRESS = process.env.REACT_APP_API_ADDRESS ?? 'http://localhost:3001';
export const ENVIRONMENT: Environment = process.env.NODE_ENV ?? 'development';

export const PLAN_PORTAL_API_URL = process.env.REACT_APP_PLAN_PORTAL_API_URL ?? 'http://localhost:3002';

export const FEATURE_ENABLE_YV_VIDEO = process.env.REACT_APP_FEATURE_ENABLE_YV_VIDEO ?? '0';

export const IMAGEPROXY_URL = process.env.REACT_APP_IMAGEPROXY_URL ?? 'https://imageproxy.youversionapistaging.com';

export const PORTALPY_URL = process.env.REACT_APP_PORTALPY_URL ?? 'https://staging.pp-py-api.youversion.com';

export const BASE_TITLE = 'YouVersion';

export const APP_ELEMENT = document.getElementById('root');

export const ORGS_PER_PAGE = 50;
export const USERS_PER_PAGE = 50;
export const PLANS_PER_PAGE = 50;
export const ACTIVITIES_PER_PAGE = 50;

export const API_LOGOUT = 'auth/LOGOUT';

export const QUERY_PARAMS = Object.freeze({
  uploadAudio: 'upload_audio',
});

export const submissionStatuses = Object.freeze({
  APPROVED: 'approved',
  ARCHIVED: 'archived',
  DISABLED: 'disabled',
  DRAFT: 'draft',
  NEW: 'new',
  PUBLISHED: 'published',
  REJECTED: 'rejected',
  SUBMITTED: 'submitted',
});

export const orgStatuses = Object.freeze({
  APPROVED: 'approved',
  DISABLED: 'disabled',
  PENDING: 'pending',
  REJECTED: 'rejected',
});

export const enableSubmission = Object.freeze({
  CAN: 'canSubmit',
  CANNOT: 'cannotSubmit',
});

export const REALMS = Object.freeze({
  GROUP: 'group',
  ORGANIZATION: 'organization',
  USER: 'user',
});

export const MAX_CATEGORIES = 3;

export const PARTNER_KEY = 'YCP-071008';

// Use API_STATUS over `statusType` when using react-query hooks
export const API_STATUS = Object.freeze({
  ERROR: 'error',
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
});

export const DEFAULT_HEADERS = (authToken: string): Record<string, string> => ({
  Accept: 'application/json',
  Authorization: `Bearer ${authToken}`,
  'Content-Type': 'application/json',
});

export const DEFAULT_PLANPORTAL_HEADERS = (authToken: string) =>
  ({
    Accept: 'application/json',
    'X-PartnerPortal-Token': authToken,
    'Content-Type': 'application/json',
  }) as const;

export const QUERY_KEYS = {
  ALL_PLANS: 'all-plans',
  ALL_LANGUAGES: 'all-languages',
  TRANSLATABLE_LANGUAGES: 'translatable-languages',
  GROUP_MEMBERSHIPS: 'group-memberships',
  COMMENTS: 'all-comments',
  PLAN: 'plan',
  ALL_ORGANIZATIONS: 'all-organizations',
  ORGANIZATION: 'organization',
  ORGANIZATION_MEMBERSHIPS: 'organization-memberships',
  ORGANIZATION_PERMISSIONS: 'organization-permissions',
  ORGANIZATION_CONTENT_PARTNERS: 'organization-content-parameters',
  ALL_PERMISSIONS: 'all-permissions',
  STAFF_REPRESENTATIVES: 'staff-representatives',
  USERS: 'users',
  ACTIVITY_ITEMS: 'activity-items',
  PLAN_STATS: 'plan-stats',
  WITH_PLANS_REPORT: 'with-plans-report',
  WITHOUT_PLANS_REPORT: 'without-plans-report',
  SOURCE_PLANS: 'source-plans',
  PLAN_DAY: 'plan-day',
  PLAN_DAYS: 'plan-days',
  VOICES: 'voices',
  BIBLE_VERSIONS: 'bible-versions',
  SESSION: 'session',
};

export const PLAN_DESCRIPTION_WORDS_LIMIT = 100;

// Replace server error messages that are not human friendly.
export const ERROR_RESPONSES_FOR_MULTI_LANGUAGE_PLANS = (error: string) => {
  const { t } = i18n;
  switch (error) {
    case "given source_id can't have translations":
      return t('plans:link_plan.translation_error_response');

    default:
      return error;
  }
};

// 2mb
export const FILE_MAX_BYTE_SIZE = 2048000;

// TODO: add version ids for supported languages.
export const BIBLE_VERSIONS: Record<string, number> = {
  en: 59,
  'en-US': 59,
  'en-GB': 59,
};

export const REVIEW_WEEKS_NUM = 4;
