import { ErrorOutline } from '@mui/icons-material';
import { Avatar, Box, Button, CircularProgress, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import makeStyles from '@mui/styles/makeStyles';
import { GetUsersResponse } from 'api/users';
import { format } from 'date-fns';
import { USERS_PER_PAGE } from 'helpers';
import { isNaN } from 'lodash';
import React, { useMemo } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import { UsersTableHead } from './TableHead';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    border: '1px solid',
    borderColor: theme.palette.grey[300],
  },
  tableContainer: {
    height: '70vh',
  },
  table: {
    minWidth: 750,
  },
  paginationContainer: {
    borderBlockStart: '1px solid',
    borderColor: theme.palette.grey[300],
  },
}));

interface Props {
  data?: GetUsersResponse;
  error?: Error;
  isLoading: boolean;
  refetch: VoidFunction;
}

export function UsersDataGrid({ error, refetch, isLoading, data }: Props) {
  const classes = useStyles();
  const [query, setQuery] = useSearchParams();

  const order = useMemo(() => {
    const orderFromQuery = query.get('order');
    if (orderFromQuery && ['asc', 'desc'].includes(orderFromQuery)) {
      return orderFromQuery as 'asc' | 'desc';
    }

    return 'desc';
  }, [query]);

  const orderBy = useMemo(() => {
    const sortFromQuery = query.get('sort');
    if (sortFromQuery && ['first_name', 'email', 'created_at', 'id'].includes(sortFromQuery)) {
      return sortFromQuery;
    }

    return 'first_name';
  }, [query]);

  const page = useMemo(() => {
    const pageFromQuey = query.get('page');
    if (pageFromQuey && !isNaN(Number(pageFromQuey))) {
      return pageFromQuey;
    }

    return '1';
  }, [query]);

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    query.set('sort', property);
    query.set('order', isAsc ? 'desc' : 'asc');
    setQuery(query);
  };

  const handleChangePage = (_: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    query.set('page', (newPage + 1).toString());
    setQuery(query);
  };

  return (
    <div className={classes.root}>
      <TableContainer className={classes.tableContainer}>
        <Table
          aria-label='users table'
          aria-labelledby='users'
          className={classes.table}
          size='medium'
          stickyHeader={true}
        >
          <UsersTableHead onRequestSort={handleRequestSort} order={order} orderBy={orderBy} />
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell
                  align='center'
                  colSpan={5}
                  style={{
                    height: '50vh',
                    border: 0,
                  }}
                >
                  <CircularProgress data-testid='loading users' />
                </TableCell>
              </TableRow>
            ) : null}

            {error ? (
              <TableRow>
                <TableCell
                  align='center'
                  colSpan={5}
                  style={{
                    height: '50vh',
                    border: 0,
                  }}
                >
                  <ErrorOutline color='error' fontSize='large' />
                  <Typography>{error.message}</Typography>
                  <Box mt={2}>
                    <Button onClick={refetch} size='medium' variant='outlined'>
                      Retry
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            ) : null}

            {data && !data?.users?.length ? (
              <TableRow>
                <TableCell
                  align='center'
                  colSpan={5}
                  style={{
                    height: '50vh',
                    border: 0,
                  }}
                >
                  <ErrorOutline color='disabled' fontSize='large' />
                  <Typography>Users not found</Typography>
                  <Box mt={2}>
                    <Button onClick={refetch} size='medium' variant='outlined'>
                      Retry
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            ) : null}

            {data?.users.map(row => {
              const name = `${row.first_name ?? ''} ${row.last_name ?? ''}`;
              return (
                <TableRow
                  component={Link}
                  hover={true}
                  key={row.id}
                  style={{ textDecoration: 'none' }}
                  to={`/users/${row.id}`}
                >
                  <TableCell align='right' component='th' scope='row'>
                    <Avatar alt={name} src={row?.profile_photo?.asset?.original_url}>
                      {row.first_name?.[0]}
                      {row.last_name?.[0]}
                    </Avatar>
                  </TableCell>
                  <TableCell>{name}</TableCell>
                  <TableCell>{row.email ?? ''}</TableCell>
                  <TableCell width='20%'>
                    {row?.created_at ? format(new Date(row?.created_at ?? ''), 'MM/dd/yyyy') : 'Invalid Date'}
                  </TableCell>
                  <TableCell width='10%'>{row.id}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        className={classes.paginationContainer}
        component='div'
        count={data?.meta.total_count ?? 0}
        onPageChange={handleChangePage}
        page={Number(page) - 1}
        rowsPerPage={USERS_PER_PAGE}
        rowsPerPageOptions={[50]}
      />
    </div>
  );
}
