/* eslint-disable jsdoc/require-param */
import { useMutation, useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from 'helpers';
import { fetchClient } from 'helpers/transport';
import { NumberLike } from 'types/misc';

interface GetGroupMembershipsProps {
  /** User id of user. */
  userId?: NumberLike;
}

/**
 * GET group memberships for a provided user.
 *
 * @throws {Error} - Throws an error if there's a problem with the API response.
 *
 * @returns {Promise<Array<object>>} - The group memberships for the provided user id.
 *
 * @example
 *
 * getGroupMemberships({ userId: 777 })
 *
 */
async function getGroupMemberships({ userId }: GetGroupMembershipsProps) {
  const userQueryString = userId ? `?user_id=${userId}` : '';
  const response = await fetchClient<{
    group_memberships: Array<GroupMembership>;
  }>(`/v1/group_memberships${userQueryString}`);
  return response.parsedBody.group_memberships;
}

export const useGetGroupMemberships = (userId: NumberLike) =>
  useQuery([QUERY_KEYS.GROUP_MEMBERSHIPS, userId], () => getGroupMemberships({ userId }));

interface CreateGroupMembership {
  /** Group id of the group. */
  groupId: NumberLike;
  /** User id of the user. */
  userId: NumberLike;
}
/**
 * Create a group membership.
 *
 * @throws {Error} - Throws an error if there's a problem with the API response.
 *
 * @returns {Promise<object>} - The new group membership data.
 */
async function createGroupMembership({ userId, groupId }: CreateGroupMembership) {
  const response = await fetchClient<{
    group_membership: GroupMembership;
  }>('/v1/group_memberships', {
    method: 'POST',
    body: JSON.stringify({
      group_membership: {
        user_id: userId,
        group_id: groupId,
      },
    }),
  });

  return response.parsedBody.group_membership;
}

export const useCreateGroupMemberships = () => useMutation(createGroupMembership);

/**
 * Deletes a group membership.
 *
 * @throws {Error} - Throws an error if there's a problem with the API response.
 *
 * @returns {Promise} - Does not return anything.
 */
async function destroyGroupMembership(membershipId: NumberLike) {
  return fetchClient(`/v1/group_memberships/${membershipId}`, {
    method: 'DELETE',
  });
}

export const useDestroyGroupMemberships = () => useMutation(destroyGroupMembership);
