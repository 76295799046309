/**
 * @module CategoryFieldAdder
 */
import {
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  TextField,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import styles from 'components/Plans/Plans.module.scss';
import { Plan } from 'components/Plans/types';
import { MAX_CATEGORIES } from 'helpers';
import { toggleCategoryValue } from 'helpers/category-manager';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  cancelButton: { color: grey['600'] },
  cardContainer: {
    marginBlockEnd: '10px',
    padding: '10px',
  },
  categoryPillContainer: {
    margin: '0px 5px',
  },
  chipFontSize: { fontSize: '0.8rem' },
  fontWeightNormal: {
    fontWeight: 'normal',
  },
  noPadding: {
    padding: 0,
  },
  searchBox: { width: '100%' },
  selectedCategoriesListContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    marginBlockStart: '2vh',
  },
});

interface CategoryFieldAdderProps {
  activeCategories: Array<Plan.Category>;
  categories: Array<Plan.Category>;
  isOpen: boolean;
  onComplete: (categories: Array<Plan.Category> | null) => void;
  sections: Array<string>;
}

export function CategoryFieldAdder({
  activeCategories: activeCategoriesProp,
  categories,
  isOpen,
  onComplete,
  sections,
}: CategoryFieldAdderProps) {
  const classes = useStyles();
  const { t, i18n } = useTranslation(['common', 'plans']);

  const [activeTab, setActiveTab] = useState('All');
  const [activeCategories, setActiveCategories] = useState<Array<Plan.Category>>([]);
  const [query, setQuery] = useState('');

  useEffect(() => {
    setActiveCategories(activeCategoriesProp);
  }, [activeCategoriesProp]);

  const tabSections = [
    {
      active: activeTab === 'All',
      name: 'All',
      type: 'ALL',
    },
  ];

  sections.forEach(section =>
    tabSections.push({
      active: activeTab === section,
      name: section,
      type: 'FILTER_BY_NAME',
    })
  );

  const categoryPills = categories
    .map(category => {
      const localizationKey = `plans:categories.${category.name}`;
      return {
        ...category,
        translatedName: i18n.exists(localizationKey) ? t(localizationKey) : category.name,
      };
    })
    .filter(category => !activeCategories.find(c => c?.id === category.id))
    .filter(category => {
      if (activeTab === 'All') {
        return true;
      }

      if (category.sections.indexOf(activeTab) !== -1) {
        return true;
      }

      return false;
    })
    .filter(category => {
      const categoryName = category.translatedName.toLocaleLowerCase().trim();
      const queryString = query.toLocaleLowerCase().trim(); // Trimming words to make search more comfortable to use
      return categoryName.includes(queryString);
    }) // Limiting search only to the filtered category listings and making search case insensitive
    .sort((a, b) => {
      const nameA = a.translatedName.toUpperCase();
      const nameB = b.translatedName.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    })
    .map(category => {
      const active = !!activeCategories.find(c => c?.id === category.id);

      return (
        <div key={category.id}>
          <div className={classes.categoryPillContainer}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={active}
                  disabled={activeCategories.length === MAX_CATEGORIES}
                  name={category.name}
                  onChange={() => {
                    setActiveCategories(prevState => toggleCategoryValue(prevState, category));
                  }}
                />
              }
              disabled={activeCategories.length === MAX_CATEGORIES}
              label={category.translatedName}
            />
          </div>
        </div>
      );
    });

  return (
    <Dialog
      aria-labelledby='category-field-adder'
      onClose={() => {
        onComplete(null);
      }}
      open={isOpen}
    >
      <div className={styles.manageCategoriesContainer}>
        <div className={styles.manageCategoriesHeaderContainer}>
          <div>
            <Typography color='textPrimary' variant='h2'>
              {t('plans:edit_plan_info.manage_categories')}
            </Typography>
          </div>
          <div>
            <Typography className={classes.fontWeightNormal} color='textSecondary' variant='subtitle2'>
              {t('plans:edit_plan_info.category_count_label.category', {
                count: MAX_CATEGORIES,
              })}
            </Typography>
          </div>
        </div>

        <DialogContent className={classes.noPadding}>
          <div className={styles.tabBar}>
            <ul>
              {tabSections.map(tab => (
                <li key={tab.name}>
                  <Chip
                    className={classes.chipFontSize}
                    color={tab.active ? 'primary' : 'secondary'}
                    label={t(`plans:categories.${tab.name}`)}
                    onClick={() => setActiveTab(tab.name)}
                  />
                </li>
              ))}
            </ul>
          </div>
          <Box marginY={2} width='100%'>
            <TextField
              fullWidth={true}
              id='filter-categories'
              onChange={event => setQuery(event.target.value)}
              placeholder={t('plans:edit_plan_info.filter_categories')}
              size='small'
              type='search'
              value={query}
              variant='outlined'
            />
          </Box>
          <div className={styles.categoryPillsContainer}>
            {categoryPills.length > 0 ? (
              categoryPills
            ) : (
              <Box marginY={1}>
                <Typography color='textSecondary'>{t('plans:edit_plan_info.no_matching_categories')}</Typography>
              </Box>
            )}
          </div>
          <Collapse in={activeCategories.length > 0} timeout='auto' unmountOnExit={true}>
            <Card className={classes.cardContainer} variant='outlined'>
              <Typography variant='h3'>
                {t('plans:edit_plan_info.selected_categories', {
                  first: activeCategories.length,
                  second: MAX_CATEGORIES,
                })}
              </Typography>

              <div className={classes.selectedCategoriesListContainer}>
                {activeCategories.map(category => {
                  return (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={true}
                          data-testid={`${category.name}-checked`}
                          onChange={() => {
                            setActiveCategories(prevState => toggleCategoryValue(prevState, category));
                          }}
                        />
                      }
                      key={category.id}
                      label={t(`plans:categories.${category.name}`)}
                    />
                  );
                })}
              </div>
            </Card>
          </Collapse>
        </DialogContent>

        <DialogActions className={classes.noPadding}>
          <Button
            className={classes.cancelButton}
            onClick={event => {
              event.preventDefault();
              onComplete(null);
            }}
          >
            {t('common:cancel').toUpperCase()}
          </Button>

          <Button
            onClick={event => {
              event.preventDefault();
              onComplete(activeCategories);
            }}
          >
            {t('common:save').toUpperCase()}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
}
