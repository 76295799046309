/**
 * @module OrganizationsRoutes
 */
// `path` should be the first prop in a Route so that routes are easy to maintain.
/* eslint-disable react/jsx-sort-props */
import { Organizations } from 'containers/organizations';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { OrganizationRoutes } from './organization';

/**
 * The OrganizationsRoutes component.
 *
 * @alias module:OrganizationsRoutes
 *
 * @returns {React.ReactElement} - The OrganizationsRoutes component.
 */
export function OrganizationsRoutes() {
  return (
    <Routes>
      <Route index={true} element={<Organizations />} />

      {/* 🐣 Nested routes only below this line. */}
      <Route path=':id/*' element={<OrganizationRoutes />} />
    </Routes>
  );
}
