import Alert from '@mui/material/Alert';
import { useGetLanguages } from 'api/languages';
import styles from 'components/Languages/Pages/styles.module.scss';
import LanguagesTable from 'components/Languages/Table';
import LoaderOverlay from 'components/LoaderOverlay';
import { PaneContent } from 'components/misc';
import { API_STATUS } from 'helpers';

/**
 * Languages component.
 *
 * @returns {ReactElement} - The Languages component.
 */
export function Languages() {
  const { data: languages, status } = useGetLanguages();

  if (status !== API_STATUS.SUCCESS || !languages) {
    return <LoaderOverlay />;
  }

  return (
    <div className={styles.contentContainer}>
      <PaneContent className={styles.paneContent}>
        <h1>Languages</h1>
        <Alert severity='warning'>
          The ability to add and edit languages is no longer supported. The languages listed below are currently
          available to Partners. If you would like to make a change to this list, please contact our team at{' '}
          <a
            href='mailto:partnerships@youversion.com'
            rel='noreferrer'
            style={{
              color: '#603E14',
              textDecorationColor: '#603E14',
            }}
          >
            partnerships@youversion.com
          </a>
          .
        </Alert>
        <LanguagesTable items={Object.values(languages)} />
      </PaneContent>
    </div>
  );
}
