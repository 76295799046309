/* eslint-disable jsdoc/require-param */
/**
 * @module FilterPlans
 */
import { ReactElement } from 'react';

import { Form } from '../Form';

/**
 * Filters plans by name or languages.
 *
 * @returns {ReactElement} - Returns jsx.
 */
export const Filters = () => {
  return <Form />;
};
