/* eslint-disable react/no-multi-comp */
import c from 'classnames';
import { ClassWrapper } from 'components';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './Card.module.scss';

export { default as CommentSection } from './CommentSection';

export const Card = props => <ClassWrapper {...props} wrapClass={styles.card} />;

export const CardSection = props => (
  <ClassWrapper className={props.className} {...props} wrapClass={styles[`${props.type}Section`]}>
    {props.children}
  </ClassWrapper>
);

CardSection.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  type: PropTypes.string.isRequired,
};

CardSection.defaultProps = {
  children: null,
  className: '',
};

export const Badge = props => {
  let text = '';
  if (_.isString(props.children)) {
    text = _.snakeCase(props.children);
  }

  return <ClassWrapper {...props} wrapClass={c(text, styles.badge)} />;
};
Badge.propTypes = { className: '', children: PropTypes.node };

export const Edit = props => <ClassWrapper {...props} wrapClass={styles.edit} />;
