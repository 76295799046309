/* eslint-disable jsdoc/require-param */
import { Plan } from 'components/Plans/types';
/**
 * @module CompletionChecklistHelper
 */
import _ from 'lodash';

export const checklistFields: Record<string, { label: string; value: string }> = {
  description: {
    value: 'add-a-description',
    label: 'plans:plan_checklist.fields.description',
  },
  large_image: {
    value: 'add-large-plan-image',
    label: 'plans:plan_checklist.fields.large_image',
  },
  small_image: {
    value: 'add-small-plan-image',
    label: 'plans:plan_checklist.fields.small_image',
  },
  partner_url: {
    value: 'add-publisher-website',
    label: 'plans:plan_checklist.fields.partner_url',
  },
  categories: {
    value: 'add-categories',
    label: 'plans:plan_checklist.fields.categories',
  },
  keywords: {
    value: 'add-keywords',
    label: 'plans:plan_checklist.fields.keywords',
  },
  plan_days: {
    value: 'add-plan-days',
    label: 'plans:plan_checklist.fields.plan_days',
  },
};

export const fieldsArray = Object.keys(checklistFields) as Array<keyof Plan.Plan | string>;
interface CheckFieldOptions {
  // The least required.
  length?: number;
}

export const checkField =
  (plan: Plan.Plan, planDays: Array<Plan.Day>) => (planField: keyof Plan.Plan | string, opts?: CheckFieldOptions) => {
    if (planField in plan) {
      const field = plan[planField as keyof Plan.Plan];
      if (field) {
        if (Array.isArray(field)) {
          const leastLength = opts?.length ?? 1;
          return (field as Array<any>).length >= leastLength;
        }
        return Boolean(field);
      }
    } else if (planField === 'plan_days') {
      return planDays.length >= 3;
    }
    return false;
  };

/**
 * Takes the plan object and calculates completion percentage.
 *
 * @param {object} plan - Plan object.
 * @param {object} planDays - Array of the plan's Day objects.
 * @returns {number} - Percentage.
 */
export function calculatePercentage(plan: Plan.Plan, planDays: Array<Plan.Day>): number {
  const complete = fieldsArray.filter(field => checkField(plan, planDays)(field));
  return Math.floor((complete.length / fieldsArray.length) * 100);
}

/**
 * Tells whether the plan is completed.
 *
 * @param {object} plan - Plan object.
 * @param {object} planDays - Array of the plan's Day objects.
 *
 * @returns {boolean} - Returns a boolean.
 */
export function isPlanComplete(plan: Plan.Plan, planDays: Array<Plan.Day>): boolean {
  return calculatePercentage(plan, planDays) === 100;
}

/**
 * Hyphenates the sentence.
 *
 * @example
 * // returns hello-world
 * getKebabCase('Hello World')
 *
 * @returns {string} - Hypheneated sentence.
 */
export function getKebabCase(sentence: string) {
  return _.kebabCase(sentence);
}

interface CheckIfFieldIsActiveProps {
  field: string;
  hash: string;
}

/**
 * Checks if the field supplied is active or not.
 *
 * @returns {boolean} - Return truthy value if they are equal.
 */
export function checkIfFieldIsActive({ hash, field }: CheckIfFieldIsActiveProps) {
  const trimmedHash = hash.trim();
  if (!trimmedHash.length) {
    return false;
  }

  return hash.slice(1) === field;
}
