/** Test File.
 *
 * @module ConfirmationDialog
 */
import { Box, CircularProgress } from '@mui/material';
import { statusTypes } from '@youversion/utils';
import { useGetPollyVoices } from 'api/features/voice';
import DialogWrapper from 'components/Plans/DialogWrapper';
import FinalConfirmation from 'components/Plans/DialogWrapper/Confirmation/FinalConfirmation';
import InitialConfirmation from 'components/Plans/DialogWrapper/Confirmation/InitialConfirmation';
import PlanSuccessDialog from 'components/Plans/DialogWrapper/Confirmation/SuccessDialog';
import { Plan } from 'components/Plans/types';
import { planHasNarratedAudio } from 'helpers/plan-has-narrated-audio';
import React, { useMemo } from 'react';
import { NullableString } from 'types/misc';

const submissionSteps = {
  STEP_1: 'STEP_1',
  STEP_2: 'STEP_2',
  STEP_3: 'STEP_3',
};

interface Props {
  daysWithoutNarratedAudio: NullableString;
  handleClose: () => void;
  handleSubmit: () => void;
  hasBeenPublished: boolean;
  hasDraftLaunchDate: boolean;
  isDialogOpen: boolean;
  loadingStatus: string;
  plan: Plan.Plan;
  planLanguage: Language;
}

const ConfirmationDialog = ({
  handleClose,
  handleSubmit,
  hasBeenPublished,
  loadingStatus,
  isDialogOpen,
  plan,
  planLanguage,
  daysWithoutNarratedAudio,
  hasDraftLaunchDate,
}: Props) => {
  const { data: voices, isLoading: isLoadingVoices } = useGetPollyVoices(planLanguage.code);
  const hasNarratedAudio = planHasNarratedAudio(plan);
  const pollyVoiceId = useMemo(() => plan.polly_voice_id, [plan]);

  const isPollyVoiceNotSelected = useMemo(
    () => Boolean(voices?.length && !pollyVoiceId && !hasNarratedAudio),
    [pollyVoiceId, hasNarratedAudio, voices?.length]
  );

  const [submissionStep, setSubmissionStep] = React.useState<string>(submissionSteps.STEP_1);

  React.useEffect(() => {
    if (isPollyVoiceNotSelected || daysWithoutNarratedAudio) {
      setSubmissionStep(submissionSteps.STEP_1);
      return;
    }

    // If it has been published before we want to skip launch date selection.
    if (hasBeenPublished) {
      setSubmissionStep(submissionSteps.STEP_2);
      return;
    }

    // Show Step 2 if there's a draft launch date and no validation errors.
    if (hasDraftLaunchDate) {
      setSubmissionStep(submissionSteps.STEP_2);
      return;
    }

    setSubmissionStep(submissionSteps.STEP_1);
  }, [daysWithoutNarratedAudio, hasBeenPublished, hasDraftLaunchDate, isPollyVoiceNotSelected]);

  const [checked, setChecked] = React.useState(false);
  let component;

  React.useEffect(() => {
    if (loadingStatus === statusTypes.RESOLVED) {
      setSubmissionStep(submissionSteps.STEP_3);
    }
  }, [loadingStatus]);

  function handleSubmissionStep(step: string) {
    setSubmissionStep(step);
  }

  function handleAcceptTerms() {
    setChecked(!checked);
  }

  function handleBackClick() {
    handleSubmissionStep(submissionSteps.STEP_1);
  }

  function handleNext() {
    setSubmissionStep(submissionSteps.STEP_2);
  }

  // Initiate confirmation
  if (isDialogOpen) {
    if (submissionStep === submissionSteps.STEP_1) {
      component = (
        <InitialConfirmation
          daysWithoutNarratedAudio={daysWithoutNarratedAudio}
          handleClose={handleClose}
          handleNext={handleNext}
          isPollyVoiceNotSelected={isPollyVoiceNotSelected}
          plan={plan}
        />
      );
    } else {
      component = (
        <FinalConfirmation
          handleAcceptTerms={handleAcceptTerms}
          handleBack={handleBackClick}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          hasDraftLaunchDate={hasDraftLaunchDate}
          isTermsChecked={checked}
          loadingStatus={loadingStatus}
        />
      );
    }
  }
  if (submissionStep === submissionSteps.STEP_2) {
    component = (
      <FinalConfirmation
        handleAcceptTerms={handleAcceptTerms}
        handleBack={handleBackClick}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        hasDraftLaunchDate={hasDraftLaunchDate}
        isTermsChecked={checked}
        loadingStatus={loadingStatus}
      />
    );
  }
  if (submissionStep === submissionSteps.STEP_3) {
    component = <PlanSuccessDialog handleClose={handleClose} planImage={plan.small_image?.asset.original_url} />;
  }

  if (isLoadingVoices) {
    component = (
      <Box alignItems='center' display='flex' height={200} justifyContent='center'>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <DialogWrapper
      component={component}
      disableClose={loadingStatus === statusTypes.PENDING}
      handleClose={handleClose}
      isDialogOpen={isDialogOpen}
    />
  );
};

export default ConfirmationDialog;
