/* eslint-disable import/no-cycle */
/* eslint-disable jsdoc/require-param */
/**
 * @module OrganizationMembershipsApiMethods
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import { API_ADDRESS, QUERY_KEYS } from 'helpers';
import { cleanParams } from 'helpers/remove-nulls';
import { fetchClient } from 'helpers/transport';
import { NumberLike } from 'types/misc.d';

import { FetchOrganizationMemberships } from './types';

interface InviteUserToOrganizationProps {
  email: string;
  firstName: string;
  lastName: string;
  orgId: NumberLike;
}

/**
 * Invite user to an organization.
 *
 * @throws {Error} - Throws an error if there's a problem with the API response.
 *
 * @returns {Promise<object>} - The API response.
 */
export async function inviteUserToOrganization({ orgId, email, lastName, firstName }: InviteUserToOrganizationProps) {
  const response = await fetchClient<{
    organization_membership: OrganizationMembership;
  }>('/invitations', {
    body: JSON.stringify({
      organization_membership: {
        organization_id: orgId,
        user_attributes: {
          email,
          last_name: lastName,
          first_name: firstName,
        },
      },
    }),
    method: 'POST',
  });
  return response.parsedBody.organization_membership;
}

export const useInviteUserToOrganization = () =>
  useMutation((values: InviteUserToOrganizationProps) => inviteUserToOrganization(values));

interface GetOrganizationMembershipsResponse {
  meta: FetchOrganizationMemberships.OrganizationMembershipsMeta;
  organization_memberships: Array<OrganizationMembership>;
}

export interface GetOrganizationMembershipsProps {
  filters: FetchOrganizationMemberships.Filter;
  pagination: Pagination;
}
/**
 * Get an organization membership.
 *
 * @throws {Error} - Throws an error if there's a problem with the API response.
 *
 * @returns {Promise<object>} - The API response.
 */
export async function getOrganizationMemberships({ filters, pagination }: GetOrganizationMembershipsProps) {
  const removeAllNullableValues = cleanParams({
    per: pagination.per,
    page: pagination.page,
    ...filters,
  });
  const params = new URLSearchParams(removeAllNullableValues);
  const response = await fetchClient<GetOrganizationMembershipsResponse>(
    `/organization_memberships?${params.toString()}`
  );
  return response.parsedBody;
}

export const useGetOrganizationMemberships = (params: GetOrganizationMembershipsProps) =>
  useQuery([QUERY_KEYS.ORGANIZATION_MEMBERSHIPS, params], () => getOrganizationMemberships(params));

interface UpdateOrganizationMembershipProps {
  membershipId: NumberLike;
  role?: string;
}

/**
 * Update an organization membership.
 *
 * @throws {Error} - Throws an error if there's a problem with the API response.
 *
 * @returns {Promise<object>} - The API response.
 */
export async function updateOrganizationMembership({ membershipId, ...props }: UpdateOrganizationMembershipProps) {
  const response = await fetchClient<{
    organization_membership: OrganizationMembership;
  }>(`/organization_memberships/${membershipId}`, {
    body: JSON.stringify({
      organization_membership: props,
    }),
    method: 'PATCH',
  });

  return response.parsedBody.organization_membership;
}

export const useUpdateOrganizationMembership = () =>
  useMutation((values: UpdateOrganizationMembershipProps) => updateOrganizationMembership(values));

interface DeleteOrganizationMembershipProps {
  membershipId: NumberLike;
}

/**
 * Delete an organization membership.
 *
 * @throws {Error} - Throws an error if there's a problem with the API response.
 *
 * @returns {Promise<object>} - The API response.
 */
export async function deleteOrganizationMembership({ membershipId }: DeleteOrganizationMembershipProps) {
  return fetchClient(`/organization_memberships/${membershipId}`, {
    method: 'DELETE',
  });
}

export const useDeleteOrganizationMembership = () =>
  useMutation((membershipId: NumberLike) =>
    deleteOrganizationMembership({
      membershipId,
    })
  );

interface AcceptUserInvitation {
  confirmPassword: string;
  firstName: string;
  lastName: string;
  password: string;
  token: string;
}

interface AcceptUserInvitationResponse {
  meta: { token: string };
  organization_membership: OrganizationMembership;
}

/**
 * Accept user invitation.
 *
 * @throws {Error} - Throws an error if there's a problem with the API response.
 *
 * @returns {Promise<object>} - The API response.
 */
const acceptUserInvitations = async ({
  token,
  lastName,
  firstName,
  password,
  confirmPassword,
}: AcceptUserInvitation): Promise<AcceptUserInvitationResponse | undefined> => {
  try {
    const response = await fetch(`${API_ADDRESS}/invitations/${token}`, {
      body: JSON.stringify({
        organization_membership: {
          status: 'accepted',
        },
        user: {
          first_name: firstName,
          last_name: lastName,
          password,
          password_confirmation: confirmPassword,
        },
      }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
    });
    const jsonResponse = await response.json();

    if (!response.ok) {
      throw new Error(jsonResponse.message);
    }

    return jsonResponse;
  } catch (error) {
    if (error instanceof Error) {
      throw error;
    }
  }
};

export const useAcceptUserInvitation = () => useMutation(acceptUserInvitations);
