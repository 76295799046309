/* eslint-disable react/no-multi-comp */
import 'moment/min/locales';

import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { createTheme, CssBaseline, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { getV3ThemeObject } from '@youversion/react/styles';
import Root from 'components/Root/Root';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, Provider } from 'react-redux';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import AppRoutes from 'routes';
import * as AppActions from 'state/actions/app';
// @ts-ignore
import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';

/**
 * Scrolls to the top on route changes.
 * Replaces behavior of legacy middleware `react-router-scroll`.
 *
 * @see Https://reactrouter.com/web/guides/scroll-restoration.
 *
 * @returns {null} - Does not return anything, it's just scrolling on route change.
 */
function ScrollToTop() {
  const { pathname } = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

// if (process.env.NODE_ENV !== 'production') {
//   const worker = setupWorker()
//   worker.start()
// }
interface Props {
  appRouteChanged: () => void;
  store: any;
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  },
});

// We only want the rtlPlugin included in RTL
const cacheRtl = createCache({
  key: 'mui-rtl',
  stylisPlugins: [prefixer, rtlPlugin],
  prepend: true,
});

const cacheLtr = createCache({
  key: 'mui-ltr',
  stylisPlugins: [prefixer],
  prepend: true,
});

const App: FC<Props> = ({ appRouteChanged, store }) => {
  // When it's time to allow the user to change from light to dark mode,
  // the `setIsDark` function can be retrieved from the state to be called.
  const [isDark] = React.useState<boolean>(false);
  const { i18n } = useTranslation();
  const dir = i18n.dir();
  const lang = i18n.language;

  const theme = React.useMemo<object>(() => {
    const base = getV3ThemeObject({ isDarkMode: isDark });
    return createTheme({
      ...base,
      direction: dir,
    });
  }, [isDark, dir]);

  // This is YVui's way for handling the theme by checking for a 'data-theme' property.
  React.useEffect(() => {
    window.document.documentElement.setAttribute('data-theme', isDark ? 'dark' : 'light');
  }, [isDark]);

  // Sync the language dir to the root element for right-to-left support
  React.useEffect(() => {
    document.documentElement.dir = dir;
    document.documentElement.lang = lang;
  }, [dir, lang]);

  if (process.env.REACT_APP_MAINTENANCE_MODE === 'true') {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          placeItems: 'center',
          height: '100vh',
          marginBlockStart: '48px',
        }}
      >
        <p>The Partner Portal is currently undergoing brief maintenance to improve your experience.</p>
        <p>We apologize for any inconvenience and appreciate your patience. Please check back shortly!</p>
      </div>
    );
  }

  return (
    <QueryClientProvider client={queryClient}>
      <CacheProvider value={dir === 'rtl' ? cacheRtl : cacheLtr}>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <Provider store={store}>
            <BrowserRouter>
              <Root>
                <ScrollToTop />
                <AppRoutes appRouteChanged={appRouteChanged} />
              </Root>
            </BrowserRouter>
          </Provider>
        </MuiThemeProvider>
        <ReactQueryDevtools initialIsOpen={false} position='bottom-right' />
      </CacheProvider>
    </QueryClientProvider>
  );
};

const stateToProps = () => {
  return {};
};

const dispatchToProps = (dispatch: Dispatch) => {
  return {
    appRouteChanged: bindActionCreators(AppActions.appRouteChanged, dispatch),
  };
};

export default connect(stateToProps, dispatchToProps)(App);
