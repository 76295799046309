/* eslint-disable import/no-cycle */
/* eslint-disable react/no-multi-comp */
import { Box, Chip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DataGrid, GridColumns } from '@mui/x-data-grid';
import { useGetOrganizationMemberships } from 'api/organization_memberships';
import { API_STATUS } from 'helpers';
import { capitalize } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { NumberLike } from 'types/misc';

import { permittedValuesForMembership } from '../permissions';
import { ChangeMembershipRole } from './components/ChangeRole';
import { DeleteMember } from './components/DeleteMember';
import { LoadingOverlay } from './components/LoadingOverlay';
import { NoRowsOverlay } from './components/NoRowsOverlay';
import { PaginationComponent } from './components/Pagination';
import { ReInviteMember } from './components/ReInviteMember';

const MEMBERSHIP_PAGE_SIZE = 50;

const useStyles = makeStyles(() => ({
  planName: {
    textDecoration: 'none',
    whiteSpace: 'pre-wrap',
  },
}));

interface UserTableProps {
  currentUser: LoggedInUser;
  isEditing: boolean;
  orgId: NumberLike;
}

export function UserTable({ currentUser, orgId, isEditing }: UserTableProps) {
  const [query] = useSearchParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation(['organizations']);

  const { data: memberships, status: loadingMemberships } = useGetOrganizationMemberships({
    pagination: {
      per: MEMBERSHIP_PAGE_SIZE,
      page: query.get('page') ?? 1,
    },
    filters: {
      organization_id: orgId,
    },
  });

  const dataIsPending = loadingMemberships !== API_STATUS.SUCCESS;

  const membershipRows = memberships
    ? memberships.organization_memberships.map(membership => ({
        ...membership,
        name: `${membership.user.last_name} ${membership.user.first_name}`,
        email: membership.user.email,
      }))
    : [];

  function findCurrentUserMembership() {
    return currentUser?.organization_memberships?.find(membership => membership.organization_id === orgId) || {};
  }

  const currentUserMembership = findCurrentUserMembership();

  // check for user permission to edit organization membership
  const canUserEditOrg = currentUser.can('edit:organization_membership');

  const possibleMembershipRoles = permittedValuesForMembership('role', currentUserMembership, canUserEditOrg);

  const columns = useMemo(() => {
    const columnsArray: GridColumns = [
      {
        field: 'id',
        filterable: false,
        headerName: t('organizations:single_org.membership_table.columns.id'),
        hide: true,
        sortable: false,
      },
      {
        field: 'name',
        filterable: false,
        flex: 1,
        headerName: t('organizations:single_org.membership_table.columns.name'),
        renderCell: ({ value: name, row }) => (
          <Typography
            className={classes.planName}
            color='textPrimary'
            component={Link}
            to={currentUser.id === row.user_id ? '/my-account' : `/users/${row.user_id}`}
            variant='subtitle1'
          >
            {name}
          </Typography>
        ),
      },
      {
        field: 'role',
        filterable: false,
        headerName: t('organizations:single_org.membership_table.columns.role'),
        flex: 0.5,
        renderCell: ({ value: membershipRole, id }) =>
          isEditing ? (
            <ChangeMembershipRole membershipId={id} role={membershipRole} roleOptions={possibleMembershipRoles} />
          ) : (
            capitalize(membershipRole)
          ),
      },
      {
        field: 'status',
        filterable: false,
        headerName: t('organizations:single_org.membership_table.columns.status'),
        renderCell: ({ value: memberShipStatus }) => (
          <Chip
            label={t(`organizations:single_org.membership_table.status.${memberShipStatus}`)}
            size='small'
            variant='outlined'
          />
        ),
      },
      {
        field: 'email',
        filterable: false,
        headerName: t('organizations:single_org.membership_table.columns.email'),
        flex: 1,
      },
      {
        field: 'action',
        filterable: false,
        headerName: '',
        flex: 0.4,
        hide: !isEditing,
        renderCell: ({ id, row }) => {
          return (
            <Box alignItems='center' display='flex' flex={1} justifyContent='center'>
              {row.status === 'invited' ? <ReInviteMember orgId={orgId} user={row.user} /> : null}

              <DeleteMember membershipId={id} />
            </Box>
          );
        },
      },
    ];

    return columnsArray;
  }, [classes.planName, currentUser.id, isEditing, orgId, possibleMembershipRoles, t]);

  function getPaginationString() {
    const currentPage = memberships?.meta?.current_page || 1;
    const totalCount = memberships?.meta?.total_count || 0;
    let paginationStartCount = 1;
    let paginationEndCount = 1;
    if (currentPage) {
      paginationStartCount = currentPage > 1 ? (currentPage - 1) * MEMBERSHIP_PAGE_SIZE : 1;
      paginationEndCount = currentPage * MEMBERSHIP_PAGE_SIZE;
    }
    if (currentPage === memberships?.meta?.total_pages) {
      paginationEndCount = memberships?.meta?.total_count;
    }

    return t('organizations:single_org.membership_table.page_count.displayed_of_total', {
      start: paginationStartCount,
      end: paginationEndCount,
      count: Number(totalCount),
    });
  }

  return (
    <>
      <Box my={3}>
        <Typography variant='h2'>{t('organizations:single_org.membership_table.title')}</Typography>
        <Typography color='textSecondary' variant='body1'>
          {t('organizations:single_org.membership_table.sub_text')}
        </Typography>

        <Box data-testid='membership-data' height='50vh' mt={4}>
          <DataGrid
            columns={columns}
            components={{
              LoadingOverlay,
              NoRowsOverlay,
              Pagination: () => (
                <PaginationComponent
                  dataIsPending={dataIsPending}
                  getPaginationString={getPaginationString}
                  meta={memberships?.meta}
                />
              ),
            }}
            disableColumnFilter={true}
            disableColumnMenu={true}
            disableSelectionOnClick={true}
            loading={dataIsPending}
            onCellClick={({ row, field }) => {
              if (field === 'name') {
                navigate(currentUser.id === row.user_id ? '/my-account' : `/users/${row.user_id}`);
              }
            }}
            page={memberships ? memberships.meta?.current_page - 1 : 0} // Zero-indexed
            pageSize={MEMBERSHIP_PAGE_SIZE}
            pagination={true}
            // This is needed since the API is the one controlling the data.
            paginationMode='server'
            rowCount={memberships?.meta?.total_count || 0}
            rowHeight={70}
            rows={membershipRows}
          />
        </Box>
      </Box>
    </>
  );
}
