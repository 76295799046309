/**
 * @module TransferList
 */
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { Grid, IconButton, TextField, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useAlert } from '@youversion/react';
import {
  useCreateGroupPermissions,
  useCreateOrganizationPermissions,
  useCreateUserPermissions,
  useDeleteGroupPermissions,
  useDeleteOrganizationPermissions,
  useDeleteUserPermissions,
} from 'api/permissions';
import * as React from 'react';

import CustomList from './CustomList';
import styles from './TransferList.module.scss';

function not(a: Array<PermissionItem>, b: Array<PermissionItem>) {
  return a.filter((value: PermissionItem) => b.indexOf(value) === -1);
}

function intersection(a: Array<PermissionItem>, b: Array<PermissionItem>) {
  return a.filter((value: PermissionItem) => b.indexOf(value) !== -1);
}
interface Props {
  activePermissions: Array<PermissionItem>;
  groupId?: number;
  inactivePermissions: Array<PermissionItem>;
  onPermissionActivate: (newId: number, permissionId: string) => void;
  organizationId?: number;
  relationIds: Record<string, number>;
  userId?: number;
}

export default function TransferList({
  activePermissions,
  inactivePermissions,
  groupId,
  organizationId,
  relationIds,
  onPermissionActivate,
  userId,
}: Props) {
  const { throwAlert } = useAlert();
  const queryClient = useQueryClient();
  const { mutate: createGroupPermissions } = useCreateGroupPermissions();
  const { mutate: createOrganizationPermissions } = useCreateOrganizationPermissions();
  const { mutate: createUserPermission } = useCreateUserPermissions();
  const { mutate: deleteGroupPermissions } = useDeleteGroupPermissions();
  const { mutate: deleteOrganizationPermissions } = useDeleteOrganizationPermissions();
  const { mutate: deleteUserPermissions } = useDeleteUserPermissions();

  const [checked, setChecked] = React.useState<Array<PermissionItem>>([]);
  const [inactiveList, setInactiveList] = React.useState(inactivePermissions);
  const [activeList, setActiveList] = React.useState(activePermissions);

  const leftChecked = intersection(checked, inactiveList);
  const rightChecked = intersection(checked, activeList);

  const handleToggle = (value: PermissionItem) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleMoveRight = async () => {
    const updatedList = activeList.concat(leftChecked);
    setActiveList(updatedList);
    setInactiveList(not(inactiveList, leftChecked));
    setChecked(not(checked, leftChecked));

    try {
      leftChecked.forEach(async permission => {
        if (groupId) {
          createGroupPermissions(
            { groupId, permissionId: permission.id },
            {
              onError: error => {
                if (error instanceof Error) {
                  throwAlert({
                    id: 'create_group_permission_error',
                    key: 'create_group_permission_error',
                    message: `Failed to add permission: ${error.message}`,
                    timeout: 5000,
                    type: 'error',
                  });
                }
              },
              onSuccess: groupPermissionResponse => {
                queryClient.invalidateQueries(['group-permissions', groupPermissionResponse.id]);
                throwAlert({
                  id: 'create_group_permission_success',
                  key: 'create_group_permission_success',
                  message: 'Permission has successfully been added.',
                  timeout: 5000,
                  type: 'success',
                });
                onPermissionActivate(groupPermissionResponse.id, permission.id);
              },
            }
          );
        }
        if (organizationId) {
          createOrganizationPermissions(
            { orgId: organizationId, permissionId: permission.id },
            {
              onError: error => {
                if (error instanceof Error) {
                  throwAlert({
                    id: 'create_organization_permission_error',
                    key: 'create_organization_permission_error',
                    message: `Failed to add permission: ${error.message}`,
                    timeout: 5000,
                    type: 'error',
                  });
                }
              },
              onSuccess: organizationPermissionResponse => {
                queryClient.invalidateQueries(['organization-permissions', organizationPermissionResponse.id]);
                throwAlert({
                  id: 'create_organization_permission_success',
                  key: 'create_organization_permission_success',
                  message: 'Permission has successfully been added.',
                  timeout: 5000,
                  type: 'success',
                });
                onPermissionActivate(organizationPermissionResponse.id, permission.id);
              },
            }
          );
        }
        if (userId) {
          createUserPermission(
            { userId, permissionId: permission.id },
            {
              onError: error => {
                if (error instanceof Error) {
                  throwAlert({
                    id: 'create_user_permission_error',
                    key: 'create_user_permission_error',
                    message: `Failed to add permission: ${error.message}`,
                    timeout: 5000,
                    type: 'error',
                  });
                }
              },
              onSuccess: userPermissionResponse => {
                queryClient.invalidateQueries(['user-permissions', userPermissionResponse.id]);
                throwAlert({
                  id: 'create_user_permission_success',
                  key: 'create_user_permission_success',
                  message: 'Permission has successfully been added.',
                  timeout: 5000,
                  type: 'success',
                });
                onPermissionActivate(userPermissionResponse.id, permission.id);
              },
            }
          );
        }
      });
    } catch (error) {
      if (error instanceof Error) {
        throwAlert({
          id: 'active_permissions_error',
          key: 'active_permissions_error',
          message: `Failed to add permission: ${error.message}`,
          timeout: 5000,
          type: 'error',
        });
      }
    }
  };
  const handleMoveLeft = () => {
    setInactiveList(inactiveList.concat(rightChecked));
    setActiveList(not(activeList, rightChecked));
    setChecked(not(checked, rightChecked));

    try {
      rightChecked.forEach((permission: PermissionItem) => {
        if (groupId) {
          deleteGroupPermissions(relationIds[permission.id], {
            onError: () => {
              throwAlert({
                id: `${groupId}_permission_delete_error`,
                key: `${groupId}_permission_delete_error`,
                message: 'Failed to delete group permission',
                timeout: 5000,
                type: 'error',
              });
            },
            onSuccess: () => {
              queryClient.invalidateQueries(['group-permissions']);
              throwAlert({
                id: `${groupId}_permission_delete_success`,
                key: `${groupId}_permission_delete_success`,
                message: 'Group Permission has been deleted successfully',
                timeout: 5000,
                type: 'success',
              });
            },
          });
        }
        if (organizationId) {
          deleteOrganizationPermissions(relationIds[permission.id], {
            onError: () => {
              throwAlert({
                id: `${organizationId}_permission_delete_error`,
                key: `${organizationId}_permission_delete_error`,
                message: 'Failed to delete organization permission',
                timeout: 5000,
                type: 'error',
              });
            },
            onSuccess: () => {
              queryClient.invalidateQueries(['organization-permissions']);
              throwAlert({
                id: `${organizationId}_permission_delete_success`,
                key: `${organizationId}_permission_delete_success`,
                message: 'Organization Permission has been deleted successfully',
                timeout: 5000,
                type: 'success',
              });
            },
          });
        }
        if (userId) {
          deleteUserPermissions(relationIds[permission.id], {
            onError: () => {
              throwAlert({
                id: `${userId}_permission_delete_error`,
                key: `${userId}_permission_delete_error`,
                message: 'Failed to delete user permission',
                timeout: 5000,
                type: 'error',
              });
            },
            onSuccess: () => {
              queryClient.invalidateQueries(['user-permissions']);
              throwAlert({
                id: `${userId}_permission_delete_success`,
                key: `${userId}_permission_delete_success`,
                message: 'User Permission has been deleted successfully',
                timeout: 5000,
                type: 'success',
              });
            },
          });
        }
      });
    } catch (error) {
      throwAlert({
        id: 'inactive_permissions_error',
        key: 'inactive_permissions_error',
        message: 'Failed to remove permission',
        timeout: 5000,
        type: 'error',
      });
    }
  };

  const handleInactiveSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      const filteredPermissions = inactiveList.filter(permission =>
        permission.description.match(new RegExp(event.target.value, 'i'))
      );
      setInactiveList(filteredPermissions);
    } else {
      setInactiveList(inactivePermissions);
    }
  };

  const handleActiveSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      const filteredPermissions = activeList.filter(permission =>
        permission.description.match(new RegExp(event.target.value, 'i'))
      );
      setActiveList(filteredPermissions);
    } else {
      setActiveList(activePermissions);
    }
  };

  return (
    <div className={styles.transferListWrapper}>
      <Typography color='textPrimary' variant='h2'>
        Permissions
      </Typography>
      <Typography color='textSecondary' variant='body1'>
        Only Admins can see this section.
      </Typography>
      <Grid alignItems='center' container={true}>
        <div className={styles.card}>
          <div className={styles.heading}>
            <Typography color='textPrimary' variant='h3'>
              Inactive Permissions
            </Typography>
            <TextField
              fullWidth={true}
              label='Filter Permissions'
              margin='dense'
              onChange={handleInactiveSearch}
              type='search'
              variant='outlined'
            />
          </div>
          <Grid data-testid='inactivePermissions' item={true}>
            <CustomList checked={checked} handleToggle={handleToggle} items={inactiveList} />
          </Grid>
        </div>
        <Grid item={true}>
          <Grid alignItems='center' container={true} direction='column'>
            <IconButton
              aria-label='move selected to active'
              className={styles.iconButton}
              disabled={leftChecked.length === 0 || rightChecked.length > 0}
              onClick={handleMoveRight}
              size='large'
            >
              <ArrowForwardOutlinedIcon />
            </IconButton>
            <IconButton
              aria-label='move selected to inactive'
              className={styles.iconButton}
              disabled={rightChecked.length === 0 || leftChecked.length > 0}
              onClick={handleMoveLeft}
              size='large'
            >
              <ArrowBackOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
        <div className={styles.card}>
          <div className={styles.heading}>
            <Typography color='textPrimary' variant='h3'>
              Active Permissions
            </Typography>
            <TextField
              fullWidth={true}
              label='Filter Permissions'
              margin='dense'
              onChange={handleActiveSearch}
              type='search'
              variant='outlined'
            />
          </div>
          <Grid data-testid='activePermissions' item={true}>
            <CustomList checked={checked} handleToggle={handleToggle} items={activeList} />
          </Grid>
        </div>
      </Grid>
    </div>
  );
}
