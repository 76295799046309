/* eslint-disable jsdoc/require-param */
/**
 * @module PlanProvider
 */
import { updatePlan, useGetPlan, useGetPlanDays } from 'api/plans';
import { Plan } from 'components/Plans/types';
import { API_STATUS, sortDays } from 'helpers';
import { planHasNarratedAudio } from 'helpers/plan-has-narrated-audio';
import update from 'immutability-helper';
import PropTypes from 'prop-types';
import * as React from 'react';
import { Empty, NumberLike } from 'types/misc';

import { useGetLanguages } from '../../api/languages';
import { useGetOrganization } from '../../api/organizations';
import { Organization } from '../../components/Organizations/types';

export interface MissingNarratedAudioDay {
  day: Plan.Day;
  position: number;
}

export interface PlanContextProps {
  dayNumber?: number;
  dayPosition?: number;
  disableNewDay: boolean;
  getMissingNarratedAudioDays?: () => Array<MissingNarratedAudioDay> | null;
  handleApprovePlanInfo: () => Promise<void>;
  hasNarratedAudio: boolean;
  hasPlanBeenPublished: boolean;
  loadingStatus: string;
  plan: Plan.Plan | Empty;
  planDays: Array<Plan.Day>;
  planError?: Error;
  planLanguage: Language | Empty;
  planStatus: Plan.Status;
  primaryOrganization: Organization.Organization | Empty;
  refreshPlanData: () => void;
  scrollRef?: React.RefObject<HTMLDivElement>;
  setDayNumber: React.Dispatch<React.SetStateAction<number | undefined>>;
  setDayPosition: React.Dispatch<React.SetStateAction<number | undefined>>;
  setPlanDays: React.Dispatch<React.SetStateAction<Array<Plan.Day>>>;
  updatePlanDetails: (planDetailToUpdate: Record<string, any>) => void;
  updatedPlan: Plan.Plan | Empty;
}

export const PlanContext = React.createContext<PlanContextProps | undefined>(undefined);

/**
 * @typedef {(number|string)} NumberLike
 */

interface PlanProviderProps {
  /** Id of plan. */
  id: NumberLike;
}
/**
 * Plan provider.
 *
 * @returns {React.ReactElement} Plan context.
 */
function PlanProvider({ children, id }: React.PropsWithChildren<PlanProviderProps>) {
  const [updatedPlan, setUpdatedPlan] = React.useState<Plan.Plan | {}>({});
  const [planDays, setPlanDays] = React.useState<Array<Plan.Day>>([]);
  const [primaryOrganization, setOrganization] = React.useState<Organization | {}>({});
  const [dayNumber, setDayNumber] = React.useState<number>();
  const [dayPosition, setDayPosition] = React.useState<number>();

  // TODO: Batch These calls
  const { data: plan, status: planLoadingStatus, refetch: planRefetch, error: planServerError } = useGetPlan(id);

  const {
    data: currentPlanDays,
    status: daysLoadingStatus,
    refetch: daysRefetch,
    error: dayServerError,
  } = useGetPlanDays({ planId: id });

  const { data: planLanguages, status: languagesLoadingStatus, error: languageServerError } = useGetLanguages();

  const {
    data: organization,
    status: orgsLoadingStatus,
    error: organizationServerError,
  } = useGetOrganization(plan?.primary_organization_id);

  const loadingStatus: string = React.useMemo(() => {
    const statuses = [planLoadingStatus, daysLoadingStatus, languagesLoadingStatus, orgsLoadingStatus];
    // eslint-disable-next-line no-nested-ternary
    return statuses.some(status => status === API_STATUS.ERROR)
      ? API_STATUS.ERROR
      : statuses.some(status => status === API_STATUS.LOADING)
        ? API_STATUS.LOADING
        : API_STATUS.SUCCESS;
  }, [planLoadingStatus, daysLoadingStatus, languagesLoadingStatus, orgsLoadingStatus]);

  React.useEffect(() => {
    if (plan) {
      setUpdatedPlan(plan);
    }
  }, [plan]);

  React.useEffect(() => {
    if (currentPlanDays) {
      setPlanDays(currentPlanDays.data);
    }
  }, [currentPlanDays]);

  React.useEffect(() => {
    if (organization) {
      setOrganization(organization);
    }
  }, [organization]);

  const disableNewDay = React.useMemo(() => {
    if (plan) {
      return Boolean(
        // Even if the plan is not in the submitted status, there is a chance
        // it was published At Some Point™. We need to fallback to another
        // indicator of published status like `external_plan_url` or
        // `external_plan_id`. Ideally in the future there would be a different
        // and permanent indicator of published status, similar to the narrated
        // audio feature.
        plan.status === Plan.Status.PUBLISHED || plan.external_plan_url
      );
    }
    return false;
  }, [plan]);

  const scrollRef = React.useRef<HTMLDivElement>(null);
  const hasNarratedAudio = React.useMemo(() => {
    if (plan) {
      return planHasNarratedAudio(plan);
    }
    return false;
  }, [plan]);

  const planStatus = React.useMemo(() => {
    if (plan) {
      return plan.status;
    }
    return Plan.Status.DRAFT;
  }, [plan]);

  const hasPlanBeenPublished = React.useMemo(() => {
    if (plan) {
      return Boolean(plan.external_plan_id);
    }
    return false;
  }, [plan]);

  const getMissingNarratedAudioDays = () => {
    if (hasNarratedAudio && planDays) {
      return planDays
        .map((day, dayIdx) => ({ position: dayIdx + 1, day }))
        .filter(day => !day.day.narrated_audio_attachment);
    }

    return null;
  };

  React.useEffect(() => {
    if (Array.isArray(planDays)) {
      sortDays(planDays);
    }
  }, [planDays]);

  async function refreshPlan() {
    await Promise.allSettled([planRefetch(), daysRefetch()]);
  }

  async function handleApprovePlanInfo() {
    try {
      await updatePlan(id, {
        status: Plan.Status.APPROVED,
      });
      refreshPlan();
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(error.message);
      }
    }
  }

  async function updatePlanDetails(planDetailToUpdate: Record<string, any>) {
    setUpdatedPlan(
      update(updatedPlan, {
        [Object.keys(planDetailToUpdate)[0]]: {
          $set: Object.values(planDetailToUpdate)[0],
        },
      })
    );
  }

  const planError = React.useMemo(() => {
    return (
      ([planServerError, dayServerError, languageServerError, organizationServerError].find(
        error => error instanceof Error
      ) as Error) || null
    );
  }, [planServerError, dayServerError, languageServerError, organizationServerError]);

  const value: PlanContextProps = {
    dayNumber,
    dayPosition,
    disableNewDay,
    handleApprovePlanInfo,
    hasNarratedAudio,
    hasPlanBeenPublished,
    loadingStatus,
    plan: plan ?? {},
    planDays,
    planStatus,
    scrollRef: scrollRef ?? undefined,
    setDayNumber,
    setDayPosition,
    setPlanDays,
    updatePlanDetails,
    refreshPlanData: refreshPlan,
    updatedPlan,
    getMissingNarratedAudioDays: getMissingNarratedAudioDays ?? undefined,
    planError: planError ?? undefined,
    planLanguage: plan?.language_id !== undefined ? (planLanguages?.[plan.language_id] ?? {}) : {},
    primaryOrganization: primaryOrganization ?? {},
  };

  return <PlanContext.Provider value={value}>{children}</PlanContext.Provider>;
}

PlanProvider.propTypes = {
  children: PropTypes.element.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

/**
 * Plan context hook - Can be used within a plan provider.
 *
 * @returns {object} Plan context.
 * @throws {Error} - Throws when usePlan is not used within plan provider.
 */
function usePlan() {
  const context = React.useContext(PlanContext);
  if (context === undefined) {
    throw new Error('usePlan must be used within a PlanProvider');
  }
  return context;
}

export { PlanProvider, usePlan };
