import { Select } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useAlert } from '@youversion/react';
import { useUpdateOrganizationMembership } from 'api/organization_memberships';
import { QUERY_KEYS } from 'helpers';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NumberLike } from 'types/misc';

interface ChangeMembershipRoleProps {
  membershipId: NumberLike;
  role: string;
  roleOptions: Array<string>;
}

export function ChangeMembershipRole({ membershipId, role: activeRole, roleOptions }: ChangeMembershipRoleProps) {
  const [role, setRole] = useState(activeRole);
  const { throwAlert } = useAlert();
  const { mutateAsync: updateMembership } = useUpdateOrganizationMembership();
  const queryClient = useQueryClient();
  const { t } = useTranslation('organizations');

  const handleChange = async (selectedRole: string) => {
    setRole(selectedRole);
    try {
      await updateMembership({ role: selectedRole, membershipId });
      queryClient.invalidateQueries([QUERY_KEYS.ORGANIZATION_MEMBERSHIPS]);
      throwAlert({
        id: 'organization_membership_update_success',
        key: 'organization_membership_update_success',
        message: t('single_org.membership_table.roles.organization_membership_update_success'),
        timeout: 3000,
        type: 'success',
      });
    } catch (error) {
      throwAlert({
        id: 'organization_membership_update_error',
        key: 'organization_membership_update_error',
        message: t('single_org.membership_table.roles.organization_membership_update_error'),
        timeout: 3000,
        type: 'error',
      });
    }
  };

  return (
    <Select native={true} onChange={e => handleChange(e.target.value as string)} value={role} variant='outlined'>
      {roleOptions.map(roleOption => (
        <option key={roleOption} value={roleOption}>
          {t(`single_org.membership_table.roles.${roleOption}`)}
        </option>
      ))}
    </Select>
  );
}
