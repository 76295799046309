import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface AdminWrapperProps {
  children: React.ReactElement | React.ReactNode;
}

const useStyles = makeStyles(theme => ({
  title: {
    backgroundColor: 'white',
    color: '#1A3026',
    insetBlockStart: '-10px',
    padding: `0 ${theme.spacing(2)}`,
    position: 'absolute',
  },
  wrapper: {
    border: '1px solid lightgrey',
    borderColor: '#DDDBDB',
    borderRadius: theme.spacing(2),
    padding: theme.spacing(1),
    width: '100%',
  },
}));

export default function AdminWrapper({ children }: AdminWrapperProps) {
  const classes = useStyles();
  const { t } = useTranslation('plans');

  return (
    <Box className={classes.wrapper} position='relative'>
      <span className={classes.title}>{t('plans:new_translation.admin_only')}</span>
      {children}
    </Box>
  );
}
