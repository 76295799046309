/**
 * @module BibleReferencePicker
 */
/* eslint-disable react/no-multi-comp */
import { Box, CircularProgress } from '@mui/material';
import { useGetBibleVersion } from 'api/bible-versions';
import booksOrder from 'assets/books-order.json';
import bookData from 'assets/niv-verses-short.json';
import classnames from 'classnames';
import styles from 'components/Plans/Plans.module.scss';
import { translateBook } from 'helpers';
import { APP_ELEMENT } from 'helpers/constants';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';

const initialState = {
  activeSection: 'BOOK',
  selectedBook: null,
  selectedChapter: null,
  selectedVerse: null,
  extVerse: null,
};

/**
 * Bible reference picker.
 *
 * @alias module:BibleReferencePicker
 *
 * @param {object} props - The component props object.
 * @param {boolean} props.isOpen - Whether or not the modal is open.
 * @param {Function} props.onComplete - Function to be fired when choosing references is complete. The new reference object is passed in to this function. Shape: { book, chapter, verse, extChapter, extVerse }.
 *
 * @returns {React.ReactElement} - The BibleReferencePicker component.
 *
 * @example
 * import BibleReferencePicker from 'components/ui/bible-reference-picker'
 *
 * function MyComponent() {
 *   const [bibleReferences, setBibleReferences] = React.useState([])
 *   const [isReferencePickerOpen, setIsReferencePickerOpen] = React.useState(false)
 *
 *   function handleAddBibleReferences(newReference) {
 *     // const { book, chapter, verse, extChapter, extVerse } = newReference
 *     setBibleReferences((prevState) => {
 *       return [...prevState, newReference]
 *     })
 *     setIsReferencePickerOpen(false)
 *   }
 *
 *   return (
 *     <>
 *       <button onClick={() => setIsReferencePickerOpen(true)}>Add Bible References</button>
 *       <BibleReferencePicker
 *         isOpen={isReferencePickerOpen}
 *         onComplete={handleUpdateBibleReferences}
 *       />
 *     </>
 *   )
 * }
 */
export default function BibleReferencePicker({ isOpen, onComplete }) {
  const [state, updateState] = React.useState(initialState);
  const { t, i18n } = useTranslation(['common', 'plan_days']);
  const { isLoading, data } = useGetBibleVersion(i18n.language);

  function setState(newState) {
    updateState(prevState => {
      return { ...prevState, ...newState };
    });
  }

  React.useEffect(() => {
    if (isOpen) {
      updateState(initialState);
    }
  }, [isOpen]);

  function setSelection(selectionData) {
    const newSelection = {
      selectedBook: selectionData.book,
      selectedChapter: selectionData.chapter,
      selectedVerse: selectionData.verse,
      extVerse: selectionData.extVerse,
    };

    setState(_.omitBy(newSelection, _.isUndefined));
  }

  function setBook(book) {
    setSelection({
      book,
      chapter: null,
      verse: null,
      extVerse: null,
    });
    setState({ activeSection: 'CHAPTER' });
  }

  function setChapter(chapter) {
    setSelection({
      chapter,
      verse: null,
      extVerse: null,
    });
    setState({ activeSection: 'VERSE' });
  }

  function setExtVerse(verse) {
    setSelection({ extVerse: verse });
  }

  function setVerse(verse) {
    setSelection({ verse, extVerse: null });
    setState({ activeSection: 'EXTEND' });
  }

  function isSaveDisabled() {
    let isDisabled = true;
    const { selectedBook: book, selectedChapter: chapter } = state;
    if (book && chapter) {
      isDisabled = false;
    }

    return isDisabled;
  }

  function switchSection(section) {
    if (section === 'CHAPTER' || section === 'VERSE' || section === 'EXTEND') {
      if (!state.selectedBook) {
        return;
      }

      if ((section === 'VERSE' || section === 'EXTEND') && !state.selectedChapter) {
        return;
      }

      if (section === 'EXTEND' && !state.selectedVerse) {
        return;
      }
    }

    setState({
      activeSection: section,
    });
  }

  function switchSectionHandler(section, event) {
    event.preventDefault();
    switchSection(section);
  }

  const chooserStyle = {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    overflow: 'auto',
  };

  let sectionChooser;
  switch (state.activeSection) {
    case 'BOOK':
      sectionChooser = (
        <BookChooser
          bibleData={data}
          onSelectBook={bookCode => {
            setBook(bookCode);
          }}
          style={chooserStyle}
        />
      );
      break;
    case 'CHAPTER':
      sectionChooser = (
        <RangeChooser
          onSelectNumber={n => {
            setChapter(n);
          }}
          rangeMax={bookData[state.selectedBook].length}
        />
      );
      break;
    case 'VERSE':
      sectionChooser = (
        <RangeChooser
          onSelectNumber={n => {
            setVerse(n);
          }}
          rangeMax={bookData[state.selectedBook][state.selectedChapter - 1]}
        />
      );
      break;
    case 'EXTEND':
      // If there aren't any possible verses to select, display a message
      // instead of a chooser
      if (bookData[state.selectedBook][state.selectedChapter - 1] <= state.selectedVerse) {
        sectionChooser = <div>{t('plan_days:plan_day_edit.bible_reference.no_verses_available_for_extending')}</div>;
        break;
      }

      sectionChooser = (
        <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <p className={styles.caption}>
              {t('plan_days:plan_day_edit.bible_reference.extend_it_into_a_passage_by_selecting_end_verse')}
            </p>
            <div style={{ overflow: 'auto' }}>
              <RangeChooser
                onSelectNumber={n => {
                  setExtVerse(n);
                }}
                rangeMax={bookData[state.selectedBook][state.selectedChapter - 1]}
                rangeMin={state.selectedVerse}
              />
            </div>
          </div>
        </div>
      );
      break;
    default:
  }

  const hasExtValues = !!state.extVerse;

  // React-modal HIDES non-modal content (via ARIA). That itself is fine,
  // but the *default* behavior hides the <body> element, which is silly
  // because that's also the default parent element for modals.
  // According to the react-modal docs, your App element:
  // - Should not be a parent or ancestor of the element where modals mount
  // - Should be specified with `setAppElement` before Modals are created.
  // See: https://reactcommunity.org/react-modal/accessibility/#app-element
  Modal.setAppElement('#root');

  return (
    <Modal
      appElement={APP_ELEMENT}
      className={styles.bibleRefModal}
      contentLabel='ReferenceModal'
      isOpen={isOpen}
      onRequestClose={() => {
        onComplete(null);
      }}
      overlayClassName={styles.overlayStyle}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <div className={styles.bibleRefModalHeader}>
          <div
            style={{
              display: 'flex',
              flexGrow: 1,
              flexDirection: 'column',
              alignItems: 'baseline',
            }}
          >
            <h1>
              {state.selectedBook && !isLoading ? (
                <>
                  {translateBook(state.selectedBook, data?.books, t('common:unknown_book'))}
                  &nbsp;
                  {state.selectedChapter || '_'}
                  &nbsp;:&nbsp;
                  {state.selectedVerse || '_'}
                  {hasExtValues ? (
                    <span>
                      &nbsp;-&nbsp;
                      {state.selectedChapter || '_'}
                      &nbsp;:&nbsp;
                      {state.extVerse || '_'}
                    </span>
                  ) : null}
                </>
              ) : (
                t('plan_days:plan_day_edit.bible_reference.add_a_bible_reference')
              )}
            </h1>
          </div>
          <div className={styles.actionButtonsContainer}>
            <button
              className={styles.cancelBtn}
              onClick={e => {
                e.preventDefault();
                onComplete(null);
              }}
              style={{ padding: 10, width: 135 }}
              type='button'
            >
              {t('common:cancel')}
            </button>
            <button
              className={styles.saveButton}
              disabled={isLoading || isSaveDisabled()}
              onClick={e => {
                e.preventDefault();
                onComplete({
                  book: state.selectedBook,
                  chapter: state.selectedChapter,
                  verse: state.selectedVerse,
                  extChapter: state.selectedChapter,
                  extVerse: state.extVerse,
                });
              }}
              style={{ width: 135, marginInlineStart: 10, padding: 10 }}
              type='button'
            >
              {t('common:add')}
            </button>
          </div>
        </div>

        {isLoading ? (
          <Box alignItems='center' display='flex' flex={1} justifyContent='center'>
            <CircularProgress aria-label={t('common:loading')} />
          </Box>
        ) : (
          <>
            <div className={styles.tabBar}>
              <ul>
                <li
                  className={state.activeSection === 'BOOK' ? styles.activeTab : ''}
                  onClick={event => switchSectionHandler('BOOK', event)}
                >
                  {t('plan_days:plan_day_edit.bible_reference.book')}
                </li>

                <li
                  className={classnames(
                    state.activeSection === 'CHAPTER' ? styles.activeTab : '',
                    !state.selectedBook && styles.inactiveTab
                  )}
                  onClick={event => switchSectionHandler('CHAPTER', event)}
                >
                  {t('plan_days:plan_day_edit.bible_reference.chapter')}
                </li>

                <li
                  className={classnames(
                    state.activeSection === 'VERSE' ? styles.activeTab : '',
                    !state.selectedChapter && styles.inactiveTab
                  )}
                  onClick={event => switchSectionHandler('VERSE', event)}
                >
                  {t('plan_days:plan_day_edit.bible_reference.verse_optional')}
                </li>

                <li
                  className={classnames(
                    state.activeSection === 'EXTEND' ? styles.activeTab : '',
                    !state.selectedVerse && styles.inactiveTab
                  )}
                  onClick={event => switchSectionHandler('EXTEND', event)}
                >
                  {t('plan_days:plan_day_edit.bible_reference.end_verse_optional')}
                </li>
              </ul>
            </div>

            {sectionChooser}
          </>
        )}
      </div>
    </Modal>
  );
}

BibleReferencePicker.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired,
};

function BookChooser({ style, onSelectBook, bibleData }) {
  const { t } = useTranslation(['common', 'plan_days']);
  const bookForCode = bookCode => (
    <div key={bookCode}>
      {/* TOOD: These should be List Items. https://lifechurch.atlassian.net/browse/PP-552 */}
      <div
        className={styles.bookLink}
        onClick={e => {
          e.preventDefault();
          onSelectBook(bookCode);
        }}
      >
        {translateBook(bookCode, bibleData?.books, t('common:unknown_book'))}
      </div>
    </div>
  );

  const bookColumnStyle = {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  };

  const headerStyles = {
    marginBlockEnd: '0.5rem',
    marginBlockStart: 0,
  };

  return (
    <div style={style}>
      <div style={bookColumnStyle}>
        <h2 style={headerStyles}>{t('plan_days:plan_day_edit.bible_reference.old_testament')}</h2>
        {booksOrder.old.map(bookForCode)}
      </div>
      <div style={bookColumnStyle}>
        <h2 style={headerStyles}>{t('plan_days:plan_day_edit.bible_reference.new_testament')}</h2>
        {booksOrder.new.map(bookForCode)}
      </div>
    </div>
  );
}

BookChooser.propTypes = {
  style: PropTypes.shape({}),
  onSelectBook: PropTypes.func.isRequired,
  bibleData: PropTypes.shape({
    books: PropTypes.arrayOf(
      PropTypes.shape({
        usfm: PropTypes.string,
        human: PropTypes.string,
        human_long: PropTypes.string,
      })
    ),
  }),
};

BookChooser.defaultProps = {
  style: {},
};

function RangeChooser({ style, rangeMax, rangeMin, onSelectNumber }) {
  return (
    <div>
      <ul className={styles.rangeList} style={style}>
        {_.range(rangeMin, rangeMax).map(n => (
          <li
            key={n}
            onClick={e => {
              e.preventDefault();
              onSelectNumber(n + 1);
            }}
            style={{
              padding: '0 5px',
              cursor: 'pointer',
            }}
          >
            {n + 1}
          </li>
        ))}
      </ul>
    </div>
  );
}

RangeChooser.propTypes = {
  style: PropTypes.shape({}),
  rangeMax: PropTypes.number.isRequired,
  rangeMin: PropTypes.number,
  onSelectNumber: PropTypes.func.isRequired,
};

RangeChooser.defaultProps = {
  style: {},
  rangeMin: 0,
};
