import { Box, Typography } from '@mui/material';
import { useGetUsers } from 'api/users';
import { UsersDataGrid } from 'components/users/DataGrid';
import { UsersFilters } from 'components/users/Filtering';
import { API_STATUS, USERS_PER_PAGE } from 'helpers';
import { isNaN } from 'lodash';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export const Users = () => {
  const [query] = useSearchParams();

  const page = useMemo(() => {
    const pageFromQuey = query.get('page');
    if (pageFromQuey && !isNaN(Number(pageFromQuey))) {
      if (!query.get('search')) {
        return pageFromQuey;
      }
    }

    return '1';
  }, [query]);

  const {
    data,
    status: loadingStatus,
    refetch,
    error,
  } = useGetUsers({
    filters: {
      search: query.get('search') ?? undefined,
    },
    sorter: {
      order: query.get('order') ?? 'desc',
      sort: query.get('sort') ?? 'first_name',
    },
    pagination: {
      page: Number(page),
      per: USERS_PER_PAGE,
    },
  });

  const isLoading = loadingStatus === API_STATUS.LOADING;
  return (
    <Box mt={2}>
      <Typography variant='h1'>Users</Typography>
      <UsersFilters />
      <Box mt={3}>
        <UsersDataGrid
          data={data}
          error={error ? new Error('Error occured while fetching users') : undefined}
          isLoading={isLoading}
          refetch={refetch}
        />
      </Box>
    </Box>
  );
};
