import { Box, Typography } from '@mui/material';
import { useGetGroups } from 'api/groups';
import { Can } from 'auth';
import GroupsList from 'components/Groups/Table';
import LoaderOverlay from 'components/LoaderOverlay';
import { Link } from 'components/Navigation/Toolbar';
import { API_STATUS } from 'helpers';

export default function Groups() {
  const { status: groupsLoadingStatus, data: groups } = useGetGroups();

  if (groupsLoadingStatus !== API_STATUS.SUCCESS) {
    return <LoaderOverlay />;
  }

  return (
    <>
      <Box
        alignItems='center'
        display='flex'
        flexWrap='wrap'
        justifyContent='space-between'
        mb='1rem'
        mt='2rem'
        width='100%'
      >
        <Box mb={{ xs: '1rem', sm: '0' }} width='40%'>
          <Typography variant='h1'>Groups</Typography>
        </Box>
        <Box>
          <Can user='create:group'>
            <Link icon='icon icon-arrows-plus icon-2x' to='new' variant='dark'>
              Add a Group
            </Link>
          </Can>
        </Box>
      </Box>
      <GroupsList items={groups ?? []} />
    </>
  );
}
