/* eslint-disable jsdoc/require-param */
/**
 * @module  activeStorageApiMethods
 */
import { DirectUpload } from '@rails/activestorage';
import { useMutation } from '@tanstack/react-query';
import { getToken } from 'helpers';
import { API_ADDRESS, DEFAULT_PLANPORTAL_HEADERS, PLAN_PORTAL_API_URL } from 'helpers/constants';

export interface UploadFileResponse {
  biteSize: number;
  checksum: string;
  contentType: string;
  filename: string;
  signedId: string;
}

/**
 * Upload a  file to S3.
 *
 * @returns {Promise<object>} - The upload response blob.
 */
export async function uploadFile(file: File): Promise<UploadFileResponse> {
  return new Promise((resolve, reject) => {
    const upload = new DirectUpload(file, `${API_ADDRESS}/v1/direct_uploads`);

    upload.create((error, blob) => {
      if (error) {
        return reject(new Error(error.message));
      }
      const { byte_size: biteSize, content_type: contentType, signed_id: signedId, ...blobRest } = blob;
      return resolve({
        biteSize,
        contentType,
        signedId,
        ...blobRest,
      });
    });
  });
}

export type AttachmentFileType = 'yv_video' | 'video' | 'audio' | 'image' | 'narrated_audio';

export const isAttachmentFileType = (value: string): value is AttachmentFileType => {
  return ['yv_video', 'video', 'audio', 'image', 'narrated_audio'].includes(value);
};

interface AttachFileProps {
  dayId?: number;
  /** The type of file to be posted. Typically only used for plan days. */
  fileType: AttachmentFileType;
  /** The organization id to which the file attachment will be associated. */
  organizationId: number;
  /** The direct upload signed_id. */
  signedId: string;
}

interface AttachFileRequest {
  file: string;
  organization_id: number;
  plan_day_id?: number;
  type: AttachmentFileType;
}

interface AttachFileResponse {
  /** When file was uploaded. */
  created_at: Date;
  /** Url to access the file remotely. */
  file_url: string;
  /** Id of file attachment uploaded. */
  id: string;
  /** Organization id. */
  organization_id: number;
  /** Status of upload. */
  service_status: string;
  /** When file was last updated. */
  updated_at: Date;
}

/**
 * Post a direct upload file attachment to Rails ActiveStorage.
 *
 * @throws {Error} - Throws an error if a narrated audio file is given without a plan day id.
 * @throws {Error} - Throws an error if the request fails.
 *
 * @returns {Promise<object>} - Resolves the file_attachment data object.
 *
 * @example
 * // Using the fileType parameter
 * const response = await attachFile({
 *   fileType: 'audio',
 *   organizationId: '3',
 *   signedId: '12345abcdefg',
 * })
 *
 */
export async function attachFile({
  fileType,
  organizationId,
  signedId,
  dayId,
}: AttachFileProps): Promise<AttachFileResponse | undefined> {
  if (fileType === 'narrated_audio' && !dayId) {
    throw new Error('Narrated audio must be paired with a plan day id.');
  }

  const requestBody: AttachFileRequest = {
    type: fileType === 'yv_video' ? 'video' : fileType,
    file: signedId,
    organization_id: organizationId,
    ...(fileType === 'narrated_audio' && { plan_day_id: dayId }),
  };

  try {
    const authToken = await getToken();
    const response = await fetch(`${PLAN_PORTAL_API_URL}/4.0/file_attachments`, {
      body: JSON.stringify(requestBody),
      headers: DEFAULT_PLANPORTAL_HEADERS(authToken),
      method: 'POST',
    });
    return await response.json();
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    }
  }
}
export const useAttachFile = () => useMutation(attachFile);

interface GetFileProcessingStatusProps {
  /** The file identifier. */
  fileId: string;
  /** The type of file to be posted. */
  fileType: AttachmentFileType;
}

export interface GetFileProcessingStatusResponse {
  postprocessing_state?: string;
  postprocessing_status_details?: string;
  service_status: string;
  youtube_status_details?: string;
  zencoder_job_details?: ZencoderJobDetails;
}

export interface ZencoderJobDetails {
  finished_at: Date;
  id: number;
  input_media_file: ZencoderInputMediaFile;
  state: string;
  submitted_at: Date;
  thumbnails: Array<ZencoderThumbnail>;
}

export interface ZencoderInputMediaFile {
  error_class?: string;
  error_message?: string;
  state?: string;
  url?: string;
}

export interface ZencoderThumbnail {
  url?: string;
}

/**
 * Get a block file attachment processing status.
 *
 * @throws {Error} - Throws an error if the file type is not supported.
 * @throws {Error} - Throws an error if the request fails.
 *
 * @returns {Promise<object>} - Resolves the [fileType]_attachment_status data object.
 *
 * @example
 * const response = await getFileProcessingStatus({
 *   fileId: '1234abcd',
 *   fileType: 'audio',
 * })
 */
export async function getFileProcessingStatus({
  fileId,
  fileType,
}: GetFileProcessingStatusProps): Promise<GetFileProcessingStatusResponse | undefined> {
  if (!['audio', 'video'].includes(fileType)) {
    throw new Error(`Unsupported fileType ${fileType}.`);
  }

  try {
    const authToken = await getToken();
    const response = await fetch(`${PLAN_PORTAL_API_URL}/4.0/file_attachments/${fileId}/status`, {
      headers: DEFAULT_PLANPORTAL_HEADERS(authToken),
      method: 'GET',
    });
    return await response.json();
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    }
  }
}
