import { Chat } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { gray } from '@youversion/react/styles/colors-v3';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { AcknowledgeButton } from '../../AcknowledgeButton';

interface ActivityItemProps {
  item: ActivityItem;
}

export const CommentCreatedItem = ({ item }: ActivityItemProps) => {
  const planId = item.object.parent_id;
  const planOrDayId = item.object.id;
  const objectPath = planId ? `/plans/${planId}/days/${planOrDayId}` : `/plans/${planOrDayId}`;

  return (
    <Box border={1} borderColor={gray[10]} borderRadius='10px' mb={2} p={2}>
      <Box alignItems='flex-start' display='flex' flexDirection='row'>
        <Box mr={2} mt={1}>
          <Chat style={{ color: gray[20], fontSize: 20 }} />
        </Box>
        <Box>
          <Typography variant='body1'>
            <Link to={`/users/${item.subject.id}`}>
              {item.subject.first_name} {item.subject.last_name?.charAt(0)}
            </Link>{' '}
            commented on <Link to={objectPath}>7{item.object.name}</Link> for review (ID: {planId ?? planOrDayId})
          </Typography>
          <Typography variant='caption'>{moment(item.created_at).format('LLL')}</Typography>
        </Box>
      </Box>

      <Box alignItems='center' display='flex' flexDirection='row' justifyContent='flex-end'>
        <AcknowledgeButton item={item} />
      </Box>
    </Box>
  );
};
