/**
 * @module OrganizationMembershipReducer
 */

import { getPaginationInfo, handleApiAction } from 'helpers';
import update from 'immutability-helper';
import { handleActions } from 'redux-actions';
import {
  DESTROY_MEMBERSHIP,
  GET_MEMBERSHIPS,
  UPDATE_MEMBERSHIP,
  UPDATE_MEMBERSHIP_RESET,
} from 'state/actions/organization_memberships/constants';

/*
  Example state:

  organizationMemberships: {
    action: {
      update: {
        status: 'pending | success | failure | null', // used for tracking the status of an action triggered via the UI.
        meta: {}
      }
    },
    byOrg: {
      loading: false,
      orgs: {
        1: {
          memberships: [{...}, {...}, {...}],
          pagination: {[pagination info]}
        }
        2: {
          ...
        }
      }
    }
  }

*/

const initialState = {
  action: {
    update: {
      status: null,
      meta: {
        organization_membership_id: null,
      },
    },
  },
  byOrg: {
    loading: false,
    orgs: {},
  },
};

export default handleActions(
  {
    [GET_MEMBERSHIPS]: handleApiAction((_, action) => ({
      start: state =>
        update(state, {
          byOrg: {
            loading: { $set: true },
          },
        }),

      success: state =>
        update(state, {
          byOrg: {
            loading: { $set: false },
            orgs: {
              [action.payload.meta.organization_id]: {
                $set: {
                  memberships: action.payload.organization_memberships,
                  pagination: getPaginationInfo(action.payload.meta),
                },
              },
            },
          },
        }),
    })),

    [UPDATE_MEMBERSHIP]: handleApiAction((_, action) => ({
      start: state => {
        return update(state, {
          action: {
            update: {
              status: { $set: 'pending' },
            },
          },
        });
      },

      success: state => {
        const membership = action.payload.organization_membership;
        const membershipId = membership.id;
        const orgId = membership.organization_id;
        const orgMemberships = state.byOrg.orgs[orgId].memberships;
        const index = orgMemberships.findIndex(mem => mem.id === membershipId);

        return update(state, {
          action: {
            update: {
              status: { $set: 'success' },
              meta: { $set: action.meta },
            },
          },
          byOrg: {
            orgs: {
              [orgId]: {
                memberships: {
                  [index]: { $set: membership },
                },
              },
            },
          },
        });
      },

      failure: state => {
        return update(state, {
          action: {
            update: {
              status: { $set: 'failure' },
              meta: { $set: {} },
            },
          },
        });
      },
    })),

    [UPDATE_MEMBERSHIP_RESET]: state => {
      return update(state, {
        action: {
          update: {
            status: { $set: 'null' },
            meta: { $set: {} },
          },
        },
      });
    },

    [DESTROY_MEMBERSHIP]: handleApiAction((_, action) => ({
      success: state => {
        const { meta } = action;
        const orgId = meta.organization_id;
        const membershipId = meta.organization_membership_id;
        const orgMemberships = state.byOrg.orgs[orgId].memberships;
        const indexOfDeleted = orgMemberships.findIndex(mem => mem.id === membershipId);

        return update(state, {
          byOrg: {
            orgs: {
              [orgId]: {
                memberships: { $splice: [[indexOfDeleted, 1]] },
              },
            },
          },
        });
      },
    })),
  },
  initialState
);
