/**
 * @module reducersConstants
 */

// globalNotificationsReducer
export const TYPE_SUCCESS = 'success';

// persistReducer
export const KEY_TOKEN = 'token';
export const KEY_ORGID = 'orgId';
export const LOAD_TOKEN = 'persist/LOAD_TOKEN';
export const LOAD_ORGID = 'persist/LOAD_ORGID';
