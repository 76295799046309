import { useGetStaffRepresentatives } from 'api/users';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

interface SelectStaffRepresentativeProps {
  onChange: (value: number) => void;
  org: Organization;
  staffRepId: number;
}

export function SelectStaffRepresentative({ org, onChange, staffRepId }: SelectStaffRepresentativeProps) {
  const { data } = useGetStaffRepresentatives();
  const { t } = useTranslation('organizations');

  const options = data?.map((rep: StaffRepresentative) => ({
    value: rep.id,
    label: `${rep.first_name} ${rep.last_name}`,
  }));

  return (
    <Select
      onChange={(change: { value: number }) => onChange(change.value)}
      options={options}
      placeholder={
        org?.staff_representative
          ? `${org.staff_representative?.first_name} ${org?.staff_representative?.last_name}`
          : t('edit_org.form.select_a_rep')
      }
      value={options?.find((item: { value: number }) => item.value === staffRepId)}
    />
  );
}
