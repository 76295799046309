import { standardAction } from '../../utils';
import { APP_BOOTSTRAP_COMPLETED, APP_ROUTE_CHANGED } from './constants';

function appRouteChanged() {
  return standardAction(APP_ROUTE_CHANGED);
}

/* One off bootstrap actions for App initialization */

function appBootstrapCompleted() {
  return standardAction(APP_BOOTSTRAP_COMPLETED);
}

export { appBootstrapCompleted, appRouteChanged };
