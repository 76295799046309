import { Empty } from 'types/misc';

const MEMBERSHIP_MANAGEMENT_PERMISSIONS = {
  admin: {
    editPermissions: ['all'],
    permittedValues: {
      role: ['owner', 'manager', 'member'],
    },
  },
  forRole: {
    owner: {
      editPermissions: ['owner', 'manager', 'member', 'self'],
      permittedValues: {
        role: ['owner', 'manager', 'member'],
      },
    },
    manager: {
      editPermissions: ['manager', 'member', 'self'],
      permittedValues: {
        role: ['manager', 'member'],
      },
    },
    member: {
      editPermissions: ['self'],
      permittedValues: {
        role: ['member'],
      },
    },
  },
};

/**
 * Get permitted values for a Membership resource attribute.
 *
 * @param {string} whichAttribute -  - The attribute to lookup values against.
 * @param {object} editor -  Membership object for the one editing the membership.
 * @param {object} options
 * @param { Bool }   options.isAdmin
 */

export function permittedValuesForMembership(
  whichAttribute: 'role',
  editor: OrganizationMembership | Empty,
  isAdmin: boolean = false
): Array<string> {
  if (isAdmin) {
    return MEMBERSHIP_MANAGEMENT_PERMISSIONS.admin.permittedValues[whichAttribute];
  }

  if ('id' in editor && ['owner', 'manager', 'member'].includes(editor.role)) {
    const rolePermissions = MEMBERSHIP_MANAGEMENT_PERMISSIONS.forRole[editor.role as 'owner' | 'manager' | 'member'];
    if (rolePermissions) {
      return rolePermissions.permittedValues[whichAttribute];
    }
  }

  return [];
}

/**
 * Query organization edit permissions.
 *
 * @param {object} user - The logged in user.
 * @param {number} orgId - The selected organization id.
 *
 * @returns {boolean} - Boolean value to specify if user is a admin, owner, or manager.
 */
export function canEditOrg(user: User, orgId: number) {
  const allowed = ['admin', 'owner', 'manager'];
  const selectedOrg = user?.organization_memberships?.find(member => member.organization_id === orgId);

  if (selectedOrg?.id) {
    return allowed.includes(selectedOrg.role);
  }

  return false;
}
