import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import { Box, Container, LinearProgress, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { createReactDangerousMarkup, defaultBlockTypes } from '@youversion/mui-block-editor';
import { DropAreaFailed } from '@youversion/mui-block-editor/components/blocks/drop-area/shared-states';
import { DropAreaDownloadFile } from '@youversion/mui-block-editor/components/blocks/drop-area/shared-states/drop-area-download-file';
import { statusTypes } from '@youversion/utils';
import DownloadContainer from 'components/DownloadContainer';
import { Plan } from 'components/Plans/types';
import { submissionStatuses } from 'helpers/constants';
import { usePreloadBlockData } from 'helpers/content-blocks/use-preload-block-data';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  imageBoundaries: {
    width: '100%',
    height: 'auto',
  },
  previewContainer: {
    padding: 0,
    marginBlockStart: theme.spacing(2),
    marginBlockEnd: theme.spacing(2),
  },
  videoContainer: {
    height: '0',
    paddingBlockEnd: '56.25%',
    position: 'relative',
    width: '100%',
  },
  videoFrame: {
    height: '100%',
    insetInlineStart: '0',
    position: 'absolute',
    insetBlockStart: '0',
    width: '100%',
  },
}));

type SupportedBlockTypes = typeof defaultBlockTypes;

export interface PreviewBlockContentType {
  /** An array of devotional content blocks to render. */
  blocks: Array<Plan.DevotionalContentBlocks>;
  /** The current status of the plan. */
  planStatus: Plan.Status;
  /** The supported block types to render. */
  supportedBlocks: SupportedBlockTypes;
}

const PreviewBlockContent = ({ blocks, planStatus, supportedBlocks }: PreviewBlockContentType) => {
  const classes = useStyles();
  const { t } = useTranslation(['plan_days']);

  const [displayBlocks, setDisplayBlocks] = React.useState<Array<Plan.DevotionalContentBlocks>>(blocks);

  const loadingStatus = usePreloadBlockData({
    callback: setDisplayBlocks,
    initialBlocks: blocks,
    planStatus,
  });

  if (!Array.isArray(blocks) || !blocks.length) {
    return null;
  }

  if (loadingStatus === statusTypes.RESOLVED) {
    return (
      <Box maxWidth={800}>
        {displayBlocks.map(block => {
          switch (block.type) {
            case supportedBlocks.readonly.name:
            case supportedBlocks.text.name:
              return (
                <Container className={classes.previewContainer} key={block.block_id}>
                  <div
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={
                      createReactDangerousMarkup(block.content.html as string) as { __html: string }
                    }
                  />
                </Container>
              );
            case supportedBlocks.image.name:
              return block.content.display_url ? (
                <Container className={classes.previewContainer} key={block.block_id}>
                  <img alt={block.content.alt} className={classes.imageBoundaries} src={block.content.display_url} />
                </Container>
              ) : (
                <DownloadContainer key={block.block_id}>
                  <DropAreaDownloadFile
                    description={
                      planStatus === submissionStatuses.APPROVED
                        ? t('plan_days:plan_day_edit.devotional_content.image.preview_message')
                        : t('plan_days:plan_day_edit.devotional_content.image.download_file_content')
                    }
                    downloadButtonLabel={t('plan_days:plan_day_edit.devotional_content.image.download_file_label')}
                    fileType={block.type}
                    muiIcon={PhotoLibraryIcon}
                    sourceUrl={block.content.source_url || ''}
                  />
                </DownloadContainer>
              );
            case supportedBlocks.audio.name:
              return block.content.postprocessing_url ? (
                <Container className={classes.previewContainer} key={block.block_id}>
                  {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                  <audio
                    controls={true}
                    preload='metadata'
                    src={block.content.postprocessing_url}
                    style={{ width: '100%' }}
                  />
                </Container>
              ) : (
                <DownloadContainer key={block.block_id}>
                  {block.content.error ? (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <DropAreaFailed {...block.content.error} />
                  ) : (
                    <DropAreaDownloadFile
                      description={
                        planStatus === submissionStatuses.APPROVED
                          ? t('plan_days:plan_day_edit.devotional_content.audio.preview_message')
                          : t('plan_days:plan_day_edit.devotional_content.audio.download_file_content')
                      }
                      downloadButtonLabel={t('plan_days:plan_day_edit.devotional_content.audio.download_file_label')}
                      fileType={block.type}
                      muiIcon={AudiotrackIcon}
                      sourceUrl={block.content.source_url || ''}
                    />
                  )}
                </DownloadContainer>
              );
            case supportedBlocks.soundcloud_audio.name:
              return (
                <Container className={classes.previewContainer} key={block.block_id}>
                  {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
                  <iframe
                    frameBorder='no'
                    height='250'
                    scrolling='no'
                    src={block.content.soundcloud_embed_url}
                    width='100%'
                  ></iframe>
                </Container>
              );
            case supportedBlocks.youtube_video.name:
            case supportedBlocks.video.name:
              return block.content.youtube_video_id ? (
                <Container
                  className={`${classes.videoContainer}
                    ${classes.previewContainer}`}
                  key={block.block_id}
                >
                  {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
                  <iframe
                    allow='autoplay; encrypted-media'
                    allowFullScreen={true}
                    className={classes.videoFrame}
                    frameBorder='0'
                    src={`https://www.youtube.com/embed/${block.content.youtube_video_id}?rel=0`}
                  />
                </Container>
              ) : (
                <DownloadContainer key={block.block_id}>
                  {block.content.error ? (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <DropAreaFailed {...block.content.error} />
                  ) : (
                    <DropAreaDownloadFile
                      description={
                        planStatus === submissionStatuses.APPROVED
                          ? t('plan_days:plan_day_edit.devotional_content.video.preview_message')
                          : t('plan_days:plan_day_edit.devotional_content.video.download_file_content')
                      }
                      downloadButtonLabel={t('plan_days:plan_day_edit.devotional_content.video.download_file_label')}
                      fileType={block.type}
                      muiIcon={VideoLibraryIcon}
                      sourceUrl={block.content.source_url || ''}
                    />
                  )}
                </DownloadContainer>
              );
            case supportedBlocks.yv_video.name:
              return block.content.poster_url ? (
                <Container
                  className={`${classes.videoContainer}
                      ${classes.previewContainer}`}
                  key={block.block_id}
                >
                  <video className={classes.videoFrame} controls={true} poster={block.content.poster_url}>
                    {block.content.sources
                      ? block.content.sources.map((source, sourceIdx) => {
                          const codecs: Array<string> = [];
                          if (source.video_codec) {
                            codecs.push(source.video_codec);
                          }

                          if (source.audio_codec) {
                            codecs.push(source.audio_codec);
                          }

                          return (
                            <source
                              key={sourceIdx}
                              src={source.url}
                              type={`${source.html_mime_type}${codecs.length ? `; codecs="${codecs.join(', ')}"` : ''}`}
                            />
                          );
                        })
                      : null}
                  </video>
                </Container>
              ) : (
                <DownloadContainer key={block.block_id}>
                  {block.content.error ? (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <DropAreaFailed {...block.content.error} />
                  ) : (
                    <DropAreaDownloadFile
                      description={
                        planStatus === submissionStatuses.APPROVED
                          ? t('plan_days:plan_day_edit.devotional_content.video.preview_message')
                          : t('plan_days:plan_day_edit.devotional_content.video.download_file_content')
                      }
                      downloadButtonLabel={t('plan_days:plan_day_edit.devotional_content.video.download_file_label')}
                      fileType='video'
                      muiIcon={VideoLibraryIcon}
                      sourceUrl={block.content.source_url || ''}
                    />
                  )}
                </DownloadContainer>
              );
            default:
              return null;
          }
        })}
      </Box>
    );
  }
  if (loadingStatus === statusTypes.PENDING) {
    return (
      <Box alignItems='center' display='flex' flexDirection='column' justifyContent='center' width='100%'>
        <Box mb={2} width='50%'>
          <LinearProgress />
        </Box>
        <Typography variant='subtitle1'>
          {t('plan_days:plan_day_edit.devotional_content.loading_content_blocks')}
        </Typography>
      </Box>
    );
  }
  if (loadingStatus === statusTypes.REJECTED) {
    return (
      <Box alignItems='center' display='flex' flexDirection='column' justifyContent='center' width='100%'>
        <Typography variant='subtitle1'>
          {t('plan_days:plan_day_edit.devotional_content.error_loading_content_blocks')}
        </Typography>
      </Box>
    );
  }

  return null;
};

export default PreviewBlockContent;
