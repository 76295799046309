import Table from 'components/misc/Table';

import styles from './styles.module.scss';

interface Props {
  items: Array<Language>;
}

export default function LanguagesTable({ items }: Props) {
  return (
    <Table>
      <thead>
        <tr>
          <th className={styles.sortableHeading}>Language</th>
          <th className={styles.sortableHeading}>Code</th>
        </tr>
      </thead>
      <tbody className={styles.tableBody}>
        {items
          ? items.map(language => (
              <tr key={language.id}>
                <td>
                  <strong>{language.name}</strong>
                </td>
                <td>{language.code}</td>
              </tr>
            ))
          : null}
      </tbody>
    </Table>
  );
}

LanguagesTable.displayName = 'Languages/Table';
