/**
 * @module WithoutPlans
 */
import { useGetWithoutPlansReport } from 'api/report';
import LoaderOverlay from 'components/LoaderOverlay';
import List from 'components/Reports/GlobalReps/Pages/List';
import { API_STATUS } from 'helpers';
import { useState } from 'react';
import { NumberLike } from 'types/misc';

interface DateRange {
  endMonth?: SelectOption;
  endYear?: SelectOption;
  startMonth?: SelectOption;
  startYear?: SelectOption;
}
interface SelectOption {
  label: NumberLike;
  value: NumberLike;
}

/**
 * The WithoutPlans component.
 *
 * @alias module:WithoutPlans
 */
const WithoutPlansReport = () => {
  const [dateRange, setDateRange] = useState<DateRange>({
    startYear: undefined,
    startMonth: undefined,
    endYear: undefined,
    endMonth: undefined,
  });

  const { status, data } = useGetWithoutPlansReport({
    start_year: dateRange?.startYear?.value,
    start_month: dateRange?.startMonth?.value,
    end_year: dateRange?.endYear?.value,
    end_month: dateRange?.endMonth?.value,
  });

  const isLoading = status === API_STATUS.LOADING;

  if (isLoading && !data) {
    return <LoaderOverlay />;
  }

  let totalPlans = 0;
  const totalPartners = data?.length ?? 0;

  const records = new Map<number, Array<PartnerRecord>>();

  data?.forEach(row => {
    totalPlans += row.org_plans_count;
    const userId = row.id;
    if (records.has(userId)) {
      const record = records.get(userId);
      if (record) {
        record.push(row);
        records.set(userId, record);
      }
    } else {
      records.set(userId, [row]);
    }
  });

  const reportData = {
    totalPlans,
    totalPartners,
    rawJSON: data ?? [],
    records,
  };

  return (
    <List
      dateRange={dateRange}
      reportData={reportData}
      setDateRange={setDateRange}
      title='Global Reps: Partners Without Plans'
    />
  );
};

export default WithoutPlansReport;
