/**
 * @module PlansReducer
 */

import { getPaginationInfo, handleApiAction as handleApi } from 'helpers';
import { API_LOGOUT } from 'helpers/constants';
import update from 'immutability-helper';
import _ from 'lodash';
import { schema } from 'normalizr';
import { handleActions } from 'redux-actions';
import { LOAD_LANGUAGES } from 'state/actions/app/constants';
import { SWITCH_VIEW_ALL_ORGS } from 'state/actions/organizations/constants';
import { LIST_PLANS, SET_FILTERS, SET_SORT } from 'state/actions/plans/constants';

const { Entity } = schema;
const planEnt = new Entity('plans', {});

export const plansSchema = { plans: [planEnt] };

const SUBMIT_NEW_PLAN = 'plans/SUBMIT_NEW_PLAN';
const UPDATE_PLAN = 'plans/UPDATE_PLAN';
const UPDATE_DAY = 'plans/UPDATE_DAY';
const APPROVE_SUBMISSION = 'plans/APPROVE_SUBMISSION';

function updatePlanObj(s, planPayload) {
  const planId = planPayload.result.plan;
  return {
    plansById: {
      ...s.plansById,
      [planId]: planPayload.entities.plans[planId],
    },
    allPlanIds: _.uniq([...s.allPlanIds, planId]),
  };
}

function updatePlanDays(s, id, planDays) {
  return {
    planDaysById: {
      ...s.planDaysById,
      ...planDays,
    },
    planDayIdsByPlanId: {
      ...s.planDaysByPlanId,
      [id]: [],
    },
  };
}

const initialState = {
  plansById: {},
  planRevisionsById: {},
  allPlanIds: [],
  allPlanRevisionIds: [],
  isLoadingSubmission: false,
  activePlanId: null,
  plansPagination: null,
  sorter: {
    sort: null,
    order: 'desc',
  },
  filters: {
    name: null,
    language: null,
    organization_id: null,
  },
  languages: [],
};

export default handleActions(
  {
    [SWITCH_VIEW_ALL_ORGS]: s => ({
      ...s,
      filters: {
        ...s.filters,
        organization_id: null,
      },
    }),
    // Wipe-it clean!
    [API_LOGOUT]: handleApi(() => ({
      finish: s => ({
        ...s,
        ...initialState,
      }),
    })),

    [LOAD_LANGUAGES]: handleApi((state, { payload }) => ({
      success: s => {
        return update(s, {
          languages: { $set: payload.languages },
        });
      },
    })),

    [SET_FILTERS]: (state, action) => {
      return update(state, {
        filters: {
          name: { $set: action.payload.name },
          language: { $set: action.payload.language },
        },
      });
    },

    [SET_SORT]: (state, action) => {
      return update(state, {
        // When sorting changes, set the current page back to #1.
        plansPagination: {
          currentPage: { $set: 1 },
        },
        sorter: {
          order: { $set: action.payload.order },
          sort: { $set: action.payload.sort },
        },
      });
    },
    [APPROVE_SUBMISSION]: handleApi((state, { payload, meta }) => ({
      start: s => ({
        ...s,
        isLoadingSubmission: true,
      }),
      finish: s => ({
        ...s,
        isLoadingSubmission: false,
      }),
      failure: s => ({
        ...s,
        error: payload.message || 'Error submitting plan. Please try again.',
      }),
      success: s => {
        const { 1: planPayload, 2: daysPayload, 3: revisionsPayload } = payload;

        return {
          ...s,
          ...updatePlanObj(s, planPayload),
          ...updatePlanDays(s, meta.planId, daysPayload),
          allPlanRevisionIds: revisionsPayload.result.plan_revisions,
          planRevisionsById: {
            ...s.planRevisionsById,
            ...revisionsPayload.entities.plan_revisions,
          },
        };
      },
    })),

    [LIST_PLANS]: handleApi((state, { payload }) => ({
      start: s => ({
        ...s,
        isLoadingPlans: true,
        allPlanIds: [],
      }),
      finish: s => ({
        ...s,
        isLoadingPlans: false,
      }),
      failure: s => ({
        ...s,
        error: payload.message || 'Error loading plans. Please try again.',
      }),
      success: s => ({
        ...s,
        plansPagination: getPaginationInfo(payload.result.meta),
        plansById: {
          ...s.plansById,
          ...payload.entities.plans,
        },
        allPlanIds: payload.result.plans,
        error: null,
      }),
    })),

    [SUBMIT_NEW_PLAN]: handleApi((state, { payload }) => ({
      start: s => ({
        ...s,
        isSubmittingPlan: true,
      }),
      finish: s => ({
        ...s,
        isSubmittingPlan: false,
      }),
      failure: s => ({
        ...s,
        error: payload.message || 'Error submitting plan. Please try again.',
      }),
      success: s => ({
        ...s,
        plansById: {
          ...s.plansById,
          ...payload.entities.plans,
        },
        allPlanIds: _.uniq([...s.allPlanIds, payload.result]),
        error: null,
      }),
    })),

    [UPDATE_DAY]: handleApi((state, { payload, meta: { dayId } }) => ({
      success: s => {
        const { 0: maybeComment, 1: planDaysPayload } = payload;

        let nextState = s;

        if (maybeComment) {
          nextState = {
            ...s,
            commentsById: {
              ...s.commentsById,
              ...maybeComment.entities.comments,
            },
            dayCommentIdsByDayId: {
              ...s.dayCommentIdsByDayId,
              [dayId]: [...s.dayCommentIdsByDayId[dayId], maybeComment.result.comment],
            },
          };
        }

        return {
          ...nextState,
          planDaysById: {
            ...nextState.planDaysById,
            ...planDaysPayload.entities.plan_days,
          },
        };
      },
    })),

    [UPDATE_PLAN]: handleApi((state, { payload, meta: { planId } }) => ({
      success: s => {
        const { 0: maybeComment, 1: planPayload } = payload;

        let nextState = s;

        if (maybeComment) {
          nextState = {
            ...s,
            commentsById: {
              ...s.commentsById,
              ...maybeComment.entities.comments,
            },
            planCommentIdsByPlanId: {
              ...s.planCommentIdsByPlanId,
              [planId]: [...s.planCommentIdsByPlanId[planId], maybeComment.result.comment],
            },
          };
        }

        return {
          ...nextState,
          plansById: {
            ...nextState.plansById,
            [planId]: planPayload.entities.plans[planPayload.result.plan],
          },
        };
      },
    })),
  },
  initialState
);
