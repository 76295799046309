/**
 * @module InitialConfirmation
 */
import { Alert, Box, Button, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useAlert } from '@youversion/react';
import { UpdatePlanParams, useUpdatePlan } from 'api/plans';
import DatePicker, { excludeDays, excludeOctober2022 } from 'components/DatePicker';
import { Plan } from 'components/Plans/types';
import { API_STATUS, REVIEW_WEEKS_NUM } from 'helpers';
import moment from 'moment';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NullableString } from 'types/misc';

interface Props {
  daysWithoutNarratedAudio: NullableString;
  handleClose: () => void;
  handleNext: () => void;
  isPollyVoiceNotSelected: boolean;
  plan: Plan.Plan;
}

export const getNextAvailableDay = (date: moment.Moment): moment.Moment => {
  const isDayBlocked = excludeDays(date);
  if (isDayBlocked) {
    return getNextAvailableDay(date.add(1, 'days'));
  }
  return date;
};

const fourWeeksFromToday = moment().add('4', 'weeks').startOf('day');
const selectNovemberWhenSelectedDayIsOctober2022 = excludeOctober2022(fourWeeksFromToday)
  ? moment('2022-11-01')
  : fourWeeksFromToday;

const nextAvailableDay = getNextAvailableDay(selectNovemberWhenSelectedDayIsOctober2022);

const InitialConfirmation: FC<Props> = ({
  handleClose,
  handleNext,
  plan,
  daysWithoutNarratedAudio,
  isPollyVoiceNotSelected,
}) => {
  const { t } = useTranslation(['plans', 'common']);
  const { throwAlert } = useAlert();
  const { mutate, status: loadingStatus } = useUpdatePlan();

  const [selectedDate, setSelectedDate] = React.useState(nextAvailableDay);

  const isUpdatingPlan = loadingStatus === API_STATUS.LOADING;

  const isBeforeLaunchDate = React.useMemo(() => moment(selectedDate).isBefore(nextAvailableDay), [selectedDate]);

  const isDayBlocked = excludeDays(moment(selectedDate));

  function handleDateChange(date: moment.Moment | null) {
    if (date) {
      setSelectedDate(date);
    }
  }

  function updatePlan() {
    const submitValues: UpdatePlanParams = {
      attribution_text: plan.attribution_text,
      language_id: plan.language_id,
      categories: plan.categories.length ? plan.categories : [],
      description: plan.description,
      keywords: plan.keywords.length ? plan.keywords : [],
      large_image_id: plan.large_image?.id,
      name: plan.name,
      partner_url: plan.partner_url,
      small_image_id: plan.small_image?.id,
      system_status: plan?.system_status,
      title_slug: plan.title_slug,
    };
    mutate(
      {
        planId: plan.id,
        data: {
          launches_at: selectedDate.toDate(),
          ...submitValues,
        },
      },
      {
        onSuccess: () => {
          handleNext();
        },
        onError() {
          throwAlert({
            id: 'save_launch_error',
            key: 'save_launch_error',
            message: t('plans:submit_plan.initial_confirmation.save_launch_date_error_message'),
            timeout: 3000,
            type: 'error',
          });
        },
      }
    );
  }

  const dialogTitle = t('plans:submit_plan.initial_confirmation.title');
  const dialogSubTitle = t('plans:submit_plan.initial_confirmation.sub_text');
  const dialogContentDescription = t('plans:submit_plan.initial_confirmation.description.allow_n_weeks', {
    count: REVIEW_WEEKS_NUM,
  });

  const dialogContentChildren = (
    <>
      <Typography color='textSecondary' variant='caption'>
        {t('common:step_of_total', {
          step: 1,
          total: 2,
        })}
      </Typography>
      <Typography color='textPrimary' variant='h3'>
        {dialogSubTitle}
      </Typography>
      <Typography color='textSecondary' variant='subtitle1'>
        {dialogContentDescription}
      </Typography>
      <DatePicker
        handleDateChange={handleDateChange}
        isBeforeLaunchDate={isBeforeLaunchDate}
        selectedDate={selectedDate}
      />

      {daysWithoutNarratedAudio ? (
        <Box mt={3}>
          <Alert severity='error'>
            {t('plans:submit_plan.initial_confirmation.days_without_narrated_audio_warning', {
              daysWithoutNarratedAudio,
            })}
          </Alert>
        </Box>
      ) : null}
      {isPollyVoiceNotSelected ? (
        <Box mt={3}>
          <Alert severity='error'>{t('plans:submit_plan.initial_confirmation.no_polly_voice_selected_warning')}</Alert>
        </Box>
      ) : null}
    </>
  );
  const dialogActions = (
    <>
      <Button color='inherit' disabled={isUpdatingPlan} onClick={handleClose}>
        {t('common:cancel')}
      </Button>
      <Button
        color='inherit'
        disabled={
          isBeforeLaunchDate ||
          isDayBlocked ||
          isUpdatingPlan ||
          Boolean(daysWithoutNarratedAudio) ||
          isPollyVoiceNotSelected
        }
        onClick={updatePlan}
      >
        {isUpdatingPlan ? t('common:loading') : t('common:next')}
      </Button>
    </>
  );

  return (
    <>
      <DialogTitle id='dialog-wrapper-title'>{dialogTitle}</DialogTitle>
      <DialogContent id='dialog-wrapper-content'>{dialogContentChildren}</DialogContent>
      <DialogActions>{dialogActions}</DialogActions>
    </>
  );
};

export default InitialConfirmation;
