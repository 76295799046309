/* eslint-disable @typescript-eslint/no-shadow */
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  DialogActions,
  DialogContent,
  Grid,
  LinearProgress,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { LazyImage } from '@youversion/react';
import { RefactoredLanguages } from 'api/languages';
import CheckIcon from 'assets/check-icon.png';
import MuiLoadingButton from 'components/Buttons/mui-loading-button';
import { Plan } from 'components/Plans/types';
import { Plan as CardDetails } from 'components/TranslationPicker/types';
import { imageProxyUrl } from 'helpers/plan-images-helpers';
import { ChangeEvent, Dispatch, FC, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardImage: {
      borderRadius: '16px',
    },
    cardContainer: {
      margin: '0px 4px',
      border: '0px',
    },

    checkIcon: {
      backgroundColor: 'grey',
      borderRadius: '16px',
    },
    dialog: {
      width: '400px',
    },
    icon: {
      alignSelf: 'center',
      marginHorizontal: '50px',
      marginVertical: '50px',
    },
    iconImage: {
      marginInlineEnd: theme.spacing(4),
    },
    list: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      overflowY: 'auto',
      maxHeight: '28vh',
      marginBlockStart: '1vh',
    },
    rowPadding: { padding: '25px 0px' },
    selectedCard: {
      margin: '0px 4px',
      border: '0px',
    },
    textField: {
      borderRadius: '4px',
      marginVertical: '10px',
    },
  })
);
interface Props {
  handleClose: () => void;
  handleStep: () => void;
  isLoadingPlans: boolean;
  languages: RefactoredLanguages | undefined;
  plans: Array<Plan.Plan> | undefined;
  setSelectedPlan: Dispatch<SetStateAction<Plan.Plan | undefined>>;
}

const InitialDialog: FC<Props> = ({ handleClose, handleStep, languages, plans, setSelectedPlan, isLoadingPlans }) => {
  const classes = useStyles();
  const [planSearchText, setPlanSearchText] = useState('');
  const [selectedLanguageId, setSelectedLanguageId] = useState('');
  const { t } = useTranslation(['plans', 'common']);
  const [selectedCardDetails, setSelectedCardDetails] = useState<CardDetails>({
    id: '',
    name: '',
  });

  const isSearched = Boolean(planSearchText.trim()) || Boolean(selectedLanguageId);

  const isCardSelected = Boolean(selectedCardDetails.id);

  const linkableLanguageIds = plans ? Array.from(new Set(plans.map(plan => plan.language_id))) : [];

  const filteredPlans = plans
    ? plans.filter(plan => {
        const lowerCasePlanName = plan.name.toLowerCase().trim();
        const lowerCasePlanSearchText = planSearchText.toLowerCase().trim();

        if (selectedLanguageId && lowerCasePlanSearchText) {
          return String(plan.language_id) === selectedLanguageId && lowerCasePlanName.includes(lowerCasePlanSearchText);
        }

        if (selectedLanguageId) {
          return String(plan.language_id) === selectedLanguageId;
        }

        if (lowerCasePlanSearchText) {
          return lowerCasePlanName.includes(lowerCasePlanSearchText);
        }

        return true;
      })
    : undefined;

  const selectedPlan = plans ? plans.filter(plan => plan.id === selectedCardDetails.id)[0] : undefined;

  const isNextButtonDisabled = !selectedPlan;

  function handleSelectedCardDetails(selectedCardDetails: CardDetails, plan: CardDetails) {
    if (selectedCardDetails.id === plan.id) {
      return classes.selectedCard;
    }
    return classes.cardContainer;
  }

  function searchPlanByText(event: ChangeEvent<HTMLInputElement>) {
    const text = event.target.value.toLowerCase();
    setPlanSearchText(text);
  }

  function handleLanguageChange(event: ChangeEvent<HTMLInputElement>) {
    setSelectedLanguageId(event.target.value);
  }

  function handleNextButtonClick(plan: Plan.Plan | undefined): void {
    // Only serves to type-check, Next button is disabled if plan is undefined.
    if (!plan) {
      return;
    }

    setSelectedPlan(plan);
    handleStep();
  }

  function handleCardClick(card: CardDetails) {
    if (isCardSelected && selectedCardDetails.id === card.id) {
      setSelectedCardDetails({
        id: '',
        name: '',
      });
    } else {
      setSelectedCardDetails(card);
    }
  }

  const isPlanCardFound = isSearched && filteredPlans && filteredPlans.length > 0;

  const isNoPlanFound = isSearched && filteredPlans && filteredPlans.length === 0;

  return (
    <>
      <DialogContent id='dialog-wrapper-content'>
        <Typography color='textSecondary' variant='caption'>
          {t('common:step_of_total', {
            step: 1,
            total: 2,
          })}
        </Typography>
        <Typography color='textPrimary' variant='body1'>
          {t('plans:label.find_an_existing_plan')}
        </Typography>
        {languages && plans ? (
          <>
            <TextField
              SelectProps={{ native: true }}
              className={classes.textField}
              fullWidth={true}
              id='language'
              label={t('plans:col_header.language')}
              margin='normal'
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleLanguageChange(e)}
              placeholder={t('plans:col_header.language')}
              required={true}
              select={true}
              value={selectedLanguageId}
              variant='outlined'
            >
              {linkableLanguageIds.length ? (
                <>
                  <option>{t('plans:col_header.please_select')}</option>
                  {linkableLanguageIds.map(linkableLanguageId => (
                    <option key={linkableLanguageId} value={linkableLanguageId}>
                      {languages[linkableLanguageId].name}
                    </option>
                  ))}
                </>
              ) : (
                <option disabled={true}>{t('plans:col_header.no_language_found')}</option>
              )}
            </TextField>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              className={classes.textField}
              fullWidth={true}
              id='searchPlanTitle'
              label={t('plans:label.search_plan_title')}
              margin='normal'
              onChange={(e: ChangeEvent<HTMLInputElement>) => searchPlanByText(e)}
              placeholder={t('plans:label.search_plan_title')}
              required={true}
              type='text'
              value={planSearchText}
              variant='outlined'
            />
          </>
        ) : null}
        {isLoadingPlans ? <LinearProgress /> : null}
        {isPlanCardFound ? (
          <Grid className={classes.list} container={true}>
            {filteredPlans.map(plan => {
              const planName = plan.name;
              const planCardData = {
                id: plan.id,
                name: planName,
              };
              return (
                <Grid item={true} key={planCardData.id} xs={6}>
                  <Card
                    className={handleSelectedCardDetails(selectedCardDetails, planCardData)}
                    onClick={() => handleCardClick(planCardData)}
                    variant='outlined'
                  >
                    <CardActionArea>
                      <LazyImage
                        aspectRatio={1 / 1}
                        className={classes.cardImage}
                        height='160px'
                        src={imageProxyUrl(plan.small_image, '100x') ?? ''}
                        width='100%'
                      />
                      {selectedCardDetails.id === plan.id && (
                        <Box height='160px' position='absolute' top={0} width='100%'>
                          <Box
                            alignItems='center'
                            className={classes.checkIcon}
                            data-testid='check-icon'
                            display='flex'
                            height='inherit'
                            justifyContent='center'
                          >
                            <LazyImage alt='check-icon' height='24px' src={CheckIcon} width='24px' />
                          </Box>
                        </Box>
                      )}
                    </CardActionArea>
                    <CardActions>
                      <Typography color='textPrimary' variant='body1'>
                        {planCardData.name}
                      </Typography>
                    </CardActions>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        ) : null}
        {isNoPlanFound ? (
          <Typography color='textPrimary' variant='body1'>
            {t('plans:label.no_plans_found')}
          </Typography>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button autoFocus={true} color='primary' onClick={handleClose}>
          {t('common:cancel')}
        </Button>
        <Box mr={3}>
          <MuiLoadingButton
            color='primary'
            disabled={isNextButtonDisabled || isLoadingPlans}
            isLoading={isLoadingPlans}
            onClick={() => handleNextButtonClick(selectedPlan)}
            progressBarTestId='progressbar'
            size='medium'
            variant='contained'
          >
            {t('common:next')}
          </MuiLoadingButton>
        </Box>
      </DialogActions>
    </>
  );
};

export default InitialDialog;
