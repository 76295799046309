import { useAlert } from '@youversion/react';
import { statusTypes } from '@youversion/utils';
import { getAllPermissions, useGetOrganizationPermissions } from 'api/permissions';
import TransferList from 'components/TransferList';
import { API_STATUS, REALMS } from 'helpers/constants';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  orgId: number;
}

export const OrganizationTransferList = ({ orgId }: Props) => {
  const { throwAlert } = useAlert();
  const { t } = useTranslation(['organizations']);

  const [allPermissions, setAllPermissions] = React.useState<Array<PermissionItem>>([]);
  const [orgPermissionsIds, setOrgPermissionsIds] = React.useState<Record<string, number>>({});
  const { data: organizationPermissions, status: orgPermissionsLoadingStatus } = useGetOrganizationPermissions(orgId);

  const activePermissions = React.useMemo(
    () => allPermissions.filter(permission => orgPermissionsIds[permission.id]),
    [allPermissions, orgPermissionsIds]
  );

  const inactivePermissions = React.useMemo(
    () => allPermissions.filter(permission => !orgPermissionsIds[permission.id]),
    [allPermissions, orgPermissionsIds]
  );

  const [loadingPermissionsStatus, setLoadingPermissionsStatus] = React.useState(statusTypes.IDLE);

  React.useEffect(() => {
    async function fetchOrganizationPermissions() {
      setLoadingPermissionsStatus(statusTypes.PENDING);
      try {
        const permissionsResponse = await getAllPermissions([REALMS.ORGANIZATION]);
        setAllPermissions(permissionsResponse);
        setLoadingPermissionsStatus(statusTypes.RESOLVED);
      } catch (error) {
        setLoadingPermissionsStatus(statusTypes.REJECTED);
        if (error instanceof Error) {
          throwAlert({
            id: 'fetch_organization_permission_status_details_error',
            key: 'permissionFetchError',
            message: `Failed to fetch organization permission status details: ${error}`,
            timeout: 5000,
            type: 'error',
          });
        }
      }
    }
    fetchOrganizationPermissions();
  }, [orgId, throwAlert, t]);

  React.useEffect(() => {
    if (organizationPermissions) {
      setOrgPermissionsIds(
        organizationPermissions.reduce(
          (acc, cur) => ({
            ...acc,
            [cur.permission.id]: cur.id,
          }),
          {}
        )
      );
    }
  }, [organizationPermissions]);

  if (loadingPermissionsStatus !== statusTypes.RESOLVED || orgPermissionsLoadingStatus !== API_STATUS.SUCCESS) {
    return null;
  }

  return (
    <TransferList
      activePermissions={activePermissions}
      inactivePermissions={inactivePermissions}
      onPermissionActivate={(newId: number, permissionId: string) => {
        setOrgPermissionsIds(prevIds => ({
          ...prevIds,
          [permissionId]: newId,
        }));
      }}
      organizationId={orgId}
      relationIds={orgPermissionsIds}
    />
  );
};

OrganizationTransferList.propTypes = {
  orgId: PropTypes.number.isRequired,
};
