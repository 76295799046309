import { Box } from '@mui/material';

import { CommentCreatedItem } from './Items/CommentCreated';
import { PlanSubmissionItem } from './Items/PlanSubmission';

interface Props {
  items: Array<ActivityItem>;
}

interface ActivityComponentProps {
  item: ActivityItem;
}

type ActivityComponentsType = Record<string, ({ item }: ActivityComponentProps) => JSX.Element>;

const ActivityComponents: ActivityComponentsType = {
  'Activity::Plan::Submission': PlanSubmissionItem,
  'Activity::Comment::Created': CommentCreatedItem,
};

export const Items = ({ items }: Props) => {
  return (
    <Box data-testid='activities-container'>
      {items.map(item => {
        const ActivityComponent = ActivityComponents[item.type];
        return <ActivityComponent item={item} key={item.id} />;
      })}
    </Box>
  );
};
