import './helpers/i18n';

import * as Sentry from '@sentry/browser';
import App from 'components/App/App';
import reportWebVitals from 'helpers/reportWebVitals';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import createStore from 'state/createStore';
import { loadOrgId, loadToken } from 'state/reducers/persistReducer';

const store = createStore();

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    release: `partner-portal-frontend@${process.env.REACT_APP_PACKAGE_VERSION}`,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.NODE_ENV,
  });
}

// Synchronously load from localStorage before app mounts
store.dispatch(loadToken());
store.dispatch(loadOrgId());

ReactDOM.render(
  <Suspense fallback={null}>
    <App store={store} />
  </Suspense>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint.
// @see {@link https://bit.ly/CRA-vitals}
reportWebVitals();
