/**
 * @module PaperWrapperComponent
 */
import { Box, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { FC } from 'react';

import TranslationMenuActions from '../TranslationMenuActions';
import { PaperWrapperComponentProps } from '../types';

const useStyles = makeStyles(() => ({
  boxDesign: {
    marginBlockStart: '8px',
    borderRadius: '8px',
    overflow: 'hidden',
  },
}));

const PaperWrapperComponent: FC<PaperWrapperComponentProps> = ({
  children,
  handleToggleSort,
  isSortedAlphabetically,
  disableNewLanguageButton,
  isOrgPlanListGreaterThanOne,
}) => {
  const classes = useStyles();
  return (
    <Paper className={classes.boxDesign} elevation={3}>
      <TranslationMenuActions
        disableNewLanguageButton={disableNewLanguageButton}
        handleToggleSort={handleToggleSort}
        isOrgPlanListGreaterThanOne={isOrgPlanListGreaterThanOne}
        isSortedAlphabetically={isSortedAlphabetically}
      />
      <Box>{children}</Box>
    </Paper>
  );
};

export default PaperWrapperComponent;
