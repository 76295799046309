import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useAlert } from '@youversion/react';
import { FC, useEffect, useState } from 'react';

interface AudioButtonProps {
  label: string;
  src?: string;
}

const useStyles = makeStyles({
  buttonCssReset: {
    cursor: 'pointer',
    height: '50px',
  },
});

export const AudioButton: FC<AudioButtonProps> = ({ src, label }) => {
  const classes = useStyles();
  const { throwAlert } = useAlert();
  const [audio, setAudio] = useState<HTMLAudioElement | undefined>();
  const [isPlaying, setIsPlaying] = useState(false);

  const handleClickAudioButton = () => {
    if (isPlaying && audio) {
      audio.pause();
      audio.currentTime = 0;
      setIsPlaying(prev => !prev);
    } else {
      audio?.play();
      setIsPlaying(prev => !prev);
    }
  };

  useEffect(() => {
    if (audio) {
      audio.pause();
      setIsPlaying(false);
      setAudio(undefined);
    }
    const newAudio = new Audio(src);
    newAudio.onerror = () => {
      throwAlert({
        id: 'audio_error',
        key: 'audio_error',
        message: 'Unable to get audio!',
        timeout: 3000,
        type: 'error',
      });
    };
    newAudio.onloadeddata = () => {
      setAudio(newAudio);
    };
    newAudio.addEventListener('ended', () => {
      setIsPlaying(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src, throwAlert]);

  return (
    <Button
      className={classes.buttonCssReset}
      disabled={!audio}
      onClick={handleClickAudioButton}
      startIcon={isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
      type='button'
      variant='contained'
    >
      {label}
    </Button>
  );
};
