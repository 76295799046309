/* eslint-disable jsdoc/require-param */
/**
 * @module DeletePlan
 */
import { Box, Button, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { yvRed } from '@youversion/react/styles/colors-v3';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { NumberLike } from 'types/misc';

import { DeleteDialog } from './DeleteDialog';

const useStyles = makeStyles(() => ({
  deleteButton: {
    '&:hover': {
      backgroundColor: yvRed,
    },
    backgroundColor: yvRed,
    color: '#fff',
  },
}));

interface Props {
  planId: NumberLike;
}

/**
 * DeletePlan.
 *
 * @returns {React.ReactElement} - Delete a plan component.
 */
export function DeletePlan({ planId }: Props) {
  const classes = useStyles();
  const { t } = useTranslation('plans');
  const [openDialog, setOpenDialog] = React.useState(false);

  function handleClickDelete() {
    setOpenDialog(true);
  }

  return (
    <>
      <Box mt={6}>
        <Typography variant='h2'>{t('edit_plan_info.delete_plan')}</Typography>
        <Typography color='textSecondary' variant='caption'>
          {t('edit_plan_info.delete_info')}
        </Typography>
      </Box>
      <Box mt={1.5}>
        <Button className={classes.deleteButton} onClick={handleClickDelete} size='large' variant='contained'>
          {t('edit_plan_info.delete')}
        </Button>
      </Box>
      <DeleteDialog open={openDialog} planId={planId} setOpen={setOpenDialog} />
    </>
  );
}
