/**
 * @module sortDays
 */
import { Plan } from 'components/Plans/types';
import _ from 'lodash';

/**
 * Sort an array of plan days by position.
 *
 * @returns {Array} - Sorted plan days.
 *
 * @example
 * import { sortDays } from 'helpers'
 *
 * function MyComponent() {
 *
 * const [planDays, setPlanDays] = React.useState(() => {
 *   let days = []
 *   if (Array.isArray(plan?.plan_days)) {
 *     days = sortDays(plan.plan_days)
 *   }
 *   return days
 * })
 *
 */

/* eslint-disable import/prefer-default-export */
export function sortDays(days: Array<Plan.Day>) {
  return _.sortBy(days, 'position');
}
