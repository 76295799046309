/* eslint-disable prettier/prettier */
/* eslint-disable jsdoc/require-param */
/* eslint-disable react/no-multi-comp */
/**
 * @module PlanDataGrid
 */
import ErrorIcon from '@mui/icons-material/Error'
import LanguageIcon from '@mui/icons-material/Language'
import { Box, Tooltip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  DataGrid,
  GridColumns,
  GridRenderCellParams,
  GridSortModel,
} from '@mui/x-data-grid'
import { LazyImage } from '@youversion/react'
import { gray } from '@youversion/react/styles/colors-v3'
import { useGetLanguages } from 'api/languages'
import { GetPlansResponse } from 'api/plans'
import { useAuth } from 'auth'
import { API_STATUS, submissionStatuses } from 'helpers'
import { imageProxyUrl } from 'helpers/plan-images-helpers'
import moment from 'moment'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useSearchParams } from 'react-router-dom'
import { getLocalizedLanguage } from 'utils/get-localized-language/getLocalizedLanguage'
import getLocalizedPastDateString from 'utils/get-localized-past-date-string/getLocalizedPastDateString'

import StatusBadge from '../StatusBadge'
import { LoadingOverlay } from './components/LoadingOverlay'
import { NoRowsOverlay } from './components/NoRowsOverlay'
import { PaginationComponent } from './components/Pagination'

const PLAN_IMAGE_HEIGHT = 75
const PLAN_PAGE_SIZE = 50

const useStyles = makeStyles((theme) => ({
  headerLaunchDate: {
    lineHeight: 'normal',
    whiteSpace: 'pre-wrap',
  },
  planDataGrid: {
    // This fixes a bug in Mui's DataGrid component, where the last
    // header has an unnecessary column separator at the end.
    '& .MuiDataGrid-columnHeader': {
      ...(theme.direction === 'ltr'
        ? {
            '&:last-of-type .MuiDataGrid-columnSeparator': {
              display: 'none',
            },
          }
        : {
            '&:first-of-type .MuiDataGrid-columnSeparator': {
              display: 'none',
            },
          }),
    },
  },
  planImage: {
    borderRadius: theme.spacing(1),
    lineHeight: 'unset',
  },
  planImageLink: {
    height: PLAN_IMAGE_HEIGHT,
    lineHeight: 'normal',
  },
  planName: {
    fontWeight: 'bold',
    textDecoration: 'none',
    whiteSpace: 'pre-wrap',
    width: '100%',
  },
  warningIcon: {
    color: gray[25],
  },
}))

const DEFAULT_SORT_MODEL = {
  field: 'created_at', // YV data uses `sort`.
  sort: 'desc', // YV data uses `order`.
} as const
interface PlanDataGridProps {
  /** The plans data. */
  data?: GetPlansResponse,
  /** Shows if the Plan data is loading. */
  loadingStatus: string,
  /** Active organization id. Could be ALL_ORGS if no org is active. */
  organizationId: string
}

/**
 * The PlanDataGrid component.
 *
 * @returns {ReactElement} - The PlanDataGrid component.
 */
export function PlanDataGrid({
  loadingStatus,
  data,
  organizationId,
}: PlanDataGridProps) {
  const [query, setQuery] = useSearchParams()
  const classes = useStyles()
  const { user } = useAuth()
  const { data: languages, status } = useGetLanguages()
  const { t, i18n } = useTranslation('plans')

  // This runs only when the sort model changes. Make sure this lines up with YV sortData.
  function handleSortModelChange(muiSortModel: GridSortModel) {
    if (muiSortModel.length) {
      const newSort = muiSortModel[0].sort === 'asc' ? 'desc' : 'asc'

      // MUI fires {handleSortModelChange} fn multiple times while table initially renders. That causes a refetch when the app starts and affects loading time.
      // Checking to make sure it doesn't set anything to state when it first renders.
      const isDefaultSortModeOnFirstLoad =
        !query.get('sort') &&
        !query.get('order') &&
        muiSortModel[0].field === DEFAULT_SORT_MODEL.field &&
        newSort === DEFAULT_SORT_MODEL.sort

      const isSortModeSetOnFirstLoad =
        query.get('sort') &&
        query.get('order') &&
        muiSortModel[0].field === query.get('sort') &&
        newSort === query.get('order')

      if (isDefaultSortModeOnFirstLoad || isSortModeSetOnFirstLoad) {
        return
      }

      query.set('sort', muiSortModel[0].field)
      query.set('order', newSort)
      setQuery(query)
    } else {
      query.set('sort', DEFAULT_SORT_MODEL.field)
      query.set('order', DEFAULT_SORT_MODEL.sort)
      setQuery(query)
    }
  }

  const dataIsPending = Boolean(
    loadingStatus !== API_STATUS.SUCCESS || status !== API_STATUS.SUCCESS,
  )

  const plansRows =
    !dataIsPending && data
      ? data?.data?.map((plan) => {
          const localizedUpdatedDate = getLocalizedPastDateString(
            plan.updated_at,
          )
          return {
            id: plan.id,
            lang: {
                  ...languages?.[plan.language_id],
                  name: languages?.[plan.language_id]
                      ? getLocalizedLanguage(
                          languages?.[plan.language_id].code,
                          i18n.language,
                      )
                      : languages?.[plan.language_id].name,
              },
              launches_at: plan.launches_at
                  ? moment(plan.launches_at)
                      .locale(i18n.language)
                      .format('l')
                  : t('not_set'),
            name: plan.name,
            organization_name: plan.primary_organization_name,
            plan_image: imageProxyUrl(plan.small_image, '100x'),
            status: plan.overall_status,
            system_status: plan.system_status,
            updated_at: t(
              `last_updated_value.${localizedUpdatedDate.timeUnit}`,
              { count: localizedUpdatedDate.count },
            ),
          }
        })
      : []

  function getPaginationString() {
    const currentPage = data?.meta?.current_page || 1
    const totalCount = data?.meta?.total_count || 0
    let paginationStartCount = 1
    let paginationEndCount = 1
    if (currentPage) {
      paginationStartCount =
        currentPage > 1 ? (currentPage - 1) * PLAN_PAGE_SIZE : 1
      paginationEndCount = currentPage * PLAN_PAGE_SIZE
    }
    if (currentPage === data?.meta?.total_pages) {
      paginationEndCount = data?.meta?.total_count
    }
    return t('page_count.displayed_of_total', {
      start: paginationStartCount,
      end: paginationEndCount,
      count: totalCount,
    })
  }

  // The amount subtracting from the viewport height is the size of the page's
  // header text, the search input field, and the clear filters button.
  // This allows the DataGrid to fill the height of the page.
  const dataGridHeightCss = 'calc(100vh - 215px)'
  const minDataGridHeighCss = '240px'

  const columns = useMemo(() => {
    const columnsArray: GridColumns = [
      {
        field: 'created_at',
        filterable: false,
        headerName: t('col_header.created_date'),
        hide: true,
      },
      {
        field: 'id',
        filterable: false,
        headerName: t('col_header.id'),
        hide: true,
        sortable: false,
      },
      {
        field: 'plan_image',
        filterable: false,
        headerName: t('col_header.image'),
        renderCell: ({ id, value: imageUrl }) => (
          <Link className={classes.planImageLink} to={`/plans/${id}`}>
            <LazyImage
              aspectRatio={1 / 1}
              className={classes.planImage}
              height={PLAN_IMAGE_HEIGHT}
              // Added an image to show on development since there isn't full staging data.
              // This will NOT appear on production. If it's not development, an empty string
              // is falsy and will result in defaulting to the imageUrl variable.
              src={imageUrl}
              width={PLAN_IMAGE_HEIGHT}
            />
          </Link>
        ),
        sortable: false,
        // The amount added to PLAN_IMAGE_HEIGHT is for padding.
        width: PLAN_IMAGE_HEIGHT + 20,
      },
      {
        field: 'name',
        filterable: false,
        flex: 1,
        headerName: t('col_header.name'),
        minWidth: 200,
        renderCell: ({ id, value: name }) => (
          <Typography
            className={classes.planName}
            color="textPrimary"
            component={Link}
            to={`/plans/${id}`}
            variant="body1"
          >
            {String(name)}
          </Typography>
        ),
      },
      {
        field: 'status',
        filterable: false,
        headerName: t('col_header.status'),
        renderCell: ({ value: planStatus, row }) => (
          <StatusBadge
            status={t(
              `status.${
                row?.system_status === false
                  ? submissionStatuses.ARCHIVED
                  : planStatus
              }`,
            )}
          />
        ),
        width: 110,
      },
      {
        field: 'launches_at',
        filterable: false,
        renderHeader: () => (
          <div className={classes.headerLaunchDate}>
            {t('col_header.launch_date')}
          </div>
        ),
        width: 110,
      },
      {
        field: 'lang',
        filterable: false,
        renderHeader: () => (
          <Tooltip title={t('col_header.language')}>
            <Box alignItems="center" display="flex" justifyContent="center">
              <LanguageIcon height={16} width={16} />
            </Box>
          </Tooltip>
        ),
        renderCell: (params: GridRenderCellParams<Language>) => {
          if (params?.value?.name) {
            return (
              <Typography color="textPrimary">{params.value.name}</Typography>
            )
          }
          return (
            <Tooltip title={`${t('unknown_language')}: ${params.value}`}>
              <Box alignItems="center" display="flex" justifyContent="center">
                <ErrorIcon
                  className={classes.warningIcon}
                  height={16}
                  width={16}
                />
              </Box>
            </Tooltip>
          )
        },
        width: 80,
      },
      {
        field: 'updated_at',
        filterable: false,
        headerName: t('col_header.last_updated'),
        width: 150,
      },
    ]

    if (
      user &&
      user.can('read_all:organization') &&
      organizationId === 'ALL_ORGS'
    ) {
      columnsArray.push({
        field: 'organization_name',
        filterable: false,
        headerName: t('col_header.organization'),
        width: 150,
      })
    }

    return columnsArray
  }, [t, user, organizationId, classes])

  return (
    <Box
      className={classes.planDataGrid}
      data-testid="plans-data-grid"
      height={dataGridHeightCss}
      minHeight={minDataGridHeighCss}
      pb={2}
      width="100%"
    >
      <DataGrid
        columns={columns}
        components={{
          LoadingOverlay,
          NoRowsOverlay,
          Pagination: () => (
            <PaginationComponent
              dataIsPending={dataIsPending}
              getPaginationString={getPaginationString}
              meta={data?.meta}
            />
          ),
        }}
        disableColumnFilter={true}
        disableColumnMenu={true}
        disableSelectionOnClick={true}
        loading={dataIsPending}
        onSortModelChange={handleSortModelChange}
        page={data ? data.meta?.current_page - 1 : 0} // Zero-indexed
        pageSize={PLAN_PAGE_SIZE}
        pagination={true}
        // This is needed since the API is the one controlling the data.
        paginationMode="server"
        rowCount={data?.meta?.total_count || 0}
        // The amount added to PLAN_IMAGE_HEIGHT is for padding.
        rowHeight={PLAN_IMAGE_HEIGHT + 32}
        rows={plansRows}
        rowsPerPageOptions={[PLAN_PAGE_SIZE]}
        sortModel={[
          {
            sort: query.get('order') === 'asc' ? 'desc' : 'asc',
            field: query.get('sort') ?? DEFAULT_SORT_MODEL.field,
          },
        ]}
        // This is needed since the API is handling sorting.
        sortingMode="server"
      />
    </Box>
  )
}
