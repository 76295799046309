/**
 * @module Sidebar
 */
import { OpenInNewRounded } from '@mui/icons-material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ApartmentIcon from '@mui/icons-material/Apartment';
import CheckIcon from '@mui/icons-material/Check';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LanguageIcon from '@mui/icons-material/Language';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import PeopleIcon from '@mui/icons-material/People';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Fade,
  IconButton,
  List,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { surface } from '@youversion/react/styles/colors-v3';
import partnerPortalLogo from 'assets/yv-pp-logo.svg';
import { useAuth } from 'auth';
import classNames from 'classnames';
import { MuiNavLink } from 'components/Navigation/mui-nav-link';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, useMatch, useNavigate } from 'react-router-dom';
import { State } from 'state/reducers';
import { logout } from 'state/reducers/authReducer';
import {
  activeOrgSelector,
  allOrgsSelector,
  isAdminSelector,
  orgsLoadingSelector,
  ownAvatarUrlSelector,
  userSelector,
  viewingAllOrgsSelector,
} from 'state/selectors';

import { SidebarItem } from './sidebar-item';

// Flag to enable dense sidebar menu items.
const enableDenseSidebar = true;
const drawerWidth = 240;
const useStyles = makeStyles(theme => ({
  adminHeader: {
    alignItems: 'center',
    display: 'flex',
  },
  anchorTag: {
    color: 'inherit',
    cursor: 'pointer',
    textDecoration: 'none',
  },
  drawer: {
    flexShrink: 0,
    width: ({ openSidebar }: { openSidebar: boolean }) => (openSidebar ? drawerWidth : 0),
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    height: '100vh',
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
  },
  drawerRoot: {
    backgroundColor: surface[theme.palette.mode].default,
    justifyContent: 'space-between',
  },
  helpEndButton: {
    flex: 'none',
    marginInline: 'auto',
  },
  homeButton: {
    alignItems: 'flex-start',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: 0,
    textTransform: 'none',
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shortest,
    }),
    width: '100%',
    '&:active': {
      transform: 'scale(0.95)',
    },
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
  menuItem: {
    '&:hover': {
      color: 'inherit',
    },
  },
  createPlanButton: {
    marginInlineStart: '6rem',
  },
  sideMenuList: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  textBold: {
    fontWeight: 'bold',
  },
}));

/**
 * Sidebar Component.
 *
 * @param {object} props - The component's prop object.
 * @param {object} props.activeOrganization - The active organization.
 * @param {string} [props.avatarUrl] - The user's avatar image url.
 * @param {Function} props.handleCloseMobileSidebar - Closes the sidebar when the viewport is in mobile view.
 * @param {boolean} props.isAdmin - Indicates that the given user is an admin, which enables the Admin Pages on the Sidebar component.
 * @param {boolean} props.isMobileView - Indicates if the viewport is in mobile view.
 * @param {boolean} props.isViewingAllOrgs - Indicates if the active organization is "All Organizations".
 * @param {boolean} props.open - Indicates if the Sidebar is open or closed.
 * @param {object[]} [props.organizations] - List of available organizations for the user to switch between.
 * @param {Function} props.signOut - Signs the user out.
 * @param {string} props.userFullName - First and last name of the active user.
 *
 * @returns {React.ReactElement} Sidebar component.
 */

interface Props {
  activeOrganization?: ActiveOrganization;
  avatarUrl?: string;
  handleCloseMobileSidebar: Function;
  isAdmin: boolean;
  isMobileView: boolean;
  isViewingAllOrgs: boolean;
  open: boolean;
  organizations?: Array<ActiveOrganization>;
  signOut: Function;
  userFullName: string;
}
export const Sidebar: FC<Props> = ({
  activeOrganization = null,
  avatarUrl = '',
  handleCloseMobileSidebar,
  isAdmin,
  isMobileView,
  isViewingAllOrgs,
  open,
  organizations = [],
  signOut,
  userFullName,
}) => {
  const navigate = useNavigate();
  const activeOrganizationMatch = useMatch(`organizations/${activeOrganization?.id}`);
  const { user } = useAuth();
  const { t, i18n } = useTranslation(['common', 'menu']);
  const classes = useStyles({ openSidebar: open });

  const dir = i18n.dir() === 'ltr' ? 'left' : 'right';

  const [orgMenuAnchorEl, setOrgMenuAnchorEl] = React.useState<HTMLElement | null>(null);
  const orgMenuItems = [
    {
      startIcon: <DataUsageIcon />,
      text: t('menu:analytics'),
      to: '/analytics',
      component: MuiNavLink,
    },
    {
      // Do not show add Plan button when viewing all organizations. It fails to load the page,
      // because you cannot create a Plan as all organizations. You must have an organization
      // selected before this appears.
      endButton: !isViewingAllOrgs ? (
        <div className={classes.createPlanButton} data-testid='add-plan'>
          <IconButton
            edge='end'
            onClick={event => {
              event.preventDefault();
              navigate('/plans/new');
            }}
            size='small'
          >
            <Box color='text.secondary' display='flex'>
              <AddCircleOutlineIcon fontSize='small' />
            </Box>
          </IconButton>
        </div>
      ) : null,
      startIcon: <CheckIcon />,
      text: t('menu:plans'),
      component: MuiNavLink,
      to: '/plans',
    },
  ];

  const adminMenuItems = [
    {
      startIcon: <NotificationsNoneIcon />,
      text: t('menu:latest_activity'),
      to: '/activity',
      component: MuiNavLink,
    },
    {
      startIcon: <ApartmentIcon />,
      text: t('menu:organizations'),
      to: '/organizations',
      component: MuiNavLink,
    },
    {
      can: 'read:language',
      startIcon: <LanguageIcon />,
      text: t('menu:languages'),
      to: '/languages',
      component: MuiNavLink,
    },
    {
      startIcon: <GroupWorkIcon />,
      text: t('menu:groups'),
      to: '/groups',
      component: MuiNavLink,
    },
    {
      startIcon: <PeopleIcon />,
      text: t('menu:users'),
      to: '/users',
      component: MuiNavLink,
    },
  ];

  function handleOrgMenuClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    setOrgMenuAnchorEl(event.currentTarget);
  }

  let organizationName = activeOrganization?.name || 'Loading…';
  if (isViewingAllOrgs) {
    organizationName = user?.can('read_all:organization') ? t('menu:all_organizations') : t('menu:my_organizations');
  }

  return (
    <Drawer
      anchor={dir}
      className={classes.drawer}
      classes={{ paper: classNames(classes.drawerRoot, classes.drawer) }}
      onClose={() => handleCloseMobileSidebar()}
      open={open}
      variant={isMobileView ? 'temporary' : 'permanent'}
    >
      {/* Logo */}
      <Box p={2} pb={1} pt={3}>
        <Box width='100%'>
          <Button className={classes.homeButton} component={Link} disableRipple={true} to='/plans'>
            <img alt={t('common:partner_portal_logo')} height='48px' src={partnerPortalLogo} />
          </Button>
        </Box>
      </Box>

      {/* Pages */}
      <List className={classes.sideMenuList} dense={enableDenseSidebar}>
        <Box display='flex' flexDirection='column' flexGrow={1}>
          <Box>
            <SidebarItem
              component={MuiNavLink}
              endButton={
                organizations?.length > 1 ? (
                  <Box
                    aria-controls='org-menu'
                    aria-haspopup='true'
                    aria-label={t('menu:switch_org')}
                    color='text.secondary'
                    display='flex'
                    marginInlineStart='auto'
                    onClick={event => {
                      event.preventDefault();
                      event.stopPropagation();
                      handleOrgMenuClick(event);
                    }}
                  >
                    <IconButton edge='end' size='small'>
                      <MoreVertIcon fontSize='small' />
                    </IconButton>
                  </Box>
                ) : null
              }
              isSelected={activeOrganizationMatch !== null}
              onClick={() => {
                handleCloseMobileSidebar();
              }}
              showRippleEffect={true}
              text={
                <Typography
                  className={classes.textBold}
                  maxWidth={164}
                  overflow='hidden'
                  textOverflow='ellipsis'
                  variant='body1'
                >
                  {organizationName}
                </Typography>
              }
              to={
                isViewingAllOrgs && user?.can('read_all:organization')
                  ? '/organizations'
                  : `/organizations/${activeOrganization?.id}`
              }
            />

            <Menu
              anchorEl={orgMenuAnchorEl}
              id='org-menu'
              keepMounted={true}
              onClose={() => setOrgMenuAnchorEl(null)}
              open={Boolean(orgMenuAnchorEl)}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <MenuItem
                className={classes.menuItem}
                component={Link}
                onClick={() => {
                  setOrgMenuAnchorEl(null);
                  handleCloseMobileSidebar();
                }}
                to='/organizations'
              >
                {t('menu:switch_org')}
              </MenuItem>
            </Menu>

            {orgMenuItems.map(({ text, to, ...otherProps }) => (
              <SidebarItem
                key={text}
                onClick={handleCloseMobileSidebar}
                showRippleEffect={true}
                text={text}
                to={to}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...otherProps}
              />
            ))}

            <Fade in={isAdmin}>
              <Box mt={2}>
                {isAdmin ? (
                  <>
                    <SidebarItem
                      showRippleEffect={false}
                      text={
                        <Typography className={classNames(classes.textBold, classes.adminHeader)} variant='body1'>
                          {t('menu:admin_pages')}
                          <Box alignItems='center' color='text.secondary' component='span' display='flex' marginX={0.5}>
                            <Tooltip placement='top' title={t('menu:account_tooltip_message')}>
                              <InfoOutlinedIcon fontSize='inherit' />
                            </Tooltip>
                          </Box>
                        </Typography>
                      }
                    />
                    {adminMenuItems.map(({ text, ...otherProps }) => (
                      <SidebarItem
                        isSelected={false}
                        key={text}
                        onClick={handleCloseMobileSidebar}
                        showRippleEffect={true}
                        text={text}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...otherProps}
                      />
                    ))}
                  </>
                ) : null}
              </Box>
            </Fade>
          </Box>
        </Box>

        <Box mt={2}>
          {/* Takes you to Help https://partnerportal.youversion.com/help */}
          <SidebarItem
            component='a'
            endButton={<OpenInNewRounded color='action' fontSize='small' />}
            href='https://lifechurch.formstack.com/forms/yvconnect_support'
            onClick={handleCloseMobileSidebar}
            showRippleEffect={true}
            text={t('menu:help')}
          />

          {/* Takes you to My Account https://partnerportal.youversion.com/my-account */}
          <SidebarItem
            component={MuiNavLink}
            onClick={handleCloseMobileSidebar}
            showRippleEffect={true}
            text={t('menu:my_account')}
            to='/my-account'
          />
          <Box mt={1} pl={2} pr={2}>
            <Divider />
          </Box>

          {/* User Section */}
          <Box alignItems='center' data-testid='user-info' display='flex' flexGrow={1} p={2} pb={1} width='100%'>
            <Box mr={1}>
              <Avatar src={avatarUrl} />
            </Box>
            <Box marginX='0.5rem' width='calc(100% - 48px)'>
              <Typography display='block' noWrap={true} variant='body2'>
                {userFullName}
              </Typography>
              <Typography
                className={classNames(classes.textBold, classes.anchorTag)}
                onClick={() => signOut().then(navigate('/'))}
                variant='caption'
              >
                {t('menu:sign_out')}
              </Typography>
            </Box>
          </Box>
        </Box>
      </List>
    </Drawer>
  );
};

export default connect(
  (state: State) => ({
    activeOrganization: activeOrgSelector(state),
    avatarUrl: ownAvatarUrlSelector(state),
    isAdmin: isAdminSelector(state),
    isViewingAllOrgs: viewingAllOrgsSelector(state),
    organizations: orgsLoadingSelector(state)
      ? []
      : allOrgsSelector(state)?.map((org: ActiveOrganization) => ({
          name: org.name,
          orgId: org.id,
        })) || [],
    userFullName: userSelector(state)
      ? `${userSelector(state).first_name} ${userSelector(state).last_name}`
      : 'Loading…',
  }),
  // TODO: remove the logout function from redux and create the logout function in src/api (https://lifechurch.atlassian.net/browse/PP-1288)
  (dispatch: any) => ({
    signOut: () => dispatch(logout()),
  })
)(Sidebar);
