/* eslint-disable import/prefer-default-export */
/**
 * @module AudioBlockText
 */
import InfoIcon from '@mui/icons-material/Info';
import { Box, Dialog, IconButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { RoundedButton } from '@youversion/mui-block-editor/components';
import { utilityColors } from '@youversion/react/styles/colors-v3';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  infoText: {
    color: theme.palette.primary,
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:hover': {
      color: utilityColors.light.info,
    },
  },
  infoIcon: {
    position: 'absolute',
    insetInlineEnd: 5,
    insetBlockStart: 5,
  },
}));

/**
 * The AudioBlockText component.
 *
 * @alias module:AudioBlockText
 *
 * @returns {React.ReactElement} - The AudioBlockText component.
 */
export function AudioBlockText() {
  const classes = useStyles();
  const { t } = useTranslation(['plan_days', 'common']);

  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  function toggleDialogOpen(event) {
    event.stopPropagation();
    setIsDialogOpen(prevState => !prevState);
  }

  function handleCloseDialog() {
    setIsDialogOpen(false);
  }

  return (
    <React.Fragment>
      <IconButton
        aria-label={t('plan_days:plan_day_edit.devotional_content.audio.audio_block_info')}
        className={classes.infoIcon}
        onClick={toggleDialogOpen}
        size='large'
      >
        <InfoIcon />
      </IconButton>
      <Typography gutterBottom={true} variant='subtitle1'>
        {t('plan_days:plan_day_edit.devotional_content.audio.drag_and_drop_message')}
      </Typography>
      <Box align='center' width='75%'>
        <Typography align='center' variant='caption'>
          <Trans
            components={{
              span: (
                <Typography
                  className={classes.infoText}
                  color='primary'
                  component='span'
                  onClick={toggleDialogOpen}
                  variant='caption'
                />
              ),
            }}
            i18nKey='plan_days:plan_day_edit.devotional_content.audio.sub_text_on_empty_area'
            t={t}
          />
        </Typography>
      </Box>
      <Dialog onClick={event => event.stopPropagation()} onClose={handleCloseDialog} open={isDialogOpen}>
        <Box maxWidth={600} p={3}>
          <Box mb={2}>
            <Typography variant='h3'>
              {t('plan_days:plan_day_edit.devotional_content.audio.automated_audio')}
            </Typography>
            <Typography variant='body1'>
              {t('plan_days:plan_day_edit.devotional_content.audio.automated_audio_subtext')}
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography variant='h3'>{t('plan_days:plan_day_edit.devotional_content.audio.narrated_audio')}</Typography>
            <Typography variant='body1'>
              {t('plan_days:plan_day_edit.devotional_content.audio.narrated_audio_subtext')}
            </Typography>
          </Box>
        </Box>
      </Dialog>
    </React.Fragment>
  );
}

AudioBlockText.defaultProps = {};
