/**
 * @module LinkOffButton
 */
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AsyncButton, useAlert } from '@youversion/react';
import { gray } from '@youversion/react/styles/colors-v3';
import { useLinkPlanLanguage } from 'api/plans';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  AdminBarButton: {
    '&:hover': {
      backgroundColor: gray[30],
    },
    backgroundColor: gray[35],
    color: 'white',
    marginInlineStart: '0.5rem',
    whiteSpace: 'nowrap',
  },
  unlinkButton: {
    backgroundColor: gray[35],
    color: 'white',
    '&:hover': {
      backgroundColor: gray[30],
    },
    marginInlineStart: '0.5rem',
  },
  button: {
    '&:hover': {
      backgroundColor: gray[20],
    },
    backgroundColor: gray[35],
    color: 'white',
    marginInlineStart: '0.5rem',
  },
}));

interface Props {
  // The plan id to export.
  planId: number;
}

export const UnlinkPlanButton: React.FC<Props> = ({ planId }) => {
  const { t } = useTranslation(['plans', 'common']);
  const classes = useStyles();
  const { throwAlert } = useAlert();
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const { mutate: unlinkPlan } = useLinkPlanLanguage();

  async function handleUnlinkPlan() {
    unlinkPlan(
      { planId, sourceId: null },
      {
        onError: error => {
          if (error instanceof Error) {
            throwAlert({
              id: 'unlink_plan_error',
              key: 'unlink_plan_error',
              message: t('plans:unlink_plan.error_unlink', {
                message: error.message,
              }),
              timeout: 5000,
              type: 'error',
            });
          }
        },
        onSuccess: response => {
          if (response) {
            throwAlert({
              id: 'unlink_plan_success',
              key: 'unlink_plan_success',
              message: t('plans:unlink_plan.success_unlink'),
              timeout: 5000,
              type: 'success',
            });
            window.location.reload();
          }
        },
      }
    );
    setIsDialogOpen(!isDialogOpen);
  }

  return (
    <>
      <Button
        className={classes.unlinkButton}
        onClick={() => setIsDialogOpen(!isDialogOpen)}
        startIcon={<LinkOffIcon />}
        variant='contained'
      >
        {t('plans:unlink_plan.unlink')}
      </Button>
      <Dialog
        aria-describedby='unlink-plan-dialog-content'
        aria-labelledby='unlink-plan-dialog-title'
        onClose={() => setIsDialogOpen(!isDialogOpen)}
        open={isDialogOpen}
      >
        <DialogTitle id='unlink-plan-dialog-title'>{t('plans:unlink_plan.title')}</DialogTitle>
        <DialogContent>
          <DialogContentText id='unlink-plan-dialog-content'>{t('plans:unlink_plan.description')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color='primary' onClick={() => setIsDialogOpen(!isDialogOpen)}>
            {t('common:cancel')}
          </Button>
          <AsyncButton
            className={classes.button}
            color='primary'
            idle={t('plans:unlink_plan.unlink_plan')}
            onClick={() => handleUnlinkPlan()}
            options={{
              disableUpdateOnSuccess: true,
            }}
            pending={t('plans:unlink_plan.unlinking')}
            rejected={t('plans:unlink_plan.error_unlinking')}
            resolved={t('plans:unlink_plan.unlinking')}
            variant='contained'
          />
        </DialogActions>
      </Dialog>
    </>
  );
};
