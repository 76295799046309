/* eslint-disable consistent-return */
import { useMutation, useQuery } from '@tanstack/react-query';
import { API_ADDRESS, getToken } from 'helpers';
import { fetchClient } from 'helpers/transport';

interface Data {
  description: string;
  name: string;
}
interface GroupData {
  data: Data;
}
/*
 * API call to get all groups
 */
const getGroups = async (): Promise<Array<Group>> => {
  const response = await fetchClient<{ groups: Array<Group> }>('/groups');
  return response.parsedBody.groups;
};

export const useGetGroups = () => useQuery(['groups'], getGroups);

/*
 * API call to get a single group.
 */
const getGroup = async (groupId: number | string | undefined): Promise<Group | undefined> => {
  try {
    const response = await fetchClient<{ group: Group }>(`/groups/${groupId}`);
    return response.parsedBody.group;
  } catch (error) {
    if (error instanceof Response) {
      throw new Error('GroupNotFound');
    }
    if (error instanceof Error) {
      throw new Error(error.message);
    }
  }
};

export const useGetGroup = (groupId: number | string | undefined) =>
  useQuery(['group', groupId], () => getGroup(groupId));

/*
 * API call to create a new group.
 */
const createGroup = async ({ data }: GroupData): Promise<Group> => {
  const authToken = await getToken();
  const response = await fetch(`${API_ADDRESS}/groups`, {
    body: JSON.stringify({
      group: {
        ...data,
      },
    }),
    headers: {
      authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
  });
  const jsonResponse = await response.json();
  return jsonResponse.group;
};

export const useCreateGroup = () => useMutation(createGroup);

interface UpdateGroupParams {
  /**
   * The groups data.
   */
  data: object;
  /**
   * The group id to update.
   */
  groupId: number | string;
}

/*
 * API call to update a group.
 */
const updateGroup = async ({ data, groupId }: UpdateGroupParams): Promise<Group> => {
  const authToken = await getToken();
  const response = await fetch(`${API_ADDRESS}/groups/${groupId}`, {
    body: JSON.stringify({
      group: {
        ...data,
      },
    }),
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
  });
  const jsonResponse = await response.json();
  return jsonResponse.group;
};

export const useUpdateGroup = () => useMutation(updateGroup);

/*
 * API call to delete a group.
 */
const destroyGroup = async (groupId: string | number) => {
  const authToken = await getToken();
  const response = await fetch(`${API_ADDRESS}/groups/${groupId}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
    method: 'DELETE',
  });
  return response.status;
};

export const useDestroyGroup = () => useMutation(destroyGroup);
