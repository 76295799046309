/* eslint-disable jsdoc/require-param */
import { useAuth } from 'auth';
import LoaderOverlay from 'components/LoaderOverlay';
import { PaneContent } from 'components/misc';
import styles from 'components/MyAccount/MyAccount.module.scss';
import { UserDetails } from 'components/MyAccount/UserDetails';
import { UserGroups } from 'components/users/user-groups';
import { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { NumberLike } from 'types/misc';

interface MyAccountProps {
  orgId: NumberLike;
}

/**
 * My Account Component.
 *
 * @returns {ReactElement} - Returns jsx.
 */
export function MyAccount({ orgId }: MyAccountProps) {
  const { user } = useAuth();
  const dispatch = useDispatch();

  if (!user) {
    return <LoaderOverlay />;
  }

  const canUserViewGroupMembership = user.can('read_all:group_membership');

  return (
    <PaneContent className={styles.orgWrapper}>
      <UserDetails dispatch={dispatch} orgId={orgId} user={user} />
      {canUserViewGroupMembership ? <UserGroups user={user} /> : null}
    </PaneContent>
  );
}
