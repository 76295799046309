/* eslint-disable jsdoc/require-param */
/**
 * @module narratedAudioApiMethods
 */

import { useMutation } from '@tanstack/react-query';
import { fetchClient } from 'helpers/transport';
import { NumberLike } from 'types/misc';

/**
 * Delete a narrated audio file.
 *
 * @returns {Promise} - Does not return anything.
 *
 * @example
 * await deleteNarratedAudio(2)
 * await deleteNarratedAudio('2')
 */
async function deleteNarratedAudio(id: NumberLike) {
  return fetchClient(`/v1/narrated_audio_attachments/${id}`, {
    method: 'DELETE',
  });
}

export const useDeleteNarratedAudio = () => useMutation(deleteNarratedAudio);
