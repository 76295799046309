/* eslint-disable jsdoc/require-param */
/**
 * @module PlanHeading
 */
import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { yvRed } from '@youversion/react/styles/colors-v3';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  ctaButton: {
    '&:hover': {
      backgroundColor: yvRed,
      color: 'white',
    },
    backgroundColor: yvRed,
    color: 'white',
  },
}));

interface PlansHeadingProps {
  /** Indicates that there is an organization selected/active. */
  isViewingSingleOrg: boolean;
}

/**
 * Plans Heading with Add Plan CTA Button.
 *
 * @returns {ReactElement} - The PlansHeading component.
 */
export function PlansHeading({ isViewingSingleOrg }: PlansHeadingProps) {
  const { t } = useTranslation('plans');
  const classes = useStyles();

  return (
    <Box alignItems='flex-start' display='flex' justifyContent='space-between'>
      <Box>
        <Typography color='textPrimary' variant='h1'>
          {t('my_plans')}
        </Typography>
      </Box>
      <div>
        {/* Hide the add a Plan button when the user is viewing all organizations. */}
        {isViewingSingleOrg ? (
          <Link to='/plans/new'>
            <Button
              className={classes.ctaButton}
              color='primary'
              size='large'
              startIcon={<AddIcon />}
              variant='contained'
            >
              {t('create_new_plan')}
            </Button>
          </Link>
        ) : null}
      </div>
    </Box>
  );
}
