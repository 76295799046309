import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

interface Props {
  onRequestSort: (property: string) => void;
  order: 'asc' | 'desc';
  orderBy: string;
}

const useStyles = makeStyles(() => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    insetBlockStart: 20,
    width: 1,
  },
}));

const headCells = [
  {
    id: 'avatar',
    label: '',
  },
  { id: 'first_name', label: 'Name' },
  { id: 'email', label: 'Email' },
  { id: 'created_at', label: 'Created' },
  { id: 'id', label: 'ID' },
];

export function UsersTableHead({ order, orderBy, onRequestSort }: Props) {
  const classes = useStyles();
  const createSortHandler = (property: string) => onRequestSort(property);

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            align='left'
            key={headCell.id}
            padding='normal'
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={() => createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
