/* eslint-disable jsdoc/require-throws */
/**
 * @module Stats
 */

import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from 'helpers';
import { fetchClient } from 'helpers/transport';

interface PlanStatsResponse {
  approved: number;
  draft: number;
  published: number;
  rejected: number;
  submitted: number;
}

/**
 * GET all Plans Stats.
 *
 * @returns {Promise<object>} - An object of stats.
 */
async function getPlanStats() {
  const response = await fetchClient<PlanStatsResponse>('/plans/stats');
  return response.parsedBody;
}

export const useGetPlanStats = () => useQuery([QUERY_KEYS.PLAN_STATS], getPlanStats);
