/* eslint-disable jsdoc/require-param */
/**
 * @module EditFormHelpers
 */

import { FileRejection } from 'react-dropzone';

interface ValidateImageDimensionsProps {
  /** Height to validate image with. */
  height: number;
  /** Image url to validate. */
  imageUrl: string;
  /** Width to validate image with. */
  width: number;
}

/**
 * Validates image dimensions.
 *
 * @throws {Error} - When image does not match the dimensions required.
 * @returns {Promise<object>} - The image object.
 */
export function validateImageDimensions({ height, width, imageUrl }: ValidateImageDimensionsProps) {
  return new Promise((accept, reject) => {
    const img = new Image();

    img.onload = () => {
      if (img.width < width || img.height < height || img.width / img.height !== width / height) {
        return reject(
          new Error(`The image is not the correct dimensions or ratio (${width} x ${height}). Please try again.`)
        );
      }

      return accept(true);
    };
    img.src = imageUrl;
  });
}

interface ValidateFileIsImageProps {
  acceptedFile: Array<File>;
  rejectedFile: Array<FileRejection>;
}

/**
 * Checks if file is actually an image.
 *
 * @returns {Promise<object>} - Tells if file is valid or not and gives a message if its not.
 */
export function validateFileIsImage({ acceptedFile, rejectedFile }: ValidateFileIsImageProps) {
  let imageErrorMsg = '';
  let isValid = false;

  // If a file does not exist or was rejected, then throw an error.
  if (!acceptedFile.length || rejectedFile.length) {
    imageErrorMsg = 'This file type is not supported. Please upload a png, jpg, or jpeg image.';
  } else {
    isValid = true;
  }

  return { imageErrorMsg, isValid };
}
