/**
 * @module DayBoxes
 */
import { Add } from '@mui/icons-material';
import { Button, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { usePlan } from 'context';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import { DayScrollerDay } from '../Day';
import styles from '../DayScroller.module.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dayBox: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      border: '3px solid transparent',
      borderRadius: theme.spacing(1),
      height: 70,
      marginInlineEnd: theme.spacing(1),
      textAlign: 'center',
      textTransform: 'none',
      width: 70,
      '&:hover': {
        color: theme.palette.common.white,
      },
    },
    selectedBox: {
      fontWeight: 'bold',
    },
  })
);

/**
 * Represents a container of Day box components used in the DayScroller.
 *
 * @returns {React.ReactElement} - The DayBoxes component.
 */
export function DayScrollerDayBoxes() {
  const classes = useStyles();
  const { t } = useTranslation('plans');
  const { dayId, id: planId } = useParams();
  const { disableNewDay, planDays, scrollRef } = usePlan();

  React.useEffect(() => {
    if (dayId === 'new' && scrollRef !== undefined && scrollRef.current && scrollRef.current.lastElementChild) {
      scrollRef.current.lastElementChild.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [dayId, scrollRef]);

  return (
    <>
      {planDays.map((day, index) => {
        const prevDayIndex = index === 0 ? -1 : index - 1;
        const nextDayIndex = index !== planDays.length - 1 ? index + 1 : -1;
        return (
          <DayScrollerDay
            day={day}
            index={index}
            key={day.id}
            nextDayPosition={nextDayIndex >= 0 ? planDays[nextDayIndex].position : undefined}
            prevDayPosition={prevDayIndex >= 0 ? planDays[prevDayIndex].position : undefined}
            totalDays={planDays.length}
          />
        );
      })}
      {!disableNewDay ? (
        <Button
          aria-label={t('plans:day.add_new_day')}
          className={classNames(classes.dayBox, dayId === 'new' ? styles.dayBoxSelected : null)}
          component={Link}
          disabled={dayId === 'new'}
          key='newDay'
          to={`/plans/${planId}/days/new`}
          variant='contained'
        >
          <Add />
        </Button>
      ) : null}
    </>
  );
}
