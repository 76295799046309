import { Button, Tooltip } from '@mui/material';
import { useAcknowledgeActivityItem, useUnAcknowledgeActivityItem } from 'api/activity';
import React from 'react';

interface AcknowledgeButtonProps {
  item: ActivityItem;
}

export const AcknowledgeButton = ({ item }: AcknowledgeButtonProps) => {
  const { mutateAsync: onAcknowledgeItem } = useAcknowledgeActivityItem();
  const { mutateAsync: onUnacknowledgeItem } = useUnAcknowledgeActivityItem();
  const [isAcknowledged, setIsAcknowledged] = React.useState(() => item.acknowledger);

  const buttonHandler = isAcknowledged
    ? async () => {
        await onUnacknowledgeItem(item.id);
        setIsAcknowledged(null);
      }
    : async () => {
        const { acknowledger } = await onAcknowledgeItem(item.id);
        setIsAcknowledged(acknowledger);
      };

  if (isAcknowledged) {
    return (
      <Tooltip title='Unacknowledge Activity'>
        <Button color='primary' onClick={buttonHandler} variant='contained'>
          Seen by {isAcknowledged.first_name} {isAcknowledged.last_name}
        </Button>
      </Tooltip>
    );
  }

  return (
    <Tooltip title='Acknowledge Activity'>
      <Button color='secondary' onClick={buttonHandler} variant='contained'>
        Acknowledge
      </Button>
    </Tooltip>
  );
};
