/**
 * @module SyncCrowdinButton
 */
import makeStyles from '@mui/styles/makeStyles';
import { AsyncButton } from '@youversion/react';
import { gray } from '@youversion/react/styles/colors-v3';
import SyncCrowdinIcon from 'components/Icons/SyncCrowdinIcon';
import { FC } from 'react';

const useStyles = makeStyles(() => ({
  syncCrowdinButton: {
    backgroundColor: gray[35],
    color: 'white',
    '&:hover': {
      backgroundColor: gray[30],
    },
    marginInlineStart: '0.5rem',
  },
}));

export const SyncCrowdinButton: FC = () => {
  const classes = useStyles();
  return (
    <AsyncButton
      className={classes.syncCrowdinButton}
      idle='Sync Crowdin Strings'
      // TODO onClick will have API call when it is ready to be implemented.
      // onClick={() => console.log('Update this onClick')}
      options={{
        disableUpdateOnSuccess: true,
      }}
      rejected='Sync Crowdin Strings'
      resolved='Sync Crowdin Strings'
      startIcon={<SyncCrowdinIcon />}
      variant='contained'
    />
  );
};
