import LoadingButton from 'components/misc/LoadingButton';
import { APP_ELEMENT } from 'helpers/constants';
import React from 'react';
import Modal from 'react-modal';

import styles from './styles.module.scss';

export interface ConfirmProps {
  /*
   * Text for the cancel button
   * Optional - defaults to "Cancel"
   */
  cancelText?: string;
  /*
   * Text for the cancel button
   * String or renderable element(s)/component
   */
  confirmBody: string | React.ReactNode;
  /*
   * Text for the confirm button
   * Optional - defaults to "Confirm"
   */
  confirmText?: string;
  /*
   * Specify if the confirmation action is pending
   */
  isConfirmPending?: boolean;
  /*
   * Specify if the modal is showing or hidden
   */
  isOpen: boolean;
  /*
   * Handler function called when confirm button is clicked
   */
  onClose: () => void;
  /*
   * Handler function called when confirm button is clicked
   */
  onConfirm: () => void;
}

const Confirm: React.FC<ConfirmProps> = ({
  isOpen,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  confirmBody,
  onClose,
  onConfirm,
  isConfirmPending = false,
}) => {
  const modalBody =
    typeof confirmBody === 'string' ? <p className={styles.centeredConfirmText}>{confirmBody}</p> : confirmBody;

  return (
    <Modal
      appElement={APP_ELEMENT ?? undefined}
      ariaHideApp={false}
      className={styles.modalStyle}
      contentLabel='ConfirmOpen'
      isOpen={isOpen}
      onRequestClose={onClose}
      overlayClassName={styles.overlayStyle}
    >
      {modalBody}
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <LoadingButton
          className={styles.btnBig}
          isLoading={isConfirmPending}
          onClick={onConfirm}
          style={{ marginInlineEnd: 20 }}
        >
          {confirmText}
        </LoadingButton>
        <button className={styles.cancelBtn} onClick={onClose} style={{ marginInlineStart: 20 }} type='button'>
          {cancelText}
        </button>
      </div>
    </Modal>
  );
};

export default Confirm;
