/* eslint-disable jsdoc/require-param */
import DeleteButton from 'components/Buttons/Delete';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import styles from '../../styles.module.scss';

interface GroupProps {
  /** Group membership data. */
  membership: GroupMembership;
  /** Handler to delete membership. */
  onDelete: () => void;
}

/**
 * User's group.
 *
 * @returns {ReactElement} - Returns jsx.
 */
export function UserGroup({ membership, onDelete }: GroupProps) {
  const { group } = membership;
  const { t } = useTranslation('users');

  return (
    <li key={group.id}>
      <Link className={styles.groupName} to={`/groups/${group.id}`}>
        {group.name}
      </Link>
      <DeleteButton aria-label={t('groups.remove_from_group')} className={styles.groupDelete} onDelete={onDelete} />
    </li>
  );
}
