/* eslint-disable jsdoc/require-param */
/**
 * @module NewOrganizationLandingPage
 */
import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Typography } from '@mui/material';
import { LazyImage } from '@youversion/react';
import PlanCardStack from 'assets/plan-card-stack.png';
import i18n from 'helpers/i18n';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  isViewingSingleOrg: boolean;
}

const NewOrganization: FC<Props> = ({ isViewingSingleOrg }) => {
  const { t } = useTranslation('plans');
  const uiLanguage = i18n.language;

  return (
    <Box
      alignItems='center'
      component='div'
      display='flex'
      flexDirection='column'
      justifyContent='center'
      marginBlockStart='60px'
    >
      <LazyImage
        alt='plans-landing-page'
        aspectRatio='338.5/285.5'
        height='285.5px'
        src={PlanCardStack}
        width='338.5px'
      />
      <Typography marginBlockStart='8px' variant='h1'>
        {t('no_plans_title')}
      </Typography>
      <Typography marginBlockEnd='32px' variant='body2'>
        {t('no_plans_desc')}
      </Typography>
      {isViewingSingleOrg ? (
        <Button href='/plans/new' startIcon={<AddIcon />} target='blank' variant='contained'>
          {t('button.get_started')}
        </Button>
      ) : null}

      <a
        href={`https://partner-support.youversion.com/l/${uiLanguage.slice(0, 2)}/article/0vvea0bpsm`}
        target='__blank'
      >
        <Typography lineHeight='5px' marginBlockStart='base' variant='overline'>
          {t('or_learn_more')}
        </Typography>
      </a>
    </Box>
  );
};

export default NewOrganization;
