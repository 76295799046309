import { useQuery } from '@tanstack/react-query';
import { cleanParams } from 'helpers/remove-nulls';
import { fetchClient } from 'helpers/transport';
import { NumberLike } from 'types/misc.d';

import { QUERY_KEYS } from '../../helpers/constants';

export interface GetReportsProps {
  end_month?: NumberLike;
  end_year?: NumberLike;
  start_month?: NumberLike;
  start_year?: NumberLike;
}

/**
 * GET without plans report.
 *
 * @throws {Error} - Throws an error if there's a problem with the API response.
 *
 * @returns {Promise<object>} - Report data.
 */
export async function getWithoutPlansReport({
  start_year,
  start_month,
  end_month,
  end_year,
}: GetReportsProps): Promise<Array<PartnerRecord> | undefined> {
  const removeAllNullableValues = cleanParams({
    start_year,
    start_month,
    end_month,
    end_year,
  });
  const params = new URLSearchParams(removeAllNullableValues);
  try {
    const response = await fetchClient<Array<PartnerRecord>>(
      `/v1/reports/global_reps/without_plans?${params.toString()}`
    );
    return response.parsedBody;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    }
  }
}

export const useGetWithoutPlansReport = (query: GetReportsProps) =>
  useQuery([QUERY_KEYS.WITHOUT_PLANS_REPORT, query], () => getWithoutPlansReport(query));

/**
 * GET plans report.
 *
 * @throws {Error} - Throws an error if there's a problem with the API response.
 *
 * @returns {Promise<object>} - Report data.
 */
export async function getWithPlansReport({
  start_year,
  start_month,
  end_month,
  end_year,
}: GetReportsProps): Promise<Array<PartnerRecord> | undefined> {
  const removeAllNullableValues = cleanParams({
    start_year,
    start_month,
    end_month,
    end_year,
  });
  const params = new URLSearchParams(removeAllNullableValues);
  try {
    const response = await fetchClient<Array<PartnerRecord>>(`/v1/reports/global_reps/with_plans?${params.toString()}`);
    return response.parsedBody;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    }
  }
}

export const useGetWithPlansReport = (query: GetReportsProps) =>
  useQuery([QUERY_KEYS.WITHOUT_PLANS_REPORT, query], () => getWithPlansReport(query));
