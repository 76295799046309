/**
 * @module UsersReducer
 */

import { handleApiAction as handleApi } from 'helpers';
import update from 'immutability-helper';
import { handleActions } from 'redux-actions';
import { LOAD_STAFF_REPRESENTATIVES } from 'state/actions/app/constants';

const initialState = {
  staff_representatives: [],
};

export default handleActions(
  {
    [LOAD_STAFF_REPRESENTATIVES]: handleApi((state, { payload }) => {
      return {
        success: state => {
          return update(state, {
            staff_representatives: { $set: payload.representatives },
          });
        },
      };
    }),
  },
  initialState
);
