/**
 * @module Accept
 */
import { Box, Typography } from '@mui/material';
import { ConfirmUserAccountForm } from 'components/ConfirmUserAccountForm';
import { BASE_TITLE } from 'helpers/constants';
import { ReactElement } from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

/**
 * The Accept component.
 *
 * @alias module:Accept
 *
 * @returns {ReactElement} - The Reset Password component.
 */
function AcceptInvitation() {
  const { token } = useParams();
  const { t } = useTranslation(['create_account']);

  return (
    <>
      <Helmet title={`${BASE_TITLE} | ${t('accept_invitation')}`} />

      <Box alignItems='center' display='flex' flex={1} flexDirection='column' justifyContent='center'>
        <Box>
          <Typography color='textPrimary' gutterBottom={true} variant='h1'>
            {t('welcome_aboard')}
          </Typography>

          <Typography color='textSecondary' gutterBottom={true} variant='body1'>
            {t('create_password')}
          </Typography>

          <Box mt={2}>{token ? <ConfirmUserAccountForm token={token} /> : null}</Box>
        </Box>
      </Box>
    </>
  );
}

export default AcceptInvitation;
