/* eslint-disable react/jsx-props-no-spreading */
import { useAlert } from '@youversion/react';
import React from 'react';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import styles from '../fieldRenderers.module.scss';

/* eslint-disable react/prop-types */
export function FileAttachmentField({ className, onFileDrop, label }) {
  const { throwAlert } = useAlert();
  const [isUploadingFile, setIsUploadingFile] = React.useState(false);
  const { t } = useTranslation(['organizations']);

  const onDrop = React.useCallback(
    async (acc, rej) => {
      if (!onFileDrop) {
        return;
      }
      setIsUploadingFile(true);

      try {
        await onFileDrop(acc, rej);
        setIsUploadingFile(false);
        throwAlert({
          id: 'upload_field_success',
          key: 'upload_field_success',
          message: t('organizations:edit_org.upload_partnership_agreement.success_message'),
          type: 'success',
        });
      } catch (e) {
        throwAlert({
          id: 'upload_field_error',
          key: 'upload_field_error',
          message: t('organizations:edit_org.upload_partnership_agreement.failed_message'),
          type: 'error',
        });
      }
    },
    [onFileDrop, throwAlert, t]
  );

  return (
    <div className={styles.fileAttachmentWrapper} data-testid='file-attachment-container'>
      <Dropzone className={className} multiple={false} onDrop={onDrop}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} className={className}>
            <input {...getInputProps()} />
            {label ?? t('organizations:edit_org.upload_partnership_agreement.click_here_to_update_attachment')}
            {isUploadingFile ? (
              <span>
                &nbsp;
                <i className='fa fa-spinner fa-spin' />
              </span>
            ) : null}
          </div>
        )}
      </Dropzone>
    </div>
  );
}

FileAttachmentField.defaultProps = {
  isEditing: true,
};
