/**
 * @module GroupsRoutes
 */
// `path` should be the first prop in a Route so that routes are easy to maintain.
import { useAuth } from 'auth';
import Groups from 'containers/groups';
import { GroupNew } from 'containers/groups/new';
import { Route, Routes } from 'react-router-dom';

import GroupRoutes from './group';

export default function GroupsRoutes() {
  const { user } = useAuth();

  return (
    <Routes>
      <Route element={<Groups />} index={true} />

      {/* 🐣 Nested routes only below this line. */}
      {user?.can('create:group') ? <Route element={<GroupNew />} path='new' /> : null}
      <Route element={<GroupRoutes />} path=':id/*' />
    </Routes>
  );
}
