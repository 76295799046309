import { Box, Typography } from '@mui/material';
import { useGetOrganizations } from 'api/organizations';
import { API_STATUS } from 'helpers';
import { useState } from 'react';
import { NumberLike } from 'types/misc';

import { OrganizationMembershipDataGrid } from './DataGrid';

export interface OrganizatonMembershipsProps {
  userId: NumberLike;
}

const ORGANIZATION_MEMBERSHIPS_PER_PAGE = 50;

export function OrganizationMemberships({ userId }: OrganizatonMembershipsProps) {
  const [page, setPage] = useState(1);

  const {
    data,
    status: loadingStatus,
    refetch,
    error,
  } = useGetOrganizations({
    filters: {
      member_user_id: userId,
    },
    sorter: {},
    pagination: {
      page: Number(page),
      per: ORGANIZATION_MEMBERSHIPS_PER_PAGE,
    },
  });

  const isLoading = loadingStatus === API_STATUS.LOADING;

  return (
    <Box>
      <Typography color='textPrimary' variant='h2'>
        Organizations
      </Typography>
      {isLoading ? (
        <Typography color='textSecondary' variant='body1'>
          Loading memberships ...
        </Typography>
      ) : (
        <Box mt={2}>
          <OrganizationMembershipDataGrid
            data={data}
            error={error ? new Error('Error occured while fetching organizations') : undefined}
            isLoading={isLoading}
            page={page}
            refetch={refetch}
            setPage={setPage}
          />
        </Box>
      )}
    </Box>
  );
}
