/**
 * @module SubmitPlanButton
 */
import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useAlert } from '@youversion/react';
import { statusTypes } from '@youversion/utils';
import { submitForApproval } from 'api/plans';
import ConfirmationDialog from 'components/Plans/DialogWrapper/Confirmation';
import { MissingNarratedAudioDay } from 'context';
import { isPlanComplete } from 'helpers/completion-checklist';
import { enableSubmission, submissionStatuses } from 'helpers/constants';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Plan } from '../types';

const useStyles = makeStyles(() => ({
  btnSubmit: {
    borderRadius: 99,
  },
}));

interface Props {
  getMissingNarratedAudioDays?: () => Array<MissingNarratedAudioDay> | null;
  plan: Plan.Plan;
  planDays: Array<Plan.Day>;
  planLanguage: Language;
}

const MINIMUM_DAYS_FOR_SUBMISSION = 3;

/**
 * Determine if a given plan appears to be in a state that is "submittable".
 *
 * This is used to enable/disable the "Submit Plan" button.
 *
 * @param {object} plan - A "plan" object, as returned from the API.
 * @param {object} planDays - An array of the plan's "day" objects as returned from the API.
 * @returns {string} - Value from `enableSubmission` enum.
 */
function checkSubmitStatus(plan: Plan.Plan, planDays: Array<Plan.Day>): string {
  let numberOfSubmissionBlockingCriteria = 0;

  if (planDays.length < MINIMUM_DAYS_FOR_SUBMISSION) {
    numberOfSubmissionBlockingCriteria += 1;
  } else {
    planDays.forEach(day => {
      if (day.status === submissionStatuses.REJECTED) {
        numberOfSubmissionBlockingCriteria += 1;
      }
    });
  }

  if (plan.overall_status !== submissionStatuses.DRAFT) {
    numberOfSubmissionBlockingCriteria += 1;
  }

  const isPlanCompleted = isPlanComplete(plan, planDays);

  return numberOfSubmissionBlockingCriteria > 0 || !isPlanCompleted ? enableSubmission.CANNOT : enableSubmission.CAN;
}

/**
 * Responsible for submitting plans.
 *
 * @returns {React.ReactElement} - Buttons for specific state.
 */

const SubmitPlanButton: FC<Props> = ({
  plan,
  planDays,
  planLanguage,
  getMissingNarratedAudioDays,
}): React.ReactElement => {
  const { t } = useTranslation(['plans', 'common']);
  const classes = useStyles();
  const { throwAlert } = useAlert();

  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [loadingSubmissionStatus, setLoadingSubmissionStatus] = React.useState(statusTypes.IDLE);
  const canPlanSubmit = checkSubmitStatus(plan, planDays);

  function handleClose() {
    setIsDialogOpen(false);
    window.location.reload();
  }

  async function handleSubmit() {
    setLoadingSubmissionStatus(statusTypes.PENDING);
    try {
      const response = await submitForApproval(plan.id);
      if (response.error) {
        throw new Error(response.message);
      } else {
        setLoadingSubmissionStatus(statusTypes.RESOLVED);
      }
    } catch (error) {
      setLoadingSubmissionStatus(statusTypes.IDLE);
      setIsDialogOpen(false);
      if (error instanceof Error) {
        throwAlert({
          id: 'plan_submit_error',
          key: 'plan_submit_error',
          message: t('plans:submit_plan.error_message', {
            message: error.message,
          }),
          timeout: 5000,
          type: statusTypes.ERROR,
        });
      }
    }
  }

  const daysWithoutNarratedAudio = React.useMemo(() => {
    if (getMissingNarratedAudioDays) {
      const missingNarratedAudioDays = getMissingNarratedAudioDays();
      if (missingNarratedAudioDays && missingNarratedAudioDays.length) {
        return missingNarratedAudioDays.map(day => day.position).join(', ');
      }
    }

    return null;
  }, [getMissingNarratedAudioDays]);

  return (
    <>
      <Button
        className={classes.btnSubmit}
        color='primary'
        disabled={canPlanSubmit === enableSubmission.CANNOT}
        onClick={() => {
          setIsDialogOpen(true);
        }}
        size='large'
        variant='contained'
      >
        {t('plans:submit_plan.title')}
      </Button>

      <ConfirmationDialog
        daysWithoutNarratedAudio={daysWithoutNarratedAudio}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        hasBeenPublished={Boolean(plan.external_plan_id)}
        hasDraftLaunchDate={Boolean(plan.launches_at)}
        isDialogOpen={isDialogOpen}
        loadingStatus={loadingSubmissionStatus}
        plan={plan}
        planLanguage={planLanguage}
      />
    </>
  );
};

export default SubmitPlanButton;
