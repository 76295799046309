/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import { FormControl, TextField } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export const excludeOctober2022 = (date: moment.Moment) => date.get('month') === 9 && date.get('year') === 2022;

export const excludeWeekends = (date: moment.Moment) => [0, 6].includes(date.day());

export const excludeHolidays = (date: moment.Moment) => {
  // Structure is {month: [days]}
  const holidays: Record<number, Array<number>> = {
    0: [1, 2, 3, 4, 5],
    11: [21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
  };

  return holidays?.[date.get('month')]?.includes(date.date());
};

export const exclude2023Days = (date: moment.Moment) => {
  const holidays: Record<number, Array<number>> = {
    0: [24, 25, 26],
    3: [7],
    4: [29],
    6: [3, 4],
    8: [4],
    9: [17, 18, 19],
    10: [21, 22, 23, 24],
  };

  return date.get('year') === 2023 && holidays?.[date.get('month')]?.includes(date.date());
};

export const excludeDays = (date: moment.Moment) =>
  excludeWeekends(date) || excludeHolidays(date) || exclude2023Days(date);

interface DatePickerProps {
  handleDateChange: (date: moment.Moment | null) => void;
  isBeforeLaunchDate: boolean;
  selectedDate: moment.Moment;
}

export default function DatePicker({ selectedDate, handleDateChange, isBeforeLaunchDate }: DatePickerProps) {
  const { t, i18n } = useTranslation(['plans']);
  const today = moment().locale(i18n.language).format('MM/DD/YYYY');
  const twoWeeksDate = moment(today).locale(i18n.language).add('4', 'weeks').startOf('day');
  const isWeekend = excludeWeekends(selectedDate);
  const isHoliday = excludeHolidays(selectedDate);
  const isExcludedDayIn2023 = exclude2023Days(selectedDate);

  const errorMessage = isBeforeLaunchDate
    ? t('plans:submit_plan.initial_confirmation.choose_date_n_weeks_after_launch_date', { count: 4 })
    : isWeekend
      ? t('plans:submit_plan.initial_confirmation.cannot_launch_a_plan_on_weekend')
      : isHoliday
        ? t('plans:submit_plan.initial_confirmation.cannot_launch_a_plan_on_holiday')
        : isExcludedDayIn2023
          ? t('plans:submit_plan.initial_confirmation.cannot_launch_a_plan_in_2023')
          : '';

  return (
    <FormControl>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <MuiDatePicker
          disablePast={true}
          label={t('plans:submit_plan.initial_confirmation.enter_date')}
          minDate={twoWeeksDate}
          onChange={event => handleDateChange(event)}
          renderInput={params => (
            <TextField data-testid='launch-date-input' helperText={errorMessage} variant='standard' {...params} />
          )}
          shouldDisableDate={excludeDays}
          value={selectedDate}
        />
      </LocalizationProvider>
    </FormControl>
  );
}
