/**
 * @module ImageField
 */

import { useAlert } from '@youversion/react';
import PropTypes from 'prop-types';
import React from 'react';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import styles from '../fieldRenderers.module.scss';

/**
 * Creates a ImageField component for forms.
 *
 * @param {object} props - The component props object.
 * @param {string} [props.className] - Class name for this component's styles.
 * @param {React.Element|Node} [props.ImageComponent] - The component that contains the image file.
 * @param {boolean} [props.isEditing] - The boolean flag for edit status.
 * @param {Function} [props.onFileDrop] - The function to be run when the file is dropped.
 * @param {React.Element|Node} [props.placeholder] - The component to display until image is uploaded.
 * @param {string} [props.subText] - The subtext for the image.
 * @param {string} [props.url] - Url of existing image.
 *
 * @returns {React.ReactElement} - The ImageField Component.
 */
export function ImageField({ className, ImageComponent, isEditing, onFileDrop, url, placeholder, subText }) {
  const { throwAlert } = useAlert();
  const [isLoading, setIsLoading] = React.useState(false);
  const { t } = useTranslation(['organizations']);

  const onDrop = React.useCallback(
    async (acc, rej) => {
      if (!onFileDrop) {
        return;
      }

      if (!isEditing) {
        return;
      }

      setIsLoading(true);
      try {
        await onFileDrop(acc, rej);
        throwAlert({
          id: 'upload_field_success',
          key: 'upload_field_success',
          message: t('organizations:edit_org.profile_photo.success_message'),
          type: 'success',
          timeout: 3000,
        });
      } catch (e) {
        throwAlert({
          id: 'upload_field_error',
          key: 'upload_field_error',
          message: e?.message ?? t('organizations:edit_org.profile_photo.failed_message'),
          type: 'error',
          timeout: 3000,
        });
      } finally {
        setIsLoading(false);
      }
    },
    [isEditing, onFileDrop, throwAlert, t]
  );

  return (
    <div className={styles.imageWrapper}>
      <Dropzone accept='image/jpeg,image/png,image/jpg' disabled={!isEditing} multiple={false} onDrop={onDrop}>
        {({ getRootProps, getInputProps }) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <div {...getRootProps()} className={className}>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <input {...getInputProps()} />
            <ImageComponent isEditing={isEditing} isLoading={isLoading} placeholder={placeholder} url={url} />
          </div>
        )}
      </Dropzone>
      {subText}
    </div>
  );
}

ImageField.propTypes = {
  className: PropTypes.string,
  ImageComponent: PropTypes.elementType,
  isEditing: PropTypes.bool,
  onFileDrop: PropTypes.func.isRequired,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

ImageField.defaultProps = {
  className: '',
  ImageComponent: undefined,
  isEditing: true,
  placeholder: '',
  subText: '',
  url: null,
};
