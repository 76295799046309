import { useMutation, useQuery } from '@tanstack/react-query';
import { API_ADDRESS, getToken } from 'helpers';

/**
 * @typedef {(number|string)} NumberLike
 */

/**
 * Deletes an organization permission.
 *
 * @param {number} orgPermissionId - The organization permission's id.
 *
 * @throws {Error} - Throws an error if the orgId or permissionId is missing or incorrect.
 * @throws {Error} - Throws an error if there's a problem with the API response.
 *
 * @returns {Promise<object>} - All active permissions for the organization.
 */
export async function deleteOrganizationPermissions(orgPermissionId: number | string) {
  if (!orgPermissionId) {
    throw new Error('`orgPermissionId` is required and must be a number');
  }

  try {
    const authToken = await getToken();
    const response = await fetch(`${API_ADDRESS}/v1/organization_permissions/${orgPermissionId}`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      method: 'DELETE',
    });
    return response.status;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    }
  }
}

const getOrganizationPermissions = async (orgId: number | string): Promise<Array<OrganizationPermission>> => {
  const authToken = await getToken();
  const response = await fetch(`${API_ADDRESS}/v1/organization_permissions?organization_id=${orgId}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
    method: 'GET',
  });
  const jsonResponse = await response.json();
  return jsonResponse.data;
};

export const useGetOrganizationPermissions = (orgId: number | string) =>
  useQuery(['organization-permissions', orgId], () => getOrganizationPermissions(orgId));

interface Props {
  orgId: number;
  permissionId: string;
}

const createOrganizationPermissions = async ({ orgId, permissionId }: Props): Promise<OrganizationPermission> => {
  if (!orgId || !permissionId) {
    throw new Error('`orgId` and `permissionId` are required and must be a number or string.');
  }
  const authToken = await getToken();
  const response = await fetch(`${API_ADDRESS}/v1/organization_permissions`, {
    body: JSON.stringify({
      organization_permission: {
        organization_id: orgId,
        permission_id: permissionId,
      },
    }),
    headers: {
      authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
  });
  const jsonResponse = await response.json();
  return jsonResponse.data;
};

export const useCreateOrganizationPermissions = () => useMutation(createOrganizationPermissions);

export const useDeleteOrganizationPermissions = () => useMutation(deleteOrganizationPermissions);
