/**
 * @module RoutesUnauthenticated
 */
// `path` should be the first prop in a Route so that routes are easy to maintain.
/* eslint-disable react/jsx-sort-props */
import LoaderOverlay from 'components/LoaderOverlay';
import UnauthLayout from 'containers/unauth-layout';
import * as React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import SignUpRoutes from './sign-up';

const Accept = React.lazy(() => import('../accept'));
const Login = React.lazy(() => import('./login'));
const ResetPassword = React.lazy(() => import('./reset-password'));
const ForgotPassword = React.lazy(() => import('./forgot-password'));

function Redirected({ to }: { to: string }) {
  const location = useLocation();
  return <Navigate to={to} state={{ from: location }} />;
}

/**
 * The unauthenticated routes component.
 *
 * @alias module:RoutesUnauthenticated
 *
 * @returns {React.ReactElement} - The RoutesUnauthenticated component.
 */
// eslint-disable-next-line react/no-multi-comp
export default function RoutesUnauthenticated() {
  return (
    <UnauthLayout>
      {/* 🐣 Nested routes only below this line. */}
      <React.Suspense fallback={<LoaderOverlay />}>
        <Routes>
          <Route index={true} element={<Navigate to='login' />} />
          <Route path='accept/:token' element={<Accept />} />
          <Route path='forgot-password' element={<ForgotPassword />} />
          <Route path='login' element={<Login />} />
          <Route path='password/:token' element={<ResetPassword />} />
          <Route path='sign-up/*' element={<SignUpRoutes />} />
          <Route path='*' element={<Redirected to='/login' />} />
        </Routes>
      </React.Suspense>
    </UnauthLayout>
  );
}
