/* eslint-disable import/prefer-default-export */
import i18n from 'helpers/i18n';

interface Error {
  noReferences?: string;
}

/**
 * Validates an array of USFM references.
 *
 * @param {Array<string>} references - An array of references.
 *
 * @returns {Array<Error>} - An array of errors.
 */
export function validateReferences(references: Array<string>): Array<Error> {
  const { t } = i18n;
  const errors = [];

  if (!references || !references.length) {
    errors.push({
      noReferences: t('plan_days:validation.no_reference'),
    });
  }
  return errors;
}
