import { Delete as DeleteIcon } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useQueryClient } from '@tanstack/react-query';
import { AsyncButton, useAlert } from '@youversion/react';
import { yvRed } from '@youversion/react/styles/colors-v3';
import { useDeleteOrganizationMembership } from 'api/organization_memberships';
import { API_STATUS, QUERY_KEYS } from 'helpers';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NumberLike } from 'types/misc';

interface DeleteMemberModalProps {
  membershipId: NumberLike;
}

const useStyles = makeStyles(() => ({
  deleteButton: {
    backgroundColor: yvRed,
    '&:hover': {
      backgroundColor: yvRed,
    },
  },
}));

export function DeleteMember({ membershipId }: DeleteMemberModalProps) {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const { t } = useTranslation(['organizations', 'common']);

  const [show, setShow] = useState(false);
  const { mutateAsync: deleteMembership, status: loadingMembershipDeletion } = useDeleteOrganizationMembership();

  const { throwAlert } = useAlert();

  async function handleDeleteUser() {
    try {
      await deleteMembership(membershipId);
      queryClient.invalidateQueries([QUERY_KEYS.ORGANIZATION_MEMBERSHIPS]);
      throwAlert({
        id: 'organization_membership_delete_success',
        key: 'organization_membership_delete_success',
        message: t('organizations:single_org.membership_table.delete_member.organization_membership_delete_success'),
        timeout: 3000,
        type: 'success',
      });
      setShow(false);
    } catch (error) {
      throwAlert({
        id: 'organization_membership_delete_error',
        key: 'organization_membership_delete_error',
        message: t('organizations:single_org.membership_table.delete_member.organization_membership_delete_error'),
        timeout: 3000,
        type: 'error',
      });
    }
  }

  const isLoading = loadingMembershipDeletion === API_STATUS.LOADING;

  return (
    <>
      <Tooltip title={t('organizations:single_org.membership_table.delete_member.title')}>
        <IconButton
          aria-label={t('organizations:single_org.membership_table.delete_member.button.idle')}
          onClick={() => setShow(true)}
          size='large'
          style={{ color: yvRed }}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>

      <Dialog maxWidth='xs' onClose={() => setShow(false)} open={show}>
        <DialogTitle>{t('organizations:single_org.membership_table.delete_member.title')}</DialogTitle>

        <DialogContent>
          <DialogContentText>{t('organizations:single_org.membership_table.delete_member.sub_text')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus={true} disabled={isLoading} onClick={() => setShow(false)} type='button'>
            {t('common:close')}
          </Button>
          <AsyncButton
            className={classes.deleteButton}
            color='primary'
            disabled={isLoading}
            idle={t('organizations:single_org.membership_table.delete_member.button.idle')}
            onClick={handleDeleteUser}
            options={{
              disableUpdateOnSuccess: true,
            }}
            pending={t('organizations:single_org.membership_table.delete_member.button.loading')}
            rejected={t('organizations:single_org.membership_table.delete_member.button.failed')}
            resolved={t('organizations:single_org.membership_table.delete_member.button.success')}
            startIcon={<DeleteIcon />}
            variant='contained'
          />
        </DialogActions>
      </Dialog>
    </>
  );
}
