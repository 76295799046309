import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { AsyncButton, useAlert } from '@youversion/react';
import { useUpdateOrganization } from 'api/organizations';
import { API_STATUS } from 'helpers';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { NumberLike } from 'types/misc';

interface UpdateOrganizationModalProps {
  organizationId?: NumberLike;
  refetch: VoidFunction;
  setShow: Dispatch<SetStateAction<boolean>>;
  show: boolean;
}

export function ApproveOrganization({ organizationId, show, setShow, refetch }: UpdateOrganizationModalProps) {
  const { t } = useTranslation(['common', 'organizations']);
  const { mutateAsync: updateOrganization, status: loadingUpdateStatus } = useUpdateOrganization(organizationId);

  const { throwAlert } = useAlert();

  async function handleUpdateOrganization() {
    try {
      await updateOrganization({ status: 'approved' });
      refetch();
      throwAlert({
        id: 'update_organization_success',
        key: 'update_organization_success',
        message: t('organizations:approve_organization.success_message'),
        timeout: 3000,
        type: 'success',
      });
      setShow(false);
    } catch (error) {
      throwAlert({
        id: 'update_organization_error',
        key: 'update_organization_error',
        message: t('organizations:approve_organization.failed_message'),
        timeout: 3000,
        type: 'error',
      });
    }
  }

  const isLoading = loadingUpdateStatus === API_STATUS.LOADING;

  return (
    <>
      <Dialog maxWidth='xs' onClose={() => setShow(false)} open={show}>
        <DialogTitle>{t('organizations:approve_organization.title')}</DialogTitle>

        <DialogContent>
          <DialogContentText>{t('organizations:approve_organization.description')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus={true} disabled={isLoading} onClick={() => setShow(false)} type='button'>
            {t('common:close')}
          </Button>
          <AsyncButton
            color='primary'
            disabled={isLoading}
            idle={t('organizations:approve_organization.button.idle')}
            onClick={handleUpdateOrganization}
            options={{
              disableUpdateOnSuccess: true,
            }}
            pending={t('organizations:approve_organization.button.loading')}
            rejected={t('organizations:approve_organization.button.failed')}
            resolved={t('organizations:approve_organization.button.success')}
            variant='contained'
          />
        </DialogActions>
      </Dialog>
    </>
  );
}
