/**
 * @description - Contains type definitions for the plan module.
 *
 * TODO: Types not 100% defined, will keep updating them!
 */

import { Organization } from 'components/Organizations/types';
import { submissionStatuses } from 'helpers/constants';
import {
  Empty,
  NullableDate,
  NullableNumber,
  NullableString,
  NullableStringList,
  NumberLike,
  NumberList,
} from 'types/misc';

export namespace Plan {
  export enum DayStatus {
    APPROVED = 'approved',
    DRAFT = 'draft',
    MISSING_NARRATED_AUDIO_STATUS = 'missing-narrated-audio',
    PUBLISHED = 'published',
    REJECTED = 'rejected',
    SUBMITTED = 'submitted',
  }

  export interface NarratedAudioAttachment {
    created_at: Date;
    file_url: string;
    id: string;
    service_status: string;
    updated_at: Date;
  }

  export interface DevotionalContentBlocksContentError {
    /** Disable retrying of an upload. */
    disableRetry: boolean;
    /** The error heading. */
    heading: string;
    /** The error message. */
    message: string;
  }

  export interface DevotionalContentBlocksContent {
    /** Some alt text. */
    alt?: string;
    /** A url to display, typically for the image block. */
    display_url?: string;
    /** An error object. */
    error?: DevotionalContentBlocksContentError;
    /** A file attachment id. */
    file_id?: string;
    /** Some html. */
    html?: string;
    /** The Poster video url. */
    poster_url?: string;
    /**  A file's postprocessing url, typically for the audio block. */
    postprocessing_url?: string;
    /** A SoundCloud embed url. */
    soundcloud_embed_url?: string;
    /**  A file's source url. */
    source_url?: string;
    /** The video sources. */
    sources?: Array<{
      audio_codec: string | null;
      html_mime_type: string;
      url: string;
      video_codec: string | null;
    }>;
    src?: string;
    status?: string;
    type?: string;
    /** A YouTube video id. */
    youtube_video_id?: string;
  }
  export interface DevotionalContentBlocks {
    /** The uuid v4 block id. */
    block_id: string;
    /** The block content object. */
    content: Partial<DevotionalContentBlocksContent>;
    /** The block type. */
    type: string;
  }

  export interface Day {
    /** The draft devotional content blocks. */
    devotional_content_blocks?: Array<DevotionalContentBlocks> | null;
    id: NumberLike;
    narrated_audio_attachment?: NarratedAudioAttachment | null;
    /** The day's draft sort order position (in ascending order). */
    position: number;
    /** Draft scripture references. Example: ["eph2.8-9", "jhn3.16","1n5.13"]. */
    references?: NullableStringList;
    /** The overall workflow status of the day. Example: "published". */
    status: DayStatus;
    /** The day's published sort order position (in ascending order). */
  }

  export enum Status {
    APPROVED = 'approved',
    DRAFT = 'draft',
    PUBLISHED = 'published',
    REJECTED = 'rejected',
    SUBMITTED = 'submitted',
  }

  export enum ImageStatus {
    PROCESSED = 'processed',
  }

  export interface ImageAsset {
    original_url?: string;
    source_url?: string;
    thumb_url?: string;
  }

  export interface Image {
    asset: ImageAsset;
    created_at: Date;
    id: number;
    organization_id: NullableNumber;
    source_url: NullableString;
    status: ImageStatus;
    updated_at: Date;
    youtube_status_details: NullableString;
    youtube_video_id: number;
  }

  export interface NarratedAudioFeature {
    created_at: Date;
    id: number;
    metadata: string;
    submission_status: (typeof submissionStatuses)[keyof typeof submissionStatuses];
    updated_at: Date;
  }

  export interface PollyVoice {
    audio_preview_url: string;
    created_at: Date;
    id: number;
    language_id: number;
    object: string;
    title: string;
    updated_at: Date;
    yv_api_id: string;
  }

  export interface Plan {
    alternate_organizations: Array<Organization.SimpleOrganization>;
    attribution_text: NullableString;
    categories: Array<string>;
    created_at: Date;
    creator: User;
    description: NullableString;
    external_plan_id: NullableNumber;
    external_plan_url: NullableString;
    id: number;
    keywords: Array<string>;
    language_id: number;
    large_image: Attachment | null;
    last_edited_by: User | null;
    last_edited_by_org_user: User | null;
    launches_at: NullableDate;
    name: string;
    narrated_audio_feature: NarratedAudioFeature | null;
    overall_status: Status;
    partner_url: NullableString;
    polly_voice_id: null | number;
    primary_organization_id: number;
    primary_organization_name: NullableString;
    publishable: boolean;
    publishable_preflight_errors: Array<string>;
    small_image: Attachment | null;
    source_id: null | number;
    status: Status;
    submitted_at: NullableDate;
    system_status: boolean;
    title_slug: string;
    translations: null | NumberList;
    updated_at: Date;
  }

  export interface PlanMeta {
    id: number | string;
    isPublishable: boolean;
    name: string;
    primaryOrganizationId: number;
    status: Status;
  }

  export interface Comment {
    action?: string;
    author: User;
    body_html: string;
    body_json: Empty;
    created_at: Date;
    id: number;
    updated_at: Date;
  }

  export interface ApiCategory {
    created_at: Date;
    data: Array<Record<string, Array<string>>>;
    id: number;
    order: number;
    updated_at: Date;
  }

  export interface Category {
    id: NumberLike;
    name: string;
    sections: Array<string>;
    translatedName?: string;
  }

  export interface ExportPlan {
    created_at: Date;
    id: number;
    plan_id: number;
    updated_at: Date;
  }
}
