import { Box, Button, CircularProgress } from '@mui/material';
import { FC, MouseEventHandler, PropsWithChildren } from 'react';

interface Props {
  className?: string;
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  disabled?: boolean;
  isLoading?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  progressBarTestId?: string;
  size?: 'small' | 'medium' | 'large' | undefined;
  startIcon?: React.ReactNode;
  type?: 'button' | 'submit' | 'reset' | undefined;
  variant?: 'text' | 'outlined' | 'contained' | undefined;
}

const MuiLoadingButton: FC<PropsWithChildren<Props>> = ({
  color,
  children,
  className,
  disabled,
  isLoading = false,
  size,
  type,
  variant,
  onClick,
  progressBarTestId,
  startIcon,
}) => {
  return (
    <Box position='relative'>
      <Button
        className={className}
        color={color}
        disabled={disabled || isLoading}
        onClick={onClick}
        size={size}
        startIcon={startIcon}
        type={type}
        variant={variant}
      >
        {children}
        {isLoading ? (
          <Box alignContent='center' color='text.primary' display='flex' position='absolute'>
            <CircularProgress color='inherit' data-testid={progressBarTestId} size='1rem' />
          </Box>
        ) : null}
      </Button>
    </Box>
  );
};

export default MuiLoadingButton;
