/* eslint-disable import/prefer-default-export */
import { Switch } from '@mui/material';
import styles from 'components/Reports/GlobalReps/Pages/Styles.module.scss';
import React, { FC } from 'react';

import { Row } from '../row';
import { RowTotals } from '../row-totals';

interface Props {
  records: Array<PartnerRecord>;
}
export const PartnerRow: FC<Props> = ({ records }) => {
  const [isVisible, setIsVisible] = React.useState(true);

  const handleToggleVisible = () => {
    setIsVisible(prevState => !prevState);
  };
  const staffRepName = `${records[0].first_name} ${records[0].last_name}`;
  const totalPartners = records.length;

  const totalPlans = records.reduce(
    (item, { org_plans_count: orgPlansCount }) =>
      item + (typeof orgPlansCount === 'string' ? Number(orgPlansCount) : orgPlansCount),
    0
  );

  return (
    <tbody>
      <tr className={styles.staffRow}>
        <td colSpan={4}>
          <Switch
            checked={isVisible}
            color='secondary'
            inputProps={{
              'aria-label': `Toggle visibility for ${staffRepName}`,
            }}
            onClick={handleToggleVisible}
          />
          {staffRepName}
        </td>
      </tr>
      {isVisible ? (
        <React.Fragment>
          {records.map(record => {
            return <Row key={`staff_${record.id}_org_${record.org_id}`} record={record} />;
          })}
          <RowTotals totalPartners={totalPartners} totalPlans={totalPlans} />
        </React.Fragment>
      ) : null}
    </tbody>
  );
};
