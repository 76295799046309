/**
 * @module PlansRoutes
 */
// `path` should be the first prop in a Route so that routes are easy to maintain.
/* eslint-disable react/jsx-sort-props */
import Plans from 'containers/plans';
import { ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';

import PlanNew from './new';
import Plan from './plan';

/**
 * The PlansRoutes component.
 *
 * @alias module:PlansRoutes
 *
 * @returns {ReactElement} - The PlansRoutes component.
 */
export default function PlansRoutes() {
  return (
    <Routes>
      <Route index={true} element={<Plans />} />

      {/* 🐣 Nested routes only below this line. */}
      <Route path='new' element={<PlanNew />} />
      <Route path=':id/*' element={<Plan />} />
    </Routes>
  );
}
