/**
 * @module NoRowsOverlay
 */
import { Box } from '@mui/material';
import { EmptyState } from '@youversion/react';
import { ReactComponent as UnDrawSearching } from 'assets/undraw-searching.svg';
import { useTranslation } from 'react-i18next';

/**
 * Returns empty state.
 *
 * @returns {ReactElement} - Returns jsx.
 */
export function NoRowsOverlay() {
  const { t } = useTranslation('organizations');
  return (
    <Box alignItems='center' display='flex' height='100%' justifyContent='center' width='100%'>
      {/* @ts-expect-error - this component is running on an old react version */}
      <EmptyState
        headerImage={<UnDrawSearching height={100} />}
        headerText={t('single_org.membership_table.members_not_found')}
        subtitleText={t('single_org.membership_table.members_not_found_sub_text')}
      />
    </Box>
  );
}
