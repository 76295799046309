/* eslint-disable jsdoc/require-param */
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { red } from '@mui/material/colors';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { AsyncButton, useAlert } from '@youversion/react';
import { useDestroyGroupMemberships } from 'api/group_memberships';
import { API_STATUS } from 'helpers';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

interface DeleteGroupModalProps {
  /** Group membership data. */
  membership: GroupMembership;
  /** Tells if modal is rendered or not. */
  open: boolean;
  /** Function to refetch group membership data.  */
  refetch: () => void;
  /** Toggles the visibility of the modal. */
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const useStyles = makeStyles(() =>
  createStyles({
    submitButton: {
      backgroundColor: red[600],
      color: 'white',
      '&:hover': {
        backgroundColor: red[500],
      },
      marginInlineStart: '0.5rem',
    },
  })
);

/**
 * Delete group membership.
 *
 * @returns {ReactElement} - Returns jsx.
 */
export function DeleteGroupModal({ open, setOpen, refetch, membership }: DeleteGroupModalProps) {
  const classes = useStyles();
  const { throwAlert } = useAlert();
  const { mutateAsync, status } = useDestroyGroupMemberships();
  const { t } = useTranslation('users');

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    try {
      await mutateAsync(membership.id);
      refetch();
      setOpen(false);
    } catch (error) {
      if (error instanceof Error) {
        throwAlert({
          id: 'delete_membership_error',
          key: 'delete_membership_error',
          message: `Unable to delete group membership: ${error.message}`,
          timeout: 3000,
          type: 'error',
        });
      }
    }
  };

  const isLoading = status === API_STATUS.LOADING;

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>{t('groups.remove_membership')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('groups.delete_group')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color='primary' disabled={isLoading} onClick={handleClose}>
          {t('groups.no')}
        </Button>
        <AsyncButton
          className={classes.submitButton}
          color='primary'
          disabled={isLoading}
          idle={t('groups.yes')}
          onClick={handleDelete}
          options={{ disableUpdateOnSuccess: true }}
          pending={t('groups.deleting')}
          variant='contained'
        />
      </DialogActions>
    </Dialog>
  );
}
