import { CREATE, LIST, UPDATE } from './constants';

/* API URL Helpers - Generate API endpoint paths
 *
 */
const api = {
  resourceUrl: id => {
    return `/languages/${id}`;
  },
  collectionUrl: () => {
    return '/languages';
  },
};

/* CRUD
 *
 */

/**
 * Languages#list.
 *
 * GET a list of languages.
 *
 * @param {object} options - Options for the request (none yet).
 */

export function list(options = {}) {
  return async function (dispatch) {
    return dispatch({
      type: LIST,
      api: async client => {
        return client.get(api.collectionUrl(), null, { options });
      },
      meta: {
        ...options,
      },
    });
  };
}

/**
 * Languages#create.
 *
 * POST to create a Language.
 *
 * @param {object} attributes - Valid attributes for a group.
 * @param {string} attributes.name
 * @param {string} attributes.code
 */

export function create(attributes = {}) {
  return async function (dispatch) {
    return dispatch({
      type: CREATE,
      api: async client => {
        return client.post(api.collectionUrl(), null, {
          data: {
            language: attributes,
          },
        });
      },
      meta: {
        onSuccess: result => {
          return result.language;
        },
      },
    });
  };
}

/**
 * Languages#update.
 *
 * PATCH/PUT updates to a Language.
 *
 * @param {string} languageId - Id of language to update.
 * @param {object} attributes - Valid attributes for a language.
 * @param {string} attributes.name
 * @param {string} attributes.code
 */

export function update(languageId, attributes) {
  return async function (dispatch) {
    return dispatch({
      type: UPDATE,
      api: async client => {
        return client.patch(api.resourceUrl(languageId), null, {
          data: {
            language: attributes,
          },
        });
      },
      meta: {
        onSuccess: result => {
          return result.language;
        },
      },
    });
  };
}
