/* eslint-disable import/prefer-default-export */

import { UploadFileResponse } from 'api/active-storage';
import i18n from 'helpers/i18n';

type Error = { missingSignedId?: string };

/**
 * Validates a narrated audio object.
 *
 * @param {Props} narratedAudio - The narrated audio object.
 *
 * @returns {Array<Error>} - An array of errors.
 */
export function validateNarratedAudio(narratedAudio: Partial<UploadFileResponse>): Array<Error> {
  const { t } = i18n;
  const errors = [];

  if (narratedAudio && !narratedAudio.signedId) {
    errors.push({
      missingSignedId: t('plan_days:validation.missing_signed_id'),
    });
  }

  return errors;
}
