/**
 * @module Reducers
 */

import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import appReducer from './reducers/appReducer';
import authReducer from './reducers/authReducer';
import globalNotifications from './reducers/globalNotifications';
import languages from './reducers/languages';
import membershipsReducer from './reducers/organizationMembershipsReducer';
import organizationsReducer from './reducers/organizationsReducer';
import plansReducer from './reducers/plansReducer';
import userReducer from './reducers/users';

export type State = ReturnType<typeof reducer>;

const reducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  users: userReducer,
  plans: plansReducer,
  organizations: organizationsReducer,
  organizationMemberships: membershipsReducer,
  globalNotifications,
  form: formReducer,
  languages,
});

export default reducer;
