import { SwapHoriz } from '@mui/icons-material';
import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { gray } from '@youversion/react/styles/colors-v3';
import { TransferPlanModal } from 'components/Modals/TransferPlanModal';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  transferButton: {
    backgroundColor: gray[35],
    color: 'white',
    '&:hover': {
      backgroundColor: gray[30],
    },
    marginInlineStart: '0.5rem',
  },
}));

interface Props {
  // The plan id to transfer.
  planId: number;
}

export function TransferButton({ planId }: Props) {
  const classes = useStyles();
  const { t } = useTranslation(['plans']);
  const [isOpen, setOpen] = React.useState(false);

  return (
    <>
      <Button
        className={classes.transferButton}
        onClick={() => setOpen(!isOpen)}
        startIcon={<SwapHoriz />}
        variant='contained'
      >
        {t('plans:transfer_plan.transfer')}
      </Button>
      <TransferPlanModal isOpen={isOpen} planId={planId} setOpen={setOpen} />
    </>
  );
}
