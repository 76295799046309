import { useAuth } from 'auth';
import LoaderOverlay from 'components/LoaderOverlay';
import PropTypes from 'prop-types';
import React, { FC } from 'react';

interface Props {
  children: React.ReactNode;
}

const Authorize: FC<Props> = ({ children }) => {
  const { user, isLoading } = useAuth();
  const isAdmin = user ? user.role === 'admin' : false;

  if (isLoading) {
    return <LoaderOverlay />;
  }
  /*
          Returning null here to allow for rendering a blank page, not redirecting.
          Decision was made to not redirect to another url in the case of failed
          authorization, rather maintain proper url requests and display content
          appropriately.
      
          We can replace null here with any component or markup we want to render
          when the user isn't authorized (an Admin)
        */
  if (!isLoading && !isAdmin) {
    return null;
  }
  return <React.Fragment>{children}</React.Fragment>;
};

Authorize.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Authorize;
