import moment from 'moment';

type TimeUnit = 'days' | 'hours' | 'minutes' | 'seconds';

/**
 * Calculates a the time between a timestamp (converted to UTC) and the current local time.
 *
 * The timestamp to be converted to UTC and compared to the current time.
 *
 * @param timestamp
 *
 * @example
 * ```typescript
 * const expirationString = useLocalizedPostExpirationString('2024-06-14T13:45:18.549Z);
 * ```
 *
 * Returns a string value like "3 days ago".
 *
 * @returns String.
 */
const getLocalizedPastDateString = (timestamp: Date): { count: number; timeUnit: TimeUnit } => {
  const timestampUTC = moment.utc(timestamp);
  const today = new Date();

  const timeDiffHour = timestampUTC.diff(today, 'hours');
  const timeDiffDay = timestampUTC.diff(today, 'day');
  const timeDiffMinute = timestampUTC.diff(today, 'minute');

  let timeUnit: TimeUnit;
  if (timeDiffDay !== 0) {
    timeUnit = 'days';
  } else if (timeDiffHour !== 0) {
    timeUnit = 'hours';
  } else if (timeDiffMinute !== 0) {
    timeUnit = 'minutes';
  } else {
    timeUnit = 'seconds';
  }
  const timeDifference = timestampUTC.diff(today, timeUnit);

  return {
    timeUnit,
    count: Math.abs(timeDifference),
  };
};

export default getLocalizedPastDateString;
