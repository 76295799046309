/* eslint-disable import/prefer-default-export */
/**
 * @module ExportPlanButton
 */
import CloudUpload from '@mui/icons-material/CloudUpload';
import { Button, CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useQueryClient } from '@tanstack/react-query';
import { RoundedButton } from '@youversion/mui-block-editor/components/ui/buttons/rounded-button';
import { AsyncButton, useAlert } from '@youversion/react';
import { gray, utilityColors } from '@youversion/react/styles/colors-v3';
import { useExportPlan } from 'api/export-plan';
import { API_STATUS } from 'helpers';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  buttonProgress: {
    backgroundColor: gray[35],
    color: 'white',
    marginInlineStart: '0.5rem',
    '&:hover': {
      backgroundColor: gray[35],
    },
  },
  exportButton: {
    backgroundColor: gray[35],
    color: 'white',
    '&:hover': {
      backgroundColor: gray[30],
    },
    marginInlineStart: '0.5rem',
  },
  errorButton: {
    color: '#fff',
    backgroundColor: utilityColors.light.alert,
    '&:hover': {
      backgroundColor: utilityColors.light.alert,
    },
    marginInlineStart: '0.5rem',
  },
}));

interface Props {
  // The plan id to export.
  planId: number;
  publishable: boolean;
}

export const ExportPlanButton: React.FC<Props> = ({ planId, publishable }) => {
  const { t } = useTranslation(['plans', 'common']);
  const classes = useStyles();
  const queryClient = useQueryClient();
  const { throwAlert } = useAlert();
  const { mutate: exportPlan, status: exportPlanLoadingStatus } = useExportPlan();

  async function handleExportPlan() {
    exportPlan(
      { id: planId },
      {
        onError: error => {
          if (error instanceof Error) {
            throwAlert({
              id: 'export_plan_error',
              key: 'export_plan_error',
              message: t('plans:export_plan.error_message', {
                message: error.message,
              }),
              timeout: 5000,
              type: 'error',
            });
          }
        },
        onSuccess: response => {
          if (response) {
            queryClient.invalidateQueries(['export-plan', response.id]);
            throwAlert({
              id: 'export_plan_success',
              key: 'export_plan_success',
              message: t('plans:export_plan.success_message'),
              timeout: 5000,
              type: 'success',
            });
          }
        },
      }
    );
  }

  let statusText = t('plans:export_plan.title');
  if (exportPlanLoadingStatus === API_STATUS.SUCCESS) {
    statusText = t('common:success');
  } else if (exportPlanLoadingStatus === API_STATUS.ERROR) {
    statusText = t('common:error_try_again');
  }

  // eslint-disable-next-line react/no-multi-comp
  const ButtonComponent = React.forwardRef<HTMLButtonElement>((_, ref) => {
    if (exportPlanLoadingStatus === API_STATUS.LOADING) {
      return (
        <Button className={classes.buttonProgress} variant='contained'>
          {t('plans:export_plan.exporting')}
          <CircularProgress color='secondary' size={24} />
        </Button>
      );
    }
    if (exportPlanLoadingStatus === API_STATUS.ERROR) {
      return (
        <Button classes={{ root: classes.errorButton }} onClick={handleExportPlan} ref={ref} variant='contained'>
          {statusText}
        </Button>
      );
    }
    return (
      <AsyncButton
        className={classes.exportButton}
        disabled={!publishable}
        idle={statusText}
        onClick={() => handleExportPlan()}
        options={{
          disableUpdateOnSuccess: true,
        }}
        pending={t('plans:export_plan.exporting')}
        rejected={t('plans:export_plan.error_exporting')}
        resolved={t('plans:export_plan.plan_exported')}
        startIcon={<CloudUpload />}
        variant='contained'
      >
        {statusText}
      </AsyncButton>
    );
  });

  ButtonComponent.displayName = RoundedButton.name;

  return <ButtonComponent />;
};
