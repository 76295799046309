/* eslint-disable no-async-promise-executor */
/* eslint-disable jsdoc/require-param */
import { API_ADDRESS, PLAN_PORTAL_API_URL } from '../constants';
import { getToken } from '../get-token';

/**
 * Fetch wrapper to treat 4xx - 5xx status codes as errors.
 * We should be able to access those error messages in our catch block!
 *
 * @returns {Promise} - Returns promise.
 */
function transport(input: RequestInfo, init?: RequestInit): Promise<Response> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(input, init);
      if (!response.ok) {
        reject(response);
      }
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

interface FetchClientConfig extends RequestInit {
  /**
   * If not set, defaults to the parpor api address {API_ADDRESS}.
   */
  baseUrl?: string;
  /**
   * Most of our requests will be made with a token.
   * So to make sure we don't set this everytime(ux reasons), I'm rather
   * checking for unauthorized requests.
   *
   * @example - If request is an unauthorized request, we don't append the Authorization header.
   * fetchClient('path-here', {isUnAuthorized:true})
   *
   * @example If request needs authorization, we append the Authorization header here.
   * fetchClient('path-here')
   *
   * @example If request needs authorization, but needs pass in the authorization header, jwt.
   * fetchClient('path-here', {jwt: 'tokenHere'})
   */
  isUnAuthorizedRequest?: boolean;
  jwt?: string;
  noBaseUrl?: boolean;
}

export interface HttpResponse<T> extends Response {
  parsedBody: T;
}

/**
 * Fetch client returns actual data/error.
 *
 * @returns {Promise} - Returns promise.
 */
export function fetchClient<T>(path: string, config?: FetchClientConfig): Promise<HttpResponse<T>> {
  return new Promise(async (resolve, reject) => {
    const baseUrl = config?.baseUrl ?? API_ADDRESS; // Defaults to PARPOR's api address.

    const headers = new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...config?.headers,
    });

    try {
      if (!config?.isUnAuthorizedRequest && !config?.jwt) {
        const authToken = await getToken();

        if (baseUrl === PLAN_PORTAL_API_URL) {
          headers.append('X-PartnerPortal-Token', authToken);
        } else {
          headers.append('Authorization', `Bearer ${authToken}`);
        }
      }

      if (config?.jwt) {
        if (baseUrl === PLAN_PORTAL_API_URL) {
          headers.append('X-PartnerPortal-Token', config?.jwt);
        } else {
          headers.append('Authorization', `Bearer ${config?.jwt}`);
        }
      }

      let response = null;
      if (config?.noBaseUrl) {
        response = await transport(path, {
          ...config,
          headers,
        });
      } else {
        response = await transport(`${baseUrl}${path}`, {
          ...config,
          headers,
        });
      }

      const contentType = response.headers.get('Content-Type');

      let parsedBody;
      if (contentType && contentType.indexOf('json') > -1) {
        parsedBody = await response.json();
      } else {
        parsedBody = await response.text();
      }

      const res: HttpResponse<T> = {
        ...response,
        parsedBody,
      };

      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
}
