/* eslint-disable @typescript-eslint/no-unused-vars */
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useGetOrganization, useGetOrganizations } from 'api/organizations';
import { useGetPlan } from 'api/plans';
import { TransferPlanButton } from 'components/Plans/Buttons/TransferPlanButton';
import { API_STATUS, ORGS_PER_PAGE } from 'helpers';
import { debounce } from 'lodash';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { OrganizationResults } from './OrganizationResults';

const useStyles = makeStyles(() =>
  createStyles({
    cancelButton: {
      marginInlineEnd: '1rem',
      marginBlockEnd: '1rem',
    },
    continueButton: {
      marginInlineEnd: '.5rem',
      marginBlockEnd: '1rem',
    },
    dialogPadding: {
      paddingInlineStart: '1rem',
      paddingInlineEnd: '1rem',
    },
    dialogSize: {
      width: '510px',
    },
    transferStyle: {
      paddingInlineStart: '1rem',
      paddingInlineEnd: '1rem',
      paddingBlockStart: 0,
    },
  })
);

export interface Props {
  isOpen: boolean;
  planId: number;
  setOpen: (isOpen: boolean) => void;
}

export function TransferPlanModal({ isOpen, setOpen, planId }: Props) {
  const classes = useStyles();
  const { t } = useTranslation(['plans', 'common']);
  const { data: planToTransfer } = useGetPlan(planId);

  const [searchValue, setSearchValue] = React.useState('');
  const [selectedOrganization, setSelectedOrganization] = React.useState<Organization | null>(null);
  const [pageSelected, setPageSelected] = React.useState<number>(1);

  const { data, status } = useGetOrganizations({
    pagination: { per: ORGS_PER_PAGE, page: 1 },
    sorter: { sort: 'created_at', order: 'desc' },
    filters: { name: searchValue },
  });

  const changeSearchValue = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchValue(event.target.value);
    if (!searchValue || !data?.organizations.length) {
      setSelectedOrganization(null);
    }
  };
  const handleClose = () => {
    setOpen(!isOpen);
    setSelectedOrganization(null);
    setSearchValue('');
    setPageSelected(1);
  };
  const debouncedHandler = debounce(changeSearchValue, 1000);
  const isLoading = status === API_STATUS.LOADING;

  return (
    <Dialog classes={{ paper: classes.dialogSize }} open={isOpen}>
      <DialogTitle classes={{ root: classes.dialogPadding }}>
        <Typography variant='h2'>{t('plans:transfer_plan.title')}</Typography>
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogPadding }}>
        {pageSelected === 1 || !searchValue ? (
          <Typography color='textSecondary' variant='caption'>
            {t('common:step_of_total', {
              step: 1,
              total: 2,
            })}
          </Typography>
        ) : (
          <Typography color='textSecondary' variant='caption'>
            {t('common:step_of_total', {
              step: 2,
              total: 2,
            })}
          </Typography>
        )}
        {pageSelected === 1 && (
          <>
            <Box mb={2}>
              <Typography color='textPrimary' variant='body1'>
                {t('plans:transfer_plan.search_org')}
              </Typography>
            </Box>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              fullWidth={true}
              label={t('common:search')}
              name='search'
              onChange={debouncedHandler}
              placeholder={t('plans:transfer_plan.search_plan_title_or_id')}
              variant='outlined'
            />
          </>
        )}
        {pageSelected === 2 && searchValue ? (
          <Typography color='primary' variant='body1'>
            <Trans
              components={{
                strong: <strong />,
              }}
              i18nKey='plans:transfer_plan.content'
              t={t}
              values={{
                planToTransferName: planToTransfer?.name,
                planToTransferPrimaryOrganizationName: planToTransfer?.primary_organization_name,
                selectedOrganizationName: selectedOrganization?.name,
              }}
            />
          </Typography>
        ) : null}
      </DialogContent>
      {searchValue && isLoading ? (
        <DialogContent>
          <Box my={1}>
            <Skeleton animation='wave' height={6} variant='rectangular' />
          </Box>
        </DialogContent>
      ) : null}
      {!data?.organizations.length && !isLoading && (
        <DialogContent classes={{ root: classes.dialogPadding }}>
          <Typography color='textSecondary' variant='body1'>
            {t('plans:transfer_plan.no_org_found')}
          </Typography>
        </DialogContent>
      )}
      {pageSelected === 1 && searchValue && !isLoading && data?.organizations.length !== 0 ? (
        <OrganizationResults
          onChange={setSelectedOrganization}
          organizationsList={data?.organizations || []}
          selectedOrganization={selectedOrganization}
        />
      ) : null}
      <DialogActions>
        <Button className={classes.cancelButton} name='Cancel' onClick={handleClose}>
          {t('common:cancel')}
        </Button>
        {pageSelected === 2 && searchValue ? (
          <TransferPlanButton
            isOpen={isOpen}
            planId={planId}
            setOpen={setOpen}
            transferOrganization={selectedOrganization}
          />
        ) : (
          <Button
            className={classes.continueButton}
            disabled={selectedOrganization === null || !searchValue}
            name='Continue'
            onClick={() => setPageSelected(2)}
            variant='contained'
          >
            {t('common:continue')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
