/* eslint-disable jsdoc/require-param */
/**
 * @module PlansLandingPage
 */
import { GetPlansResponse } from 'api/plans';
import { Filters } from 'components/Plans/Filtering/Filters';
import { PlanDataGrid } from 'components/Plans/plan-data-grid';
import React, { FC } from 'react';

interface Props {
  data?: GetPlansResponse;
  loadingStatus: string;
  organizationId: string;
}

/**
 * The plans landing page.
 *
 * @returns {ReactElement} - The Plans Landing Page component.
 */
const LandingPage: FC<Props> = ({ data, organizationId, loadingStatus }: Props) => {
  return (
    <>
      <Filters />
      <PlanDataGrid data={data} loadingStatus={loadingStatus} organizationId={organizationId} />
    </>
  );
};

export default LandingPage;
