import i18n from 'helpers/i18n';

/**
 * Takes the language tag of a given language and returns a localized name.
 *
 * @param languageTag
 * @param userLanguage
 *
 * @example
 * ```typescript
 * const language = getLocalizedLanguage('en', i18n.language);
 * ```
 *
 * @returns String.
 */
export function getLocalizedLanguage(languageTag: string, userLanguage: string) {
  const localeDisplayName = new Intl.DisplayNames([userLanguage], {
    type: 'language',
  });
  const language = localeDisplayName.of(languageTag.replace('_', '-'));
  return language === languageTag ? i18n.t(`uncommon_languages:${languageTag}`) : language;
}
