// eslint-disable-next-line no-unused-vars
import PropTypes from 'prop-types';
import React, { FC } from 'react';

import styles from './Divider.module.scss';

interface Props {
  className?: string | null;
}

const Divider: FC<Props> = ({ className }) => {
  return <hr className={className ? `${className} ${styles.divider}` : styles.divider} />;
};

Divider.propTypes = {
  className: PropTypes.string,
};

Divider.defaultProps = {
  className: null,
};
export default Divider;
