/* eslint-disable react/jsx-props-no-spreading */
/**
 * @module TranslationComboBox
 */
import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { surface } from '@youversion/react/styles/colors-v3';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getLocalizedLanguage } from 'utils/get-localized-language/getLocalizedLanguage';

import PaperWrapperComponent from '../PaperWrapper';
import { TranslationIcon } from '../TranslationIcon';
import { TranslationOption } from '../TranslationOption';
import { TranslationPickerProps } from '../types';

const useStyles = makeStyles(theme => ({
  autocomplete: {
    maxWidth: 220,
  },
  comboBox: {
    borderRadius: 99,
  },
  comboBoxEndAdornment: {
    paddingInlineEnd: theme.spacing(1),
  },
  listBoxShape: {
    maxHeight: 200,
    borderRadius: '8px',
    overflow: 'scroll',
  },
}));

interface Props {
  disableNewLanguageButton: boolean;
  handleToggleSort: () => void;
  isOrgPlanListGreaterThanOne: boolean;
  isSortedAlphabetically: boolean;
  selectedTranslation: TranslationPickerProps | null;
  setSelectedTranslation: React.Dispatch<React.SetStateAction<TranslationPickerProps | null>>;
  translations: Array<TranslationPickerProps>;
}

export default function TranslationComboBox({
  isSortedAlphabetically,
  handleToggleSort,
  selectedTranslation,
  setSelectedTranslation,
  translations = [],
  disableNewLanguageButton,
  isOrgPlanListGreaterThanOne,
}: Props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation('plans');
  const [openPopUp, setOpenPopUp] = React.useState(false);

  function handleLanguageSource(languageName: string, languageTag: string) {
    return languageName.includes(
      t('plans:language_source', {
        lang: getLocalizedLanguage(languageTag, i18n.language),
      })
    )
      ? languageName
      : getLocalizedLanguage(languageTag ?? 'en', i18n.language);
  }

  return (
    <Autocomplete
      PaperComponent={props => {
        return (
          <PaperWrapperComponent
            {...props}
            disableNewLanguageButton={disableNewLanguageButton}
            handleToggleSort={handleToggleSort}
            isOrgPlanListGreaterThanOne={isOrgPlanListGreaterThanOne}
            isSortedAlphabetically={isSortedAlphabetically}
          />
        );
      }}
      className={classes.autocomplete}
      classes={{
        endAdornment: classes.comboBoxEndAdornment,
        listbox: classes.listBoxShape,
      }}
      clearOnEscape={true}
      getOptionLabel={translation =>
        handleLanguageSource(translation.languageName, translation.languageTag ?? 'en') || translation.languageName
      } // Doesn't change the inputValue! ONLY the label.
      groupBy={translation => (isSortedAlphabetically ? translation.languageName : translation.status)}
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onChange={(event, value, reason) => {
        // If the user clicks on the clear button or clears out the input field completely; This sets the value to Null.
        // If we want to just show the initial value. We will setSelectedTranslation to the initial value using selectedTranslation.
        if (reason === 'clear') {
          setSelectedTranslation(selectedTranslation);
        }
        // Here, if the reason is that  user makes a selection from the drop-down options, we want to setSelectedTranslation()
        if (reason === 'selectOption') {
          setSelectedTranslation(value);

          if (value?.languageName === selectedTranslation?.languageName) {
            event.preventDefault();
          } else {
            navigate(`/plans/${value?.id}`);
          }
        }
      }}
      onClose={(e, reason) => {
        if (reason !== 'blur') {
          setOpenPopUp(false);
        }
      }}
      onOpen={() => setOpenPopUp(true)}
      open={openPopUp}
      options={translations}
      renderGroup={({ group: status, children }) => (
        <Box key={status}>
          {!isSortedAlphabetically ? (
            <Box bgcolor={surface.light.secondary} px={1} py={0.25}>
              <Typography color='textPrimary' variant='caption'>
                {status.toUpperCase()}
              </Typography>
            </Box>
          ) : null}
          {children}
        </Box>
      )}
      renderInput={params => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            className: classes.comboBox,
            startAdornment: (
              <Box alignItems='center' display='flex' pr={1}>
                <TranslationIcon status={selectedTranslation?.status || ''} />
              </Box>
            ),
          }}
          label={t('plans:translation')}
          size='small'
          variant='outlined'
        />
      )}
      renderOption={(props, translation) => <TranslationOption optionProps={props} {...translation} />}
      value={selectedTranslation}
    />
  );
}
