/* eslint-disable jsdoc/require-param */
import { MenuItem, TextField } from '@mui/material';
import { grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import { useGetLanguages } from 'api/languages';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { getLocalizedLanguage } from 'utils/get-localized-language/getLocalizedLanguage';

export const DEFAULT_LANGUAGE_SELECTED = 'all-languages';

const useStyles = makeStyles(theme => ({
  inputForName: {
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
    backgroundColor: grey['100'],
    borderRadius: theme.spacing(5),
    padding: `0px ${theme.spacing(1)}`,
  },
  noBorder: {
    border: 'none',
  },
}));

/**
 * Input Component for filtering by plan names.
 *
 * @returns {ReactElement} - Returns an input component.
 */
export function LanguageField() {
  const classes = useStyles();
  const { data: languages } = useGetLanguages();
  const [query, setQuery] = useSearchParams();
  const { t, i18n } = useTranslation('plans');

  return (
    <TextField
      InputProps={{
        classes: { notchedOutline: classes.noBorder },
        className: classes.inputForName,
      }}
      fullWidth={true}
      onChange={e => {
        if (e.target.value === DEFAULT_LANGUAGE_SELECTED) {
          query.delete('language');
        } else {
          query.set('language', e.target.value);
        }
        setQuery(query);
      }}
      select={true}
      type='text'
      value={languages ? query.get('language') || DEFAULT_LANGUAGE_SELECTED : DEFAULT_LANGUAGE_SELECTED}
      variant='outlined'
    >
      <MenuItem value={DEFAULT_LANGUAGE_SELECTED}>{t('label.all_languages')}</MenuItem>
      {languages
        ? Object.values(languages)?.map(lang => (
            <MenuItem key={lang.id} value={lang.code}>
              {getLocalizedLanguage(lang.code, i18n.language)}
            </MenuItem>
          ))
        : null}
    </TextField>
  );
}
