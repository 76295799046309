/**
 * @module NewDay
 */
import SaveIcon from '@mui/icons-material/Save';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { Box, Button, CircularProgress, Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import FileDropArea from '@youversion/mui-file-droparea';
import { RoundedButton, useAlert } from '@youversion/react';
import { utilityColors } from '@youversion/react/styles/colors-v3';
import { statusTypes } from '@youversion/utils';
import { uploadFile, UploadFileResponse, useAttachFile } from 'api/active-storage';
import { PlanDayDataProps, useCreatePlanDay } from 'api/plans';
import BibleReferences from 'components/bible/bible-references';
import { usePlan } from 'context';
import { API_STATUS, QUERY_PARAMS } from 'helpers/constants';
import { validateBlocks, validateNarratedAudio, validateReferences } from 'helpers/content-blocks/validation';
import { planHasNarratedAudio } from 'helpers/plan-has-narrated-audio';
import { Transition } from 'history';
import { useBlocker } from 'hooks/use-blocker';
import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import DevoContentWrapper from '../devotional-content-wrapper';
import { Plan } from '../types';

const useStyles = makeStyles(theme => ({
  iconProgress: {
    insetInlineStart: '50%',
    marginInlineStart: -12,
    marginBlockStart: -12,
    position: 'absolute',
    insetBlockStart: '50%',
  },
  iconSpinner: {
    position: 'relative',
  },
  paper: {
    borderRadius: theme.spacing(2),
    padding: theme.spacing(2),
  },
  required: {
    color: utilityColors.light.alert,
  },
  volumeUpIcon: {
    verticalAlign: 'text-top',
  },
}));

interface Props {
  /** If true, it will use the position from this local scope instead of the plan context. */
  isDayInBetween?: boolean;
  /** The plan's data. */
  plan: Plan.Plan;
}

// eslint-disable-next-line jsdoc/require-param
/**
 * The NewDay component.
 *
 * @alias module:NewDay
 *
 * @returns {React.ReactElement} - The NewDay component.
 */
export function NewDay({ isDayInBetween = false, plan }: Props) {
  const { t } = useTranslation(['plans', 'common', 'plan_days']);
  const classes = useStyles();
  const { throwAlert } = useAlert();
  const navigate = useNavigate();
  const { dayNumber, dayPosition, planDays, setPlanDays, refreshPlanData } = usePlan();

  const { mutateAsync: attachNarratedAudio } = useAttachFile();
  const { mutateAsync: createPlanDay, status: savingStatus } = useCreatePlanDay();

  const planId = plan.id;
  const planMeta: Plan.PlanMeta = useMemo(
    () => ({
      id: planId,
      isPublishable: false,
      name: plan.name || '',
      primaryOrganizationId: plan.primary_organization_id,
      status: plan.overall_status,
    }),
    [plan, planId]
  );

  const hasNarratedAudio = planHasNarratedAudio(plan);

  const [blocks, setBlocks] = React.useState<Array<Plan.DevotionalContentBlocks>>([]);
  const [bibleReferences, setBibleReferences] = React.useState<Array<string>>([]);
  const [narratedAudioBlob, setNarratedAudioBlob] = React.useState<UploadFileResponse>();
  const [saveDayClicked, setSaveDayClicked] = React.useState(false);

  const position = useMemo(() => {
    return (planDays[planDays.length - 1]?.position ?? 0) + 100;
  }, [planDays]);

  // This code for `blocker` is mostly copy paste from
  // https://gist.github.com/rmorse/426ffcc579922a82749934826fa9f743#file-react-router-dom-v-6-02-prompt-blocker-js-L46

  /**
   * Uses the browser's confirmation before navigating away from the current page.
   * Note: This function is derived largely from the `usePrompt` blocker referenced below.
   *
   * @see {@link https://gist.github.com/rmorse/426ffcc579922a82749934826fa9f743#file-react-router-dom-v-6-02-prompt-blocker-js-L46}.
   */
  const blocker = React.useCallback(
    (param: Transition) => {
      if (
        // eslint-disable-next-line no-alert
        window.confirm(t('plans:edit_plan_info.new.window_blocker_message'))
      ) {
        param.retry();
        if (isDayInBetween) {
          const filteredPlanDays = planDays.filter(day => day.id !== 'newDay');
          setPlanDays(filteredPlanDays);
          refreshPlanData();
        }
        setSaveDayClicked(true);
      }
    },
    [isDayInBetween, planDays, refreshPlanData, setPlanDays, t]
  );
  // Window prompt for when user navigates away from new day without saving.
  useBlocker(blocker, !saveDayClicked);

  async function handleSave() {
    setSaveDayClicked(true);

    const contentBlockErrors = validateBlocks(blocks);
    const narratedAudioErrors = narratedAudioBlob ? validateNarratedAudio(narratedAudioBlob) : [];
    const bibleReferenceErrors = validateReferences(bibleReferences);

    if (bibleReferenceErrors.length || contentBlockErrors.length || narratedAudioErrors.length) {
      bibleReferenceErrors.forEach(error => {
        throwAlert({
          id: Object.keys(error)[0],
          key: Object.keys(error)[0],
          message: Object.values(error)[0],
          timeout: 5000,
          type: statusTypes.ERROR,
        });
      });

      contentBlockErrors.forEach(error => {
        throwAlert({
          id: error.blockId,
          key: error.blockId,
          message: (
            <>
              {t('plans:day.devo_content')}:
              <ul>
                {Object.keys(error.blockErrors).map(key => {
                  return <li key={key}>{error.blockErrors[key as keyof typeof error.blockErrors]}</li>;
                })}
              </ul>
            </>
          ),
          timeout: 5000,
          type: statusTypes.ERROR,
        });
      });

      narratedAudioErrors.forEach(error => {
        throwAlert({
          id: Object.keys(error)[0],
          key: Object.keys(error)[0],
          message: Object.values(error)[0],
          timeout: 5000,
          type: statusTypes.ERROR,
        });
      });

      return;
    }

    try {
      const data: PlanDayDataProps = {
        position: isDayInBetween ? dayPosition : position,
        references: bibleReferences,
      };
      // Blocks are optional, so only send them along if they exist.
      if (blocks.length) {
        data.devotional_content_blocks = blocks;
      }

      const planDay = await createPlanDay({ data, planId });

      if (planDay) {
        setPlanDays([planDay, ...planDays]);

        if (hasNarratedAudio && narratedAudioBlob?.signedId) {
          await attachNarratedAudio({
            fileType: 'narrated_audio',
            dayId: Number(planDay.id),
            organizationId: planMeta.primaryOrganizationId,
            signedId: narratedAudioBlob.signedId,
          });
        }
        navigate(`/plans/${planId}/days/${planDay.id}`);
        refreshPlanData();
      }
    } catch (error) {
      if (error instanceof Error) {
        throwAlert({
          id: 'plan_day_save_error',
          key: 'plan_day_save_error',
          message: t('plans:edit_plan_info.new.error_saving_day_message', {
            message: error.message,
          }),
          timeout: 5000,
          type: statusTypes.ERROR,
        });
      }
    }
  }

  async function handleUploadNarratedAudio(files: Array<File>) {
    const response = await uploadFile(files[0]);
    setNarratedAudioBlob(response);
  }

  function handleCancelButton() {
    navigate(-1);
  }

  return (
    <Paper className={classes.paper} elevation={0} id='new-day'>
      <Box display='flex' justifyContent='space-between' mb={2}>
        <Typography variant='h2'>
          {isDayInBetween ? t('plans:day.day_number', { number: dayNumber }) : t('plans:new_day')}
        </Typography>
        <Box display='flex'>
          <Box>
            <Button
              color='primary'
              disabled={Boolean(savingStatus === API_STATUS.LOADING || !bibleReferences?.length)}
              onClick={handleSave}
              size='large'
              startIcon={
                savingStatus === API_STATUS.LOADING ? (
                  <span className={classes.iconSpinner}>
                    <CircularProgress className={classes.iconProgress} size={24} />
                    <SaveIcon />
                  </span>
                ) : (
                  <SaveIcon />
                )
              }
              variant='contained'
            >
              {savingStatus === API_STATUS.IDLE ? t('common:save') : null}
              {savingStatus === API_STATUS.LOADING ? t('common:saving') : null}
              {savingStatus === API_STATUS.ERROR ? t('common:error_try_again') : null}
            </Button>
          </Box>
          <Box ml={1}>
            <Button color='secondary' onClick={handleCancelButton} size='large' variant='contained'>
              {t('common:cancel')}
            </Button>
          </Box>
        </Box>
      </Box>

      {!hasNarratedAudio ? (
        <Box mb={2}>
          <Typography variant='subtitle1'>
            <Trans
              components={{
                a: (
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  <a href={`/plans/${planId}/edit?${QUERY_PARAMS.uploadAudio}`} />
                ),
              }}
              i18nKey='plan_days:plan_day_edit.automated_audio_notice'
              t={t}
            />
          </Typography>
        </Box>
      ) : null}

      <Box mb={4}>
        <Typography gutterBottom={true} variant='h3'>
          <Trans
            components={{
              span: <span className={classes.required} />,
            }}
            i18nKey='plan_days:plan_day_edit.bible_reference.title'
            t={t}
          />
        </Typography>
        {!bibleReferences.length ? (
          <Typography variant='subtitle1'>
            {t('plan_days:plan_day_edit.bible_reference.no_bible_references')}
          </Typography>
        ) : null}
        <BibleReferences onChange={references => setBibleReferences(references)} usfmList={bibleReferences} />
      </Box>

      {hasNarratedAudio ? (
        <Box mb={4}>
          <Typography variant='h3'>
            <Box alignItems='center' display='flex'>
              <Box>{t('plan_days:plan_day_edit.narrated_audio.title')}</Box>
              <Box ml={0.5}>
                <VolumeUpIcon className={classes.volumeUpIcon} fontSize='inherit' />
              </Box>
            </Box>
          </Typography>
          <Typography variant='subtitle1'>{t('plans:edit_plan_info.new.narrated_audio_formerly_known_as')}</Typography>
          <FileDropArea
            acceptedFileTypes='audio/*'
            // RoundedButton is running on an old react version.
            button={RoundedButton as any}
            onDrop={handleUploadNarratedAudio}
            uploadedDescription={t('plans:edit_plan_info.new.narrated_audio_uploaded_description')}
          />
        </Box>
      ) : null}

      <DevoContentWrapper blocks={blocks} onChange={updatedBlocks => setBlocks(updatedBlocks)} planMeta={planMeta} />
    </Paper>
  );
}
