import { Avatar, Box, Drawer, Typography } from '@mui/material';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import partnerPortalLogo from '../../assets/yv-pp-logo.svg';
import { State } from '../../state/reducers';
import { logout } from '../../state/reducers/authReducer';
import { ownAvatarUrlSelector, userSelector } from '../../state/selectors';

interface Props {
  avatarUrl?: string;
  errors: Array<{ key: string; message: string }>;
  signOut: Function;
  userFullName: string;
}

function Unauthorized({ avatarUrl, userFullName, signOut, errors }: Props) {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  const hasEmailError =
    errors.filter(e => ['missing_yv_user_id', 'missing_yv_connect_account'].includes(e.key)).length > 0;

  const hasLicenseAgreementError = errors.filter(e => e.key === 'invalid_license_status').length > 0;

  return (
    <div>
      <Drawer anchor='left' sx={{ width: 240 }} variant='permanent'>
        <Box p={2} pb={1} pt={3}>
          <img alt='partner portal logo' height='48px' src={partnerPortalLogo} />
        </Box>
        <Box
          alignItems='center'
          data-testid='user-info'
          display='flex'
          marginBottom={2}
          marginTop='auto'
          p={2}
          pb={1}
          width='100%'
        >
          <Box mr={1}>
            <Avatar src={avatarUrl} />
          </Box>
          <Box marginX='0.5rem' width='calc(100% - 48px)'>
            <Typography display='block' noWrap={true} variant='body2'>
              {userFullName}
            </Typography>
            <Typography
              onClick={() => signOut().then(navigate('/'))}
              sx={{
                fontWeight: 'bold',
                cursor: 'pointer',
                textDecoration: 'none',
              }}
              variant='caption'
            >
              {t('menu:sign_out')}
            </Typography>
          </Box>
        </Box>
      </Drawer>
      <Box
        sx={{
          marginInlineStart: '240px',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            maxWidth: '760px',
            textAlign: 'center',
          }}
        >
          <Typography variant='h1'>{t('unable_to_access')}</Typography>
          <Typography marginBlockEnd={4} variant='h2'>
            {t('officially_migrated')}
          </Typography>
          {hasEmailError ? (
            <Typography marginBlockEnd={4} variant='body1'>
              <Trans
                components={{
                  a1: <a href='mailto:partnerships@youversion.com' />,
                }}
                i18nKey='email_access_error'
                t={t}
              />
            </Typography>
          ) : null}
          {hasLicenseAgreementError ? (
            <Typography variant='body1'>
              <Trans
                components={{
                  a1: <a href='mailto:partnerships@youversion.com' />,
                }}
                i18nKey='license_agreement_error'
                t={t}
              />
            </Typography>
          ) : null}
        </Box>
      </Box>
    </div>
  );
}

export default connect(
  (state: State) => ({
    avatarUrl: ownAvatarUrlSelector(state),
    userFullName: userSelector(state)
      ? `${userSelector(state).first_name} ${userSelector(state).last_name}`
      : 'Loading…',
  }),
  (dispatch: any) => ({
    signOut: () => dispatch(logout()),
  })
)(Unauthorized);
