/* eslint-disable jsdoc/require-param */
/* eslint-disable react-hooks/exhaustive-deps */
/**
 * @module AlternateOrganizations
 */
import { searchOrganizations } from 'api/organizations';
import { Organization } from 'components/Organizations/types';
import { useStateIfMounted } from 'hooks/use-state-if-mounted';
import PropTypes from 'prop-types';
import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AsyncSelect from 'react-select/async';
import { NumberLike } from 'types/misc';

interface SelectValue extends Organization.SimpleOrganization {
  label: string;
  value: NumberLike;
}

interface AlternateOrganizationsProps {
  /** Updates alternate organization key in plan object. */
  onChange: (value: Array<SelectValue>) => void;
  /** The organizations selected. */
  value: Array<Organization.SimpleOrganization>;
}

/**
 * The Alternate Organizations Select Component.
 *
 * @returns {ReactElement} - The Alternate Organizations Select Component.
 */
export function AlternateOrganizations({ value, onChange }: AlternateOrganizationsProps) {
  const [organizations, setOrganizations] = useState<Array<SelectValue>>([]);
  const [defaultAdditionalOrgOptions, setDefaultAdditionalOrgOptions] = useStateIfMounted<Array<SelectValue>>([]);

  const { t } = useTranslation('plans');

  useEffect(() => {
    if (value) {
      // Adding `label` keys to already selected alternate organizations.
      setOrganizations(value.map(org => ({ label: org.name, value: org.id, ...org })));
    }
  }, []);

  function reduceToSimpleOrganization(arr: Array<Organization.Organization>): Array<Organization.SimpleOrganization> {
    return arr.map(obj => ({
      id: obj.id,
      name: obj.name,
    }));
  }

  async function searchAdditionalOrgs(
    inputValue: string,
    callback: (value: Array<SelectValue>) => void,
    firstTime?: boolean
  ) {
    if (!inputValue.length && !firstTime) {
      // Don't fire a request for a blank string
      callback([]);
      return;
    }
    const result = await searchOrganizations(inputValue);
    if (result) {
      const filteredResult = reduceToSimpleOrganization(result);
      callback(
        filteredResult.map(org => ({
          label: org.name,
          value: org.id,
          ...org,
        }))
      );
    }
  }

  useEffect(() => {
    searchAdditionalOrgs(
      '',
      data => {
        setDefaultAdditionalOrgOptions(data);
      },
      true
    );
  }, []);

  return (
    <AsyncSelect
      data-testid='select-for-alternate-organizations'
      defaultOptions={defaultAdditionalOrgOptions}
      id='alternate_organizations'
      isMulti={true}
      loadOptions={searchAdditionalOrgs}
      name='alternate_organizations'
      onChange={(val: Array<SelectValue>) => {
        setOrganizations(val);
        onChange(val);
      }}
      placeholder={t('edit_plan_info.select')}
      value={organizations}
    />
  );
}

AlternateOrganizations.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
};

AlternateOrganizations.defaultProps = {
  value: [],
};
