import { useQuery } from '@tanstack/react-query';

import { QUERY_KEYS } from '../../helpers';
import { fetchClient } from '../../helpers/transport';

interface Session {
  errors: Array<{ key: string; message: string }>;
  success: boolean;
}

export async function getSession(): Promise<Session | undefined> {
  try {
    const response = await fetchClient<Session>('/session');
    return response.parsedBody;
  } catch (error) {
    const errorResponse: Response = error as Response;
    return errorResponse.json();
  }
}

export const useGetSession = (userId?: number) =>
  useQuery([QUERY_KEYS.SESSION, userId], () => getSession(), {
    enabled: Boolean(userId),
  });
