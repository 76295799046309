/**
 * @module Actions - Load logged in route
 */

import { appBootstrapCompleted } from 'state/actions/app';
import { loadAccount } from 'state/actions/auth';
import { list as loadLanguages } from 'state/actions/languages';
import { loadActiveOrg, loadInitialOrg } from 'state/actions/organizations';
import { activeOrgIdSelector } from 'state/selectors';

export function loadLoggedInRoute() {
  return async function loadLoggedInRoute(dispatch, getState) {
    const promises = [];
    const activeOrgId = activeOrgIdSelector(getState());
    const shouldLoadInitialOrg = activeOrgId === null;
    const shouldLoadActiveOrg = !(activeOrgId === null) && !(activeOrgId === 'ALL_ORGS');

    // Load the account before bootstrapping anything else
    const loadingAccount = dispatch(loadAccount());
    loadingAccount.then(() => {
      promises.push(dispatch(loadLanguages()));

      if (shouldLoadInitialOrg) {
        promises.push(dispatch(loadInitialOrg()));
      }
      if (shouldLoadActiveOrg) {
        promises.push(dispatch(loadActiveOrg(activeOrgId)));
      }

      Promise.all(promises).then(() => dispatch(appBootstrapCompleted()));
    });
  };
}
