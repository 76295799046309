/**
 * @module ActivityList
 */
import { useGetActivityItems } from 'api/activity';
import { Items } from 'components/Activity';
import LoaderOverlay from 'components/LoaderOverlay';
import { PaneContent } from 'components/misc';
import PaginationBar from 'components/misc/PaginationBar';
import { StatsList } from 'components/Stats';
import { ACTIVITIES_PER_PAGE, API_STATUS } from 'helpers/constants';
import { ReactElement } from 'react';
import { useSearchParams } from 'react-router-dom';

/**
 * The ActivityList component.
 *
 * @returns {ReactElement} - The ActivityList component.
 */
export const Activities = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { data: activityData, status: loadingStatus } = useGetActivityItems(searchParams.get('page') ?? 1);

  const isLoading = loadingStatus === API_STATUS.LOADING;

  function handlePageSelect({ selected }: { selected: number }) {
    searchParams.set('page', String(selected + 1));
    setSearchParams(searchParams);
  }

  if (isLoading || !activityData) {
    return <LoaderOverlay />;
  }

  const items = activityData.activityItems;
  const activityItemsMeta = activityData.meta;

  return (
    <>
      <PaneContent>
        <h1>Latest Activity</h1>
        <StatsList />
        <Items items={items} />

        <PaginationBar
          itemCount={items.length}
          onPageChange={handlePageSelect}
          pageCount={activityItemsMeta.total_pages}
          perPageCount={ACTIVITIES_PER_PAGE}
          selected={activityItemsMeta.current_page}
          totalCount={activityItemsMeta.total_count}
        />
      </PaneContent>
    </>
  );
};
