import _ from 'lodash';
import { NullableString } from 'types/misc';

const countWords = (str: NullableString, maxLength: number) => {
  const words = str ?? '';
  const wordCount: number = _.size(_.words(words));
  if (wordCount > maxLength) {
    return true;
  }
  return false;
};

export default countWords;
