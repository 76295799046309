/**
 * @module OrganizationReducer
 */

import { getPaginationInfo, handleApiAction as handleApi } from 'helpers';
import { API_LOGOUT } from 'helpers/constants';
import update from 'immutability-helper';
import _ from 'lodash';
import { schema } from 'normalizr';
import { handleActions } from 'redux-actions';
import { APP_ROUTE_CHANGED } from 'state/actions/app/constants';
import {
  CANCEL_EDIT_ORG,
  EDIT_ORG,
  LIST_ORGANIZATIONS,
  LOAD_ACTIVE_ORG,
  LOAD_INITIAL_ORG,
  LOAD_ORGS_ROUTE,
  SET_ORGS_FILTERS,
  SET_ORGS_SORT,
  SHOW_ORG,
  SWITCH_ACTIVE_ORG,
  SWITCH_VIEW_ALL_ORGS,
  UPDATE_ORG,
} from 'state/actions/organizations/constants';
import { KEY_ORGID, LOAD_ORGID } from 'state/reducers/constants';
import store2 from 'store2';

const { Entity } = schema;
const orgEnt = new Entity('organizations');
const userEnt = new Entity('users');
export const orgsSchema = { organizations: [orgEnt] };
export const orgSchema = { organization: orgEnt };
export const userSchema = { user: userEnt };

const LOAD_USERS_PAGE = 'orgs/LOAD_USERS_PAGE';
const LOAD_USER = 'orgs/LOAD_USER';
const LOAD_USERS = 'orgs/LOAD_USERS';
const DELETE_USER = 'orgs/DELETE_USER';
const SHOW_ORG_MODAL = 'orgs/SHOW_ORG_MODAL';
const HIDE_ORG_MODAL = 'orgs/HIDE_ORG_MODAL';

const initialState = {
  orgsById: {},
  usersById: {},
  curentPageOrgIds: [],
  activeOrgUserIds: [],
  activeOrgId: null,
  activeOrg: null,
  loading: false,
  isEditingOrg: false,
  showOrgModal: false,
  isLoadingUsers: false,
  viewingAllOrganizations: false,
  orgsPagination: null,

  orgsSorter: {
    sort: null,
    order: 'desc',
  },

  orgsFilters: {
    name: null,
    plans_count: null,
    // Because of a bug, we should not yet supply any value for 'status'.
    // status: null,
    plan_updated_date: null,
    plan_language: null,
  },

  // used by UserView
  userLoadError: false,
  isSavingUser: false,
  isEditingUser: false,
  isPasswordEditingUser: false,

  usersPagination: null,
  usersSorter: {
    sort: null,
    order: 'desc',
  },

  pages: {
    show: {
      loading: false,
      organization: null,
    },
  },
};

const isAllOrgs = orgId => {
  return orgId === 'ALL_ORGS';
};

const handleOrgsResult = ({ errorMsg }) =>
  handleApi((state, { payload, meta, allOrgs }) => ({
    start: s => ({
      ...s,
      loading: true,
    }),
    finish: s => ({
      ...s,
      loading: false,
    }),
    failure: s => ({
      ...s,
      error: (payload && payload.message) || errorMsg,
    }),
    success: s => {
      let { viewingAllOrganizations } = s;

      if (isAllOrgs(s.activeOrgId)) {
        viewingAllOrganizations = true;
      }

      // So we can use this method for single-org and multi-org responses.
      let { curentPageOrgIds } = s;
      if (payload.result.organizations) {
        curentPageOrgIds = payload.result.organizations;
      } else if (payload.result.organization) {
        // A single-org response is usually when an organization is updated
        // through the org-edit form
        curentPageOrgIds = _.uniq(curentPageOrgIds, payload.result.organization);
      }

      const everyOrg = allOrgs && allOrgs.entities.organizations;
      const everyOrgIds = allOrgs && allOrgs.result.organizations;

      return {
        ...s,
        orgsById: {
          ...s.orgsById,
          ...payload.entities.organizations,
          ...everyOrg,
        },
        orgsPagination: payload.result.meta ? getPaginationInfo(payload.result.meta) : s.orgsPagination,
        orgsSorter: meta.orgsSorter || s.orgsSorter,
        activeOrgId: s.activeOrgId,
        viewingAllOrganizations,
        allOrgIds: everyOrgIds || s.allOrgIds,
        curentPageOrgIds,
        error: null,
      };
    },
  }));

const handleUsersResult = () =>
  handleApi((s, action) => ({
    start: s => ({ ...s, isLoadingUsers: true }),
    finish: s => ({ ...s, isLoadingUsers: false }),
    success: s => ({
      ...s,
      usersById: {
        ...s.usersById,
        ...action.payload.entities.users,
      },
      activeOrgUserIds: action.payload.result.users,
      usersPagination: getPaginationInfo(action.payload.result.meta),
      usersSorter: action.meta.usersSorter || s.usersSorter,
    }),
  }));

const handleUserResult = () =>
  handleApi((s, action) => ({
    start: s => ({ ...s, isLoadingUsers: true, userLoadError: false }),
    finish: s => ({ ...s, isLoadingUsers: false }),
    failure: s => ({
      ...s,
      userLoadError: true,
    }),
    success: s => ({
      ...s,
      userLoadError: false,
      usersById: {
        ...s.usersById,
        ...action.payload.entities.users,
      },
    }),
  }));

export default handleActions(
  {
    [APP_ROUTE_CHANGED]: state => {
      return { ...state, isEditingOrg: false };
    },

    [LOAD_ACTIVE_ORG]: handleApi((s, { payload }) => ({
      start: s => {
        return update(s, {
          activeOrg: { $set: null },
        });
      },

      success: s => {
        return update(s, {
          activeOrg: { $set: payload.organization },
        });
      },

      // In the event of an API failure to load the active/selected org
      // set the context to All Orgs
      //
      // Accounting for something like a stale id, a deleted Org,
      // or just weird state.

      failure: s => {
        return update(s, {
          activeOrgId: { $set: 'ALL_ORGS' },
          viewingAllOrganizations: { $set: true },
        });
      },
    })),

    [LOAD_INITIAL_ORG]: handleApi((s, { payload }) => ({
      start: s => {
        return update(s, {
          activeOrg: { $set: null },
          activeOrgId: { $set: null },
        });
      },

      success: s => {
        // Making sure when a user has just one organization, we set that organization as the default.
        if (payload.organizations.length === 1) {
          const org = payload.organizations[0];
          store2.set(KEY_ORGID, org.id);
          return update(s, {
            activeOrgId: { $set: org.id },
            activeOrg: { $set: org },
          });
        }

        // if there're more than one organization present, we set default to 'ALL_ORGS'
        store2.set(KEY_ORGID, 'ALL_ORGS');
        return update(s, {
          activeOrgId: { $set: 'ALL_ORGS' },
          viewingAllOrganizations: { $set: true },
        });
      },

      // In the event of an API failure to load the active/selected org
      // set the context to All Orgs
      //
      // Accounting for something like a stale id, a deleted Org,
      // or just weird state.

      failure: s => {
        return update(s, {
          activeOrgId: { $set: 'ALL_ORGS' },
          viewingAllOrganizations: { $set: true },
        });
      },
    })),

    [SHOW_ORG]: handleApi((s, { payload }) => ({
      start: s => {
        return update(s, {
          pages: {
            show: {
              loading: { $set: true },
              organization: { $set: null },
            },
          },
        });
      },

      success: s => {
        return update(s, {
          pages: {
            show: {
              loading: { $set: false },
              organization: { $set: payload.organization },
            },
          },
        });
      },

      finish: s => {
        return update(s, {
          pages: { show: { loading: { $set: false } } },
        });
      },
    })),

    [SWITCH_VIEW_ALL_ORGS]: s => {
      return update(s, {
        viewingAllOrganizations: { $set: true },
        activeOrgId: { $set: 'ALL_ORGS' },
        activeOrg: { $set: null },
      });
    },

    [SWITCH_ACTIVE_ORG]: (s, { payload }) => {
      return update(s, {
        activeOrgId: { $set: payload },
        showOrgModal: { $set: false },
        viewingAllOrganizations: { $set: false },
        activeOrg: { $set: null },
      });
    },

    [SHOW_ORG_MODAL]: s => ({ ...s, showOrgModal: true }),
    [HIDE_ORG_MODAL]: s => ({ ...s, showOrgModal: false }),
    [EDIT_ORG]: s => ({ ...s, isEditingOrg: true }),
    [CANCEL_EDIT_ORG]: s => ({ ...s, isEditingOrg: false }),

    // Wipe-it clean!
    [API_LOGOUT]: handleApi(() => ({
      finish: s => ({
        ...s,
        ...initialState,
      }),
    })),
    [LOAD_USERS]: handleUsersResult(),

    [LOAD_ORGID]: (s, { payload }) => {
      return update(s, {
        activeOrgId: { $set: payload },
        viewingAllOrganizations: { $set: payload === 'ALL_ORGS' },
      });
    },

    [LOAD_USER]: handleUserResult(),
    [LOAD_USERS_PAGE]: handleUsersResult(),

    [DELETE_USER]: handleApi((s, action) => ({
      success: s => ({
        ...s,
        activeOrgUserIds: _.without(s.activeOrgUserIds, action.meta.userId),
      }),
    })),

    [UPDATE_ORG]: (s, action) => ({
      ...handleOrgsResult({
        errorMsg: 'Error updating organization. Please try again.',
      })(s, action),
      ...handleApi(() => ({
        // We only close the orgEditing mode when we have success. Otherwise,
        // we'll display form errors.
        // Note: Here we're just returning a single-key object. That way, it
        // doesn't overwrite the state result above.
        success: () => ({ isEditingOrg: false }),
      }))(s, action),
    }),

    [LIST_ORGANIZATIONS]: handleOrgsResult({
      errorMsg: 'Error loading organizations. Please try again.',
    }),

    [LOAD_ORGS_ROUTE]: handleOrgsResult({
      errorMsg: 'Error loading organizations. Please try again.',
    }),

    [SET_ORGS_FILTERS]: (state, action) => {
      return update(state, {
        orgsFilters: {
          name: { $set: action.payload.name },
        },
      });
    },
    [SET_ORGS_SORT]: (state, action) => {
      return update(state, {
        orgsSorter: {
          sort: { $set: action.payload.sort },
          order: { $set: action.payload.order },
        },
      });
    },
  },
  initialState
);
