/* eslint-disable jsdoc/require-param */
/**
 * @module ApproveAllButton
 */
import Check from '@mui/icons-material/Check';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Tooltip,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useAlert } from '@youversion/react';
import { statusTypes } from '@youversion/utils';
import { approveEntirePlan } from 'api/plans';
import { submissionStatuses } from 'helpers/constants';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Plan } from '../types';

const useStyles = makeStyles(() => ({
  approveButtonText: {
    color: '#FF3D4D',
  },
  buttonContainer: {
    alignItems: 'center',
    display: 'flex',
  },
  dialog: {
    width: '100%',
  },
}));

interface Props {
  /** A "plan" object, as returned from the API. */
  plan: Plan.Plan;
  /** Indicates the status of the plan. */
  planStatus: string;
}

/**
 * Responsible for approving plans.
 *
 *
 * @returns {React.ReactElement} - Buttons for specific state.
 */
export function ApproveAllButton({ plan, planStatus }: Props) {
  const { t } = useTranslation(['plans', 'common']);
  const classes = useStyles();
  const { throwAlert } = useAlert();
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [loadingApprovalStatus, setLoadingApprovalStatus] = React.useState(statusTypes.IDLE);

  async function handleApprovePlan() {
    try {
      setLoadingApprovalStatus(statusTypes.PENDING);
      await approveEntirePlan(plan.id);
      window.location.reload();
    } catch (error) {
      setLoadingApprovalStatus(statusTypes.REJECTED);
      setIsDialogOpen(false);
      throwAlert({
        id: 'plan_approve_error',
        key: 'plan_approve_error',
        message: t('plans:approve_plan.error_message'),
        timeout: 5000,
        type: statusTypes.ERROR,
      });
    }
  }

  let dialogTitle;
  let dialogContentChildren;
  let dialogActions;
  // When plan status is in submission state
  if (planStatus === submissionStatuses.SUBMITTED) {
    dialogTitle = t('plans:approve_plan.title');
    dialogContentChildren = (
      <>
        <DialogContentText>{t('plans:approve_plan.content')}</DialogContentText>
      </>
    );

    dialogActions = (
      <>
        <Button autoFocus={true} color='primary' onClick={() => setIsDialogOpen(false)}>
          {t('common:cancel')}
        </Button>
        <Button className={classes.approveButtonText} onClick={handleApprovePlan}>
          {t('plans:approve_plan.approve')}
        </Button>
      </>
    );
  }

  // When the Plan is approving, display a loading component to the user.
  if (planStatus === submissionStatuses.SUBMITTED && loadingApprovalStatus === statusTypes.PENDING) {
    dialogTitle = t('plans:approve_plan.approving');
    dialogContentChildren = <LinearProgress />;
    dialogActions = null;
  }
  return (
    <>
      <Box className={classes.buttonContainer}>
        <Box mr={1}>
          <Button
            color='primary'
            onClick={() => {
              setIsDialogOpen(true);
            }}
            startIcon={<Check />}
            variant='outlined'
          >
            {t('plans:approve_plan.title')}
          </Button>
        </Box>
        <Tooltip title={t('plans:approve_plan.tooltip_message')}>
          <InfoOutlined color='primary' />
        </Tooltip>
      </Box>

      <Dialog
        aria-describedby='submit-plan-dialog-content'
        aria-labelledby='submit-plan-dialog-title'
        classes={{ paper: classes.dialog }}
        // When the Plan is being approve, disable the ability to close the dialog.
        onClose={loadingApprovalStatus === statusTypes.PENDING ? undefined : () => setIsDialogOpen(false)}
        open={isDialogOpen}
      >
        <DialogTitle id='submit-plan-dialog-title'>{dialogTitle}</DialogTitle>
        <DialogContent id='submit-plan-dialog-content'>{dialogContentChildren}</DialogContent>
        <DialogActions>{dialogActions}</DialogActions>
      </Dialog>
    </>
  );
}

ApproveAllButton.propTypes = {
  plan: PropTypes.shape({
    id: PropTypes.number,
    overall_status: PropTypes.string,
  }).isRequired,
  planStatus: PropTypes.string.isRequired,
};
