import classnames from 'classnames';
import { LinkButton } from 'components/Buttons/link-button';
import React, { FC, MouseEventHandler } from 'react';
import ReactTooltip from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';

import styles from './styles.module.scss';
import type { ButtonProps } from './types';

interface Props extends ButtonProps {
  disabled?: boolean;
  onDelete: () => void;
  tooltip?: string;
}

const DeleteButton: FC<Props> = ({ disabled = false, onDelete, tooltip, className, ...props }) => {
  let tipProps = null;
  const tipId = uuidv4();

  const clickHandler: MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault();
    e.stopPropagation();
    if (!disabled) {
      onDelete();
    }
  };

  const baseStyle = disabled ? styles.deleteBtnDisabled : styles.deleteBtn;

  if (tooltip) {
    tipProps = {
      'data-for': tipId,
      'data-tip': tooltip,
    };
  }

  return (
    <LinkButton
      className={classnames(baseStyle, className)}
      data-tip={tooltip}
      onClick={clickHandler}
      {...tipProps}
      {...props}
    >
      <i className='fa fa-times-circle fa-2x' />
      {tooltip ? <ReactTooltip id={tipId} /> : null}
    </LinkButton>
  );
};

export default DeleteButton;
