import { useMutation, useQuery } from '@tanstack/react-query';
import { API_ADDRESS, getToken } from 'helpers';
import { fetchClient } from 'helpers/transport';
import { NumberLike } from 'types/misc';

/**
 * Gets all active permissions for a user.
 *
 * @param {NumberLike} userId - The user's id.
 *
 * @throws {Error} - Throws an error if there's a problem with the API response.
 *
 * @returns {Promise<object>} - All active permissions for the user.
 */
export async function getUserPermissions(userId?: number | string) {
  const response = await fetchClient<{ data: Array<UserPermission> }>(`/v1/user_permissions?user_id=${userId}`);
  return response.parsedBody.data;
}

export const useGetUserPermissions = (userId?: NumberLike) =>
  useQuery(['user-permissions', userId], () => getUserPermissions(userId));

interface Props {
  permissionId: string;
  userId: number;
}

const createUserPermissions = async ({ userId, permissionId }: Props): Promise<UserPermission> => {
  if (!userId || !permissionId) {
    throw new Error('`userId` and `permissionId` are required and must be a number or string.');
  }
  const authToken = await getToken();
  const response = await fetch(`${API_ADDRESS}/v1/user_permissions`, {
    body: JSON.stringify({
      user_permission: {
        permission_id: permissionId,
        user_id: userId,
      },
    }),
    headers: {
      authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
  });
  const jsonResponse = await response.json();
  return jsonResponse.data;
};

export const useCreateUserPermissions = () => useMutation(createUserPermissions);

/**
 * Deletes a user permission.
 *
 * @param {NumberLike} userPermissionId - The user permission's id.
 *
 * @throws {Error} - Throws an error if the userId or permissionId is missing or incorrect.
 * @throws {Error} - Throws an error if there's a problem with the API response.
 *
 * @returns {Promise<object>} - All active permissions for the user.
 */
export async function deleteUserPermissions(userPermissionId: number | string) {
  if (!userPermissionId) {
    throw new Error('`userPermissionId` is required and must be a number or string.');
  }

  try {
    const authToken = await getToken();
    const response = await fetch(`${API_ADDRESS}/v1/user_permissions/${userPermissionId}`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      method: 'DELETE',
    });
    return response.status;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    }
  }
}

export const useDeleteUserPermissions = () => useMutation(deleteUserPermissions);
