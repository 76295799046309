/* eslint-disable react/no-multi-comp */
/**
 * @module Organization
 */
import { useAlert } from '@youversion/react';
import { useGetOrganization } from 'api/organizations';
import { Can, useAuth } from 'auth';
import LoaderOverlay from 'components/LoaderOverlay';
import { PaneContent } from 'components/misc';
import styles from 'components/Organizations/Organization.module.scss';
import OrgInfoForm from 'components/Organizations/OrgInfoForm';
import { canEditOrg } from 'components/Organizations/permissions';
import { OrganizationTransferList } from 'components/Organizations/TransferList';
import { UserTable } from 'components/Organizations/UserTable';
import { API_STATUS } from 'helpers/constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

/**
 * The Organization component.
 *
 * @alias module:Organization
 *
 * @param {object} props - The component props object.
 *
 * @returns {React.ReactElement} - The Organization component.
 */
export function EditOrganization() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { throwAlert } = useAlert();
  const { user } = useAuth();
  const { data: org, status: loadingStatus, refetch } = useGetOrganization(id);
  const { t } = useTranslation(['organizations']);

  const orgId = id ? parseInt(id, 10) : null;

  React.useEffect(() => {
    const canUserEditOrganization = user && (user.can('edit:organization') || (orgId && canEditOrg(user, orgId)));

    if (!canUserEditOrganization) {
      navigate({
        pathname: `/organizations/${orgId}`,
      });
      throwAlert({
        id: 'not_enough_permissions_error',
        key: 'not_enough_permissions_error',
        message: t('organizations:edit_org.not_enough_permissions_to_edit'),
        type: 'error',
        timeout: 3000,
      });
    }
  }, [navigate, org, orgId, throwAlert, user, t]);

  if (loadingStatus !== API_STATUS.SUCCESS || !org || !orgId) {
    return <LoaderOverlay />;
  }

  return (
    <div className={styles.fullWidth}>
      <PaneContent className={styles.orgWrapper}>
        <OrgInfoForm org={org} refetch={refetch} />

        <Can user='administrate:all_permissions'>
          <OrganizationTransferList orgId={orgId} />
        </Can>

        {user ? (
          <Can user='edit:organization_membership'>
            <UserTable currentUser={user} isEditing={true} orgId={orgId} />
          </Can>
        ) : null}
      </PaneContent>
    </div>
  );
}
