/* eslint-disable jsdoc/require-param */
/**
 * @module PlansComponent
 */
import { useGetPlans } from 'api/plans';
import { Loader } from 'components';
import styles from 'components/Plans/Plans.module.scss';
import { PlansHeading } from 'components/Plans/PlansHeading';
import LandingPage from 'containers/landing-page';
import NewOrganization from 'containers/landing-page/new-organization';
import { PLANS_PER_PAGE } from 'helpers';
import { ReactElement, useMemo } from 'react';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { State } from 'state/reducers';
import { activeOrgIdSelector, viewingAllOrgsSelector } from 'state/selectors';

interface PlansComponentProps {
  /** Indicates if the active organization is "All Organizations". */
  isViewingAllOrgs: boolean;
  /** The active organization id. */
  organizationId: string;
}

/**
 * The plans page.
 *
 * @returns {ReactElement} - The Plans component.
 */
export function PlansComponent({ organizationId, isViewingAllOrgs }: PlansComponentProps) {
  const [query] = useSearchParams();

  const { data: plansResponse, status } = useGetPlans({
    filters: {
      language: query.get('language') || undefined,
      organization_id: organizationId !== 'ALL_ORGS' ? organizationId : undefined,
      name: query.get('name') || undefined,
    },
    sorter: {
      order: query.get('order') ?? 'desc',
      sort: query.get('sort') ?? 'created_at',
    },
    pagination: {
      page: query.get('page') ?? 1,
      per: PLANS_PER_PAGE,
    },
  });

  const isFilterPresent = useMemo(
    () => Boolean(query.get('name') || query.get('page') || query.get('language')),
    [query]
  );

  // Sometimes organizationId is null until organizations data is fetched!
  if (organizationId === null) {
    return <Loader />;
  }

  return (
    <div className={styles.plansContainer}>
      <PlansHeading isViewingSingleOrg={!isViewingAllOrgs} />
      {plansResponse?.data.length === 0 && !isFilterPresent ? (
        <NewOrganization isViewingSingleOrg={!isViewingAllOrgs} />
      ) : (
        <LandingPage data={plansResponse} loadingStatus={status} organizationId={organizationId} />
      )}
    </div>
  );
}

const stateToProps = (state: State): PlansComponentProps => ({
  organizationId: activeOrgIdSelector(state),
  isViewingAllOrgs: viewingAllOrgsSelector(state),
});

export default connect(stateToProps)(PlansComponent);
