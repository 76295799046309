/**
 * @module FinalConfirmation
 */
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Theme,
  Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { statusTypes } from '@youversion/utils';
import MuiLoadingButton from 'components/Buttons/mui-loading-button';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  handleAcceptTerms: () => void;
  handleBack: () => void;
  handleClose: () => void;
  handleSubmit: () => void;
  hasDraftLaunchDate: boolean;
  isTermsChecked: boolean;
  loadingStatus: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    description: {
      marginBlockStart: theme.spacing(1),
    },
    modalSpacing: {
      padding: '16px',
    },
    modalContent: {
      padding: '0 16px',

      '& p': {
        marginBlockEnd: '16px',

        '&:nth-child(2)': {
          marginBlockStart: '5px',
        },
      },
    },
    checkbox: {
      padding: '0px 9px',
    },
  })
);

const FinalConfirmation: FC<Props> = ({
  handleBack,
  handleClose,
  handleSubmit,
  handleAcceptTerms,
  hasDraftLaunchDate,
  loadingStatus,
  isTermsChecked,
}) => {
  const { t } = useTranslation(['plans', 'common']);
  const classes = useStyles();
  const dialogTitle = t('plans:submit_plan.final_confirmation.title');
  const dialogContentPreDescription = t('plans:submit_plan.final_confirmation.pre_description');
  const dialogContentDescription = t('plans:submit_plan.final_confirmation.description');
  const label = t('plans:submit_plan.final_confirmation.agree_to_content');
  const dialogContentChildren = (
    <>
      {!hasDraftLaunchDate && (
        <Typography color='textSecondary' variant='caption'>
          {t('common:step_of_total', {
            step: 2,
            total: 2,
          })}
        </Typography>
      )}
      <Typography color='textPrimary' gutterBottom={true} variant='body1'>
        {dialogContentPreDescription}
      </Typography>
      <Typography color='textPrimary' variant='body1'>
        {dialogContentDescription}
      </Typography>
      <Box>
        <FormControlLabel
          checked={isTermsChecked}
          control={
            <Checkbox
              className={classes.checkbox}
              disabled={false}
              onChange={handleAcceptTerms}
              value={isTermsChecked}
            />
          }
          data-testid='confirmation-checkbox'
          label={label}
        />
      </Box>
    </>
  );
  const dialogActions = (
    <>
      {hasDraftLaunchDate ? (
        <Button className={classes.description} color='primary' onClick={handleClose}>
          {t('common:close')}
        </Button>
      ) : (
        <Button className={classes.description} color='primary' onClick={handleBack}>
          {t('common:back')}
        </Button>
      )}

      <MuiLoadingButton
        color='primary'
        disabled={!isTermsChecked || loadingStatus === statusTypes.PENDING}
        isLoading={loadingStatus === statusTypes.PENDING}
        onClick={handleSubmit}
        size='medium'
        type='submit'
        variant='contained'
      >
        {t('common:submit')}
      </MuiLoadingButton>
    </>
  );

  return (
    <>
      <DialogTitle className={classes.modalSpacing} id='dialog-wrapper-title'>
        {dialogTitle}
      </DialogTitle>
      <DialogContent className={classes.modalContent} id='dialog-wrapper-content'>
        {dialogContentChildren}
      </DialogContent>
      <DialogActions className={classes.modalSpacing}>{dialogActions}</DialogActions>
    </>
  );
};

export default FinalConfirmation;
