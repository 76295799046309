/**
 * @module narratedAudioFeature
 */
import { useMutation } from '@tanstack/react-query';
import { Plan } from 'components/Plans/types';
import { fetchClient } from 'helpers/transport';
import { NumberLike } from 'types/misc';

/**
 * Enables the narrated audio feature for a plan.
 *
 * @param {(number|string)} planId - The plan id on which to enable narrated audio.
 *
 * @returns {Promise<object>} - The narrated audio feature data.
 */
async function enableNarratedAudio(planId: NumberLike) {
  const response = await fetchClient<{
    narrated_audio_feature: Plan.NarratedAudioFeature;
  }>(`/v1/plans/${planId}/features/narrated_audio`, { method: 'POST' });

  return response.parsedBody.narrated_audio_feature;
}

export const useEnableNarratedAudio = () => useMutation(enableNarratedAudio);

/**
 * Disables the narrated audio feature for a plan.
 *
 * @param {(number|string)} planId - The plan id for which to disable narrated audio.
 *
 * @returns {Promise} - Does not return anything.
 */
async function disableNarratedAudio(planId: NumberLike) {
  return fetchClient(`/v1/plans/${planId}/features/narrated_audio`, {
    method: 'DELETE',
  });
}
export const useDisableNarratedAudio = () => useMutation(disableNarratedAudio);
