/* eslint-disable jsdoc/require-param */
import { Save as SaveIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import { AsyncButton, useAlert } from '@youversion/react';
import { useUpdateUser } from 'api/users';
import { Form, Formik } from 'formik';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

const AddMemberSchema = Yup.object().shape({
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'validation.password_match')
    .required('validation.password_match'),
  password: Yup.string().required('validation.password'),
});

interface UpdatePasswordProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

interface UpdatePasswordFormProps {
  confirmPassword: string;
  password: string;
}

/**
 * Update password Form.
 *
 * @returns {ReactElement} - Returns jsx.
 */
export function UpdatePassword({ open, setOpen }: UpdatePasswordProps) {
  const { throwAlert } = useAlert();
  const { mutateAsync } = useUpdateUser();
  const { t } = useTranslation('users');

  async function handleSubmit(values: UpdatePasswordFormProps) {
    try {
      await mutateAsync({
        password: values.password,
        password_confirmation: values.confirmPassword,
      });
      setOpen(false);
      throwAlert({
        id: 'update_password_success',
        key: 'update_password_success',
        message: t('messages.update_password_success'),
        timeout: 3000,
        type: 'success',
      });
    } catch (e) {
      if (e instanceof Error) {
        throwAlert({
          id: 'update_password_failed',
          key: 'update_password_failed',
          message: t('messages.update_password_failed', { error: e.message }),
          timeout: 3000,
          type: 'error',
        });
      }
    }
  }

  return (
    <Dialog maxWidth='xs' onClose={() => setOpen(false)} open={open}>
      <DialogTitle>{t('update_password.title')}</DialogTitle>

      <Formik
        initialValues={{
          password: '',
          confirmPassword: '',
        }}
        onSubmit={handleSubmit}
        validationSchema={AddMemberSchema}
      >
        {({ handleChange, values, errors, isSubmitting, touched, submitForm, handleBlur }) => (
          <Form>
            <DialogContent>
              <DialogContentText>{t('update_password.instruction')}</DialogContentText>
              <Box marginY={3}>
                <TextField
                  error={touched.password ? Boolean(errors.password) : undefined}
                  fullWidth={true}
                  helperText={touched.password && errors.password ? t(errors.password) : undefined}
                  label={t('update_password.new_password')}
                  name='password'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={t('update_password.new_password')}
                  type='password'
                  value={values.password}
                  variant='outlined'
                />
              </Box>

              <Box sx={{ marginBlockEnd: 3 }}>
                <TextField
                  error={touched.confirmPassword ? Boolean(errors.confirmPassword) : undefined}
                  fullWidth={true}
                  helperText={touched.confirmPassword && errors.confirmPassword ? t(errors.confirmPassword) : undefined}
                  label={t('update_password.confirm_password')}
                  name='confirmPassword'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={t('update_password.confirm_password')}
                  type='password'
                  value={values.confirmPassword}
                  variant='outlined'
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <AsyncButton
                color='primary'
                disabled={isSubmitting || !values.password || !values.confirmPassword}
                idle={t('save')}
                onClick={submitForm}
                options={{
                  disableUpdateOnSuccess: true,
                }}
                pending={t('update_password.updating')}
                rejected={t('update_password.updating_error')}
                resolved={t('update_password.password_updated')}
                startIcon={<SaveIcon />}
                variant='contained'
              />
              <Button
                autoFocus={true}
                onClick={() => {
                  setOpen(false);
                }}
                style={{ marginInlineStart: 8 }}
                type='button'
                variant='outlined'
              >
                {t('cancel')}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
