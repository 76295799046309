/**
 * @module useStatusIconAndText
 */

import { Check, Create, ErrorOutline, Schedule } from '@mui/icons-material';
import { capitalize, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { utilityColors } from '@youversion/react/styles/colors-v3';
import classNames from 'classnames';
import { Plan } from 'components/Plans/types';
import * as React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dayBoxTopRightIcon: {
      fill: theme.palette.text.secondary,
      fontSize: 16,
      position: 'absolute',
      insetInlineEnd: 2,
      insetBlockStart: 2,
    },
    errorIcon: {
      fill: utilityColors.light.warning,
    },
    smallerIcon: {
      fontSize: "14px '!important'",
    },
  })
);

/**
 * @typedef {object} StatusIconObject
 * @property {React.Element} icon - Icon component.
 * @property {string} tooltipText - Text to indicate status.
 */

/**
 * Determine the icon and tooltip text for the status.
 *
 * @alias useStatusIconAndText
 *
 * @param {string} status - The status of the plan or the day.
 *
 * @returns {StatusIconObject} - Icon and text for the status.
 *
 * @example
 * import { useStatusAndText } from 'hooks'
 *
 * function MyComponent() {
 *   const {icon, tooltipText} = useStatusAndText(dayStatus)
 *
 *   return (
 *    <Tooltip placement="top" title={tooltipText}>
 *      {icon}
 *    </Tooltip>
 *   )
 * }
 *
 */
// eslint-disable-next-line import/prefer-default-export

export function useStatusIconAndText(status: string) {
  const classes = useStyles();

  let icon = null;
  let tooltipText = capitalize(status);

  switch (status) {
    case Plan.DayStatus.MISSING_NARRATED_AUDIO_STATUS:
      icon = (
        <ErrorOutline
          className={classNames(classes.errorIcon, classes.dayBoxTopRightIcon)}
          color='inherit'
          data-testid='missing-narrated-audio'
          fontSize='small'
        />
      );
      tooltipText = 'Narrated Audio is Required';
      break;
    case Plan.DayStatus.PUBLISHED:
    case Plan.DayStatus.APPROVED:
      icon = <Check className={classes.dayBoxTopRightIcon} data-testid='check-icon-for-approved' fontSize='small' />;
      break;
    case Plan.DayStatus.SUBMITTED:
      icon = (
        <Schedule className={classes.dayBoxTopRightIcon} data-testid='schedule-icon-for-submitted' fontSize='small' />
      );
      tooltipText = 'In Review';
      break;
    case Plan.DayStatus.DRAFT:
      icon = (
        <Create
          className={classNames(classes.dayBoxTopRightIcon, classes.smallerIcon)}
          data-testid='create-icon-for-draft'
          fontSize='small'
        />
      );
      break;
    default:
      icon = (
        <i
          className={classNames('fa', 'fa-circle', status, classes.dayBoxTopRightIcon)}
          data-testid='default-day-status-icon'
        />
      );
      break;
  }

  return { icon, tooltipText };
}
