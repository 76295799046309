/**
 * @module RoutesAuthenticated
 */
// `path` should be the first prop in a Route so that routes are easy to maintain.
/* eslint-disable react/jsx-sort-props */
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import { Box } from '@mui/material';
import { AudioPlayerProvider, EmptyState } from '@youversion/react';
import { ReactComponent as UndrawEmptyImage } from '@youversion/react/assets/images/undraw-empty.svg';
import { useAuth } from 'auth';
import LoaderOverlay from 'components/LoaderOverlay';
import LoggedInLayout from 'components/Menu/LoggedInLayout';
import MenuLayout from 'components/Menu/MenuLayout';
import { Activities } from 'containers/activity';
import { MyAccount } from 'containers/users/myAccount';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { loadAccount } from 'state/actions/auth';
import { loadOrgsRoute } from 'state/actions/organizations';
import { State } from 'state/reducers';
import { activeOrgIdSelector } from 'state/selectors';

import { useGetSession } from '../../api/sessions';
import AcceptInvitation from '../accept'; // Accept is used in both authed and non-authed spaces.
import AnalyticsRoutes from './analytics';
import GroupsRoutes from './groups';
import LanguagesRoutes from './languages';
import { OrganizationsRoutes } from './organizations';
import PlansRoutes from './plans';
import WithoutPlans from './reports/globalreps/withoutplans';
import WithPlans from './reports/globalreps/withplans';
import Unauthorized from './unauthorized';
import { UsersRoutes } from './users';

const stateToProps = (state: State) => activeOrgIdSelector(state);

/**
 * The authenticated routes component.
 *
 * @alias module:RoutesAuthenticated
 *
 * @param {object} props - The component props.
 * @param {Function} props.dispatch - Redux dispatch.
 *
 * @returns {React.ReactElement} - The RoutesAuthenticated component.
 */
function RoutesAuthenticated() {
  const { user } = useAuth();
  const dispatch: (thunk: any) => Promise<{ error: Error; message: string }> = useDispatch();
  const orgId = useSelector(stateToProps);

  const { data: sessionData, isLoading: isSessionLoading } = useGetSession(user?.id);

  React.useLayoutEffect(() => {
    dispatch(loadAccount());
    // Get all user's organizations when entering the auth'd app for the new Sidebar.
    // This allows them to have the ability to switch between their available orgs.
    dispatch(loadOrgsRoute());
  }, [dispatch]);

  if (!user || isSessionLoading) {
    return <LoaderOverlay />;
  }

  if (sessionData?.success === false) {
    return <Unauthorized errors={sessionData.errors} />;
  }

  return (
    <LoggedInLayout>
      {/* @ts-expect-error - this component is running on an old react version */}
      <AudioPlayerProvider>
        <MenuLayout>
          {/* 🐣 Nested routes only below this line. */}
          <Routes>
            <Route index={true} element={<Navigate to='/plans' />} />
            <Route path='accept/:token' element={<AcceptInvitation />} />
            <Route path='activity' element={<Activities />} />
            <Route path='analytics/*' element={<AnalyticsRoutes />} />
            <Route path='my-account' element={<MyAccount orgId={orgId} />} />
            {/* Report routes are not nested because the parent routes do not have components. */}
            <Route path='reports/globalreps/withplans' element={<WithPlans />} />
            <Route path='reports/globalreps/withoutplans' element={<WithoutPlans />} />
            <Route
              path='sign-up'
              element={
                <Box alignItems='center' display='flex' height='100vh' justifyContent='center'>
                  {/* @ts-expect-error - this component is running on an old react version */}
                  <EmptyState
                    headerText='Hey, nice to see you again!'
                    maxWidth='xs'
                    muiHeaderIcon={<EmojiPeopleIcon />}
                    subtitleText='You tried to access a page meant for new users. If this was intended, please log out and try again.'
                  />
                </Box>
              }
            />
            {user.can('read:group') ? <Route path='groups/*' element={<GroupsRoutes />} /> : null}
            {user.can('read:language') ? <Route path='languages/*' element={<LanguagesRoutes />} /> : null}
            <Route path='organizations/*' element={<OrganizationsRoutes />} />
            <Route path='plans/*' element={<PlansRoutes />} />
            <Route path='users/*' element={<UsersRoutes />} />
            <Route
              path='*'
              element={
                <Box alignItems='center' display='flex' height='100vh' justifyContent='center'>
                  {/* @ts-expect-error - this component is running on an old react version */}
                  <EmptyState
                    headerImage={<UndrawEmptyImage height={200} />}
                    headerText='Page Not Found'
                    subtitleText='Sorry, we can’t seem to find the requested page.'
                  />
                </Box>
              }
            />
          </Routes>
        </MenuLayout>
      </AudioPlayerProvider>
    </LoggedInLayout>
  );
}

export default RoutesAuthenticated;
