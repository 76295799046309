/* eslint-disable no-nested-ternary */
/* eslint-disable jsdoc/require-param */
/**
 * @module NarratedAudioDialog
 */
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import {
  Box,
  Button,
  Checkbox,
  Checkbox as MuiCheckbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  Skeleton,
  Typography,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import { SelectInputProps } from '@mui/material/Select/SelectInput';
import makeStyles from '@mui/styles/makeStyles';
import { useGetPollyVoices } from 'api/features/voice';
import { ReactComponent as VolumeIcon } from 'assets/volume_icon.svg';
import { AudioButton } from 'components/Buttons/audio-button';
import { Plan } from 'components/Plans/types';
import { usePlan } from 'context';
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  formControlLabel: {
    alignItems: 'flex-start',
    marginBlockEnd: theme.spacing(1),
  },
  formControlLabelText: {
    marginBlockStart: 8,
  },
  selectAudio: {
    flex: '1 0',
    minWidth: '200px',
  },
  selectAudioWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 16,
    marginBlockStart: 10,
    marginInlineStart: 30,
    alignItems: 'center',
  },
  selectComponent: {
    display: 'flex',
    alignItems: 'center',
  },
  menuItem: {
    display: 'flex',
  },
  selectLabel: {
    backgroundColor: 'white',
    padding: '0 5px',
  },
  listItemIcon: {
    marginInlineEnd: 16,
  },
}));

interface NarratedAudioDialogProps {
  /** Disable fields. */
  disabled: boolean;
  /** Changes state of audio. */
  handleSelection: (value: boolean) => void;
  /** Function to submit plan. */
  handleSubmit: () => void;
  /** Plan Object. */
  plan: Plan.Plan;
  /** Does plan has narrated audio. */
  planHasNarratedAudio: boolean;
  planLanguage: Language;
  /** Function to toggle dialog. */
  setShowNarratedAudioDialog: Dispatch<SetStateAction<boolean>>;
  /** Value to toggle dialog. */
  showNarratedAudioDialog: boolean;
  /** State of audio. */
  value: boolean;
}

/**
 * Narrated Audio Dialog Component.
 *
 * @returns {ReactElement} - Narrated Audio Dialog Component.
 */
export function NarratedAudioDialog({
  setShowNarratedAudioDialog,
  showNarratedAudioDialog,
  handleSubmit,
  plan,
  planLanguage,
  disabled,
  value,
  handleSelection,
}: NarratedAudioDialogProps) {
  const classes = useStyles();
  const { updatePlanDetails } = usePlan();
  const { data, isLoading, isError, refetch: refetchVoices } = useGetPollyVoices(planLanguage.code);
  const { t } = useTranslation('plans');
  const [narratedAudioAgreements, setNarratedAudioAgreements] = useState({
    agreement1: false,
    agreement2: false,
  });
  const pollyVoiceId = useMemo(() => {
    return plan.polly_voice_id;
  }, [plan]);

  const [selectedVoiceId, setSelectedVoiceId] = useState(pollyVoiceId ? String(pollyVoiceId) : '');

  const isNarratedAudioEnabled = value;

  const isDisabled = disabled;

  function handleOnChange({ target: { checked, name } }: ChangeEvent<HTMLInputElement>) {
    setNarratedAudioAgreements(prevObject => ({
      ...prevObject,
      [name]: checked,
    }));
  }

  function handleChange(isChecked: boolean) {
    if (isChecked) {
      updatePlanDetails({ polly_voice_id: null });
    } else {
      updatePlanDetails({ polly_voice_id: Number(selectedVoiceId) || null });
    }
    handleSelection(isChecked);
  }

  const handleChangeVoice: SelectInputProps<{}>['onChange'] = e => {
    setSelectedVoiceId(String(e.target.value));
    updatePlanDetails({ polly_voice_id: Number(e.target.value) });
  };

  useEffect(() => {
    if (pollyVoiceId) {
      setSelectedVoiceId(pollyVoiceId ? String(pollyVoiceId) : '');
    }
  }, [data, pollyVoiceId]);

  return (
    <>
      <FormControl>
        <Box>
          <Box alignItems='center' display='flex' id='narrated-audio' mb={1}>
            <Typography id='audio-section' variant='h3'>
              {t('edit_plan_info.devotional_audio')}
            </Typography>
            <Box alignItems='center' display='flex' ml={0.5} mr={1}>
              <VolumeIcon />
            </Box>
          </Box>
          <Typography color='textSecondary' variant='caption'>
            {t('edit_plan_info.audio_description')}
          </Typography>
        </Box>
        {!data?.length ? null : (
          <FormControlLabel
            classes={{
              root: classes.formControlLabel,
            }}
            control={
              <Radio
                checked={!isNarratedAudioEnabled}
                color='primary'
                disabled={isDisabled}
                name='automated-audio-radio'
                onChange={event => handleChange(!event.target.checked)}
              />
            }
            disabled={isDisabled}
            label={
              <>
                <Box mt={1} />
                <Typography variant='body1'>{t('edit_plan_info.automated')}</Typography>
                <Typography color='textSecondary' variant='caption'>
                  {t('edit_plan_info.automated_info')}
                </Typography>
              </>
            }
          />
        )}

        {value ? null : (
          <>
            {isLoading ? (
              <Box className={classes.selectAudioWrapper}>
                <Skeleton height={50} sx={{ borderRadius: '10px' }} variant='rectangular' width='100%' />
                <Skeleton height={50} sx={{ borderRadius: '100px' }} variant='rectangular' width='40%' />
              </Box>
            ) : isError ? (
              <Box className={classes.selectAudioWrapper}>
                <Alert severity='warning' style={{ width: '100%' }}>
                  {t('edit_plan_info.voices_error')}
                  <Box mt={1}>
                    <Button onClick={() => refetchVoices()} variant='outlined'>
                      {t('edit_plan_info.try_again')}
                    </Button>
                  </Box>
                </Alert>
              </Box>
            ) : !data?.length ? null : (
              <Box className={classes.selectAudioWrapper}>
                <FormControl className={classes.selectAudio} size='small' variant='outlined'>
                  <InputLabel className={classes.selectLabel} htmlFor='select-plan-narration-voice' shrink={true}>
                    {t('edit_plan_info.select_voice')}
                  </InputLabel>
                  <Select
                    MenuProps={{
                      classes: {
                        root: classes.selectComponent,
                      },
                    }}
                    displayEmpty={true}
                    inputProps={{
                      name: 'select-plan-narration-voice',
                      id: 'select-plan-narration-voice',
                    }}
                    label={t('edit_plan_info.select_voice')}
                    onChange={handleChangeVoice}
                    value={selectedVoiceId}
                  >
                    <MenuItem className={classes.menuItem} value=''>
                      <Box className={classes.listItemIcon}>
                        <RecordVoiceOverIcon />
                      </Box>
                      —
                    </MenuItem>
                    {data.map(el => (
                      <MenuItem className={classes.menuItem} key={el.id} value={el.id}>
                        <Box className={classes.listItemIcon}>
                          <RecordVoiceOverIcon />
                        </Box>
                        {el.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <AudioButton
                  label={t('edit_plan_info.preview_voice')}
                  src={data?.find(el => String(el.id) === selectedVoiceId)?.audio_preview_url}
                />
              </Box>
            )}
          </>
        )}

        <FormControlLabel
          classes={{
            root: classes.formControlLabel,
          }}
          control={
            !data?.length ? (
              <Checkbox
                checked={isNarratedAudioEnabled}
                color='primary'
                disabled={isDisabled}
                name='narrated-audio-checkbox'
                onChange={event => handleChange(event.target.checked)}
              />
            ) : (
              <Radio
                checked={isNarratedAudioEnabled}
                color='primary'
                disabled={isDisabled}
                name='narrated-audio-radio'
                onChange={event => handleChange(event.target.checked)}
              />
            )
          }
          disabled={isDisabled}
          label={
            <>
              <Box mt={1} />
              <Typography variant='body1'>{t('edit_plan_info.narrated_audio')}</Typography>
              <Typography color='textSecondary' variant='caption'>
                {t('edit_plan_info.narrated_info')}
              </Typography>
              {isNarratedAudioEnabled ? (
                <Box mt={1}>
                  <Alert severity='info' variant='outlined'>
                    {t('edit_plan_info.thank_you')}
                  </Alert>
                </Box>
              ) : null}
            </>
          }
        />
      </FormControl>

      <Dialog
        aria-describedby='dialog-narrated-audio-description'
        aria-labelledby='dialog-narrated-audio-title'
        keepMounted={true}
        onClose={() => setShowNarratedAudioDialog(false)}
        open={showNarratedAudioDialog}
      >
        <DialogTitle id='dialog-narrated-audio-title'>{t('edit_plan_info.confirm_audio')}</DialogTitle>

        <DialogContent>
          <FormGroup row={true}>
            <FormControlLabel
              classes={{
                label: classes.formControlLabelText,
                root: classes.formControlLabel,
              }}
              control={
                <MuiCheckbox
                  checked={narratedAudioAgreements.agreement1}
                  color='primary'
                  name='agreement1'
                  onChange={handleOnChange}
                />
              }
              label={t('edit_plan_info.first_agreement')}
            />
            <FormControlLabel
              classes={{
                label: classes.formControlLabelText,
                root: classes.formControlLabel,
              }}
              control={
                <MuiCheckbox
                  checked={narratedAudioAgreements.agreement2}
                  color='primary'
                  name='agreement2'
                  onChange={handleOnChange}
                />
              }
              label={t('edit_plan_info.second_agreement')}
            />
          </FormGroup>
        </DialogContent>

        <DialogActions>
          <Button color='primary' onClick={() => setShowNarratedAudioDialog(false)} size='small'>
            {t('edit_plan_info.back')}
          </Button>
          <Button
            color='primary'
            disabled={
              // This runs through every Narrated Audio agreement to validate they all are checked.
              !Object.values(narratedAudioAgreements).reduce((accumulator, currentValue) =>
                Boolean(accumulator && currentValue)
              )
            }
            onClick={handleSubmit}
            size='small'
          >
            {t('edit_plan_info.i_agree')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
