/* eslint-disable jsdoc/require-param */
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from 'helpers/constants';
import { fetchClient } from 'helpers/transport';

const VOICES_BASE_URL = '/v1/polly_voices';

/**
 * List voices items.
 *
 * @returns {Promise<Array<object>>} - An array of all voices items.
 */
async function getPollyVoices(languageTag: string) {
  const response = await fetchClient<{
    meta: Meta;
    polly_voices: Array<PollyVoice>;
  }>(`${VOICES_BASE_URL}?language_tag=${languageTag}`);

  return response.parsedBody.polly_voices ?? [];
}

export const useGetPollyVoices = (languageTag: string) =>
  useQuery([QUERY_KEYS.VOICES, languageTag], () => getPollyVoices(languageTag));
