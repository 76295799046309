/* eslint-disable react/jsx-props-no-spreading */
import c from 'classnames';
import { ClassWrapper } from 'components';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './Misc.module.scss';

export const PaneContent = props => <ClassWrapper {...props} wrapClass={styles.paneContent} />;

export const FormContent = props => {
  // eslint-disable-next-line react/destructuring-assignment
  if (!props.children) {
    return null;
  }
  const { full, compact, ...rest } = props;
  const styleSet = [];
  styleSet.push(full ? styles.formContentFull : styles.formContent);

  if (compact) {
    styleSet.push(styles.formContentCompact);
  }

  return <ClassWrapper {...rest} wrapClass={c(styleSet)} />;
};
FormContent.propTypes = {
  full: PropTypes.bool,
  compact: PropTypes.bool,
  children: PropTypes.any,
};
