/* eslint-disable jsdoc/valid-types */
/**
 * @module Breadcrumbs
 */
import { Box, Breadcrumbs as MuiBreadcrumbs, Link, Typography } from '@mui/material';
import { titleCase } from 'helpers/title-case';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation } from 'react-router-dom';

interface PathProp {
  /** Removes this path from the Breadcrumbs component. */
  disabled?: boolean;
  /** Title of the page to replace the default title. */
  title?: string;
}

/** Path that PathProps should apply to. */
type Path = string | number;

interface Props {
  /** Record of Paths with PathProps to apply overrides to. */
  pathProps?: Record<Path, PathProp>;
}

// eslint-disable-next-line jsdoc/require-param
/**
 * **Please ensure breadcrumbs are localized.**
 * Generates a breadcrumb trail based on the current route.
 * Use `pathTitles` to override any path segments.
 *
 * @alias module:Breadcrumbs
 *
 * @returns {React.ReactElement} - The Breadcrumbs component.
 *
 * @example
 * // Route: plans/1234
 * import { useParams } from 'react-router-dom'
 * import { Breadcrumbs } from 'components/Navigation/breadcrumbs'
 *
 * function MyComponent() {
 *
 *   const { planId } = useParams()
 *
 *   return (
 *     <Breadcrumbs
 *       pathProps={{
 *         [planId]: {
 *           title: 'Edit' // Note the brackets. This equates to `'1234': 'Edit'`.
 *         },
 *       }}
 *     />
 *   )
 * }
 *
 * // Output: Plans / Edit
 */
export function Breadcrumbs({ pathProps }: Props) {
  const { pathname } = useLocation();
  const { t } = useTranslation('common');

  const paths = React.useMemo(() => {
    const pathArray = pathname.split('/').filter(path => path.length);

    const processedPaths = pathArray
      .map((path, index) => ({
        name: pathProps?.[path] ? pathProps[path].title : titleCase(path.split('-').join(' ')),
        route: pathArray.slice(0, index + 1).join('/'),
        disabled: Boolean(pathProps?.[path]?.disabled),
      }))
      // Remove paths that are disabled. This can be helpful in cases where the route has a path
      // that makes the URL more clear and readable but isn't a route you'd want the user to land
      // on. For example, in the URL `/plans/:planId/days/:dayId/`, the `days` path could be
      // disabled because that is only informational and not an actual path for a user to land on.
      .filter(path => !path?.disabled);
    return processedPaths;
  }, [pathProps, pathname]);

  return (
    <Box mb={1}>
      <MuiBreadcrumbs aria-label={t('breadcrumb')}>
        {paths?.map((path, index) => {
          // Last item in array path will render just as text instead of a link. This is because the last item
          // should be their location and we do not want to redirect to the current location.
          if (index === paths.length - 1) {
            return (
              <Typography color='textPrimary' key={path.route}>
                {path.name}
              </Typography>
            );
          }

          return (
            <Link color='inherit' component={RouterLink} key={path.route} to={`/${path.route}`}>
              {path.name}
            </Link>
          );
        })}
      </MuiBreadcrumbs>
    </Box>
  );
}
