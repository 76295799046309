/**
 * @module exportPlan
 */
/* eslint-disable import/prefer-default-export */
import { useMutation } from '@tanstack/react-query';
import { Plan } from 'components/Plans/types';
import { fetchClient } from 'helpers/transport';

interface Props {
  id: number;
}

const exportPlan = async ({ id }: Props): Promise<Plan.ExportPlan | undefined> => {
  try {
    const response = await fetchClient<{ plan_export: Plan.ExportPlan }>(`/v1/plans/${id}/exports`, {
      body: JSON.stringify({
        plan_export: {
          plan_id: id,
        },
      }),
      method: 'POST',
    });
    return response.parsedBody.plan_export;
  } catch (error) {
    if (error instanceof Response) {
      const jsonResponse = await error.json();
      throw new Error(jsonResponse.message ?? 'Error Exporting Plan');
    }
    if (error instanceof Error) {
      throw new Error(error.message);
    }
  }
};

export const useExportPlan = () => useMutation(exportPlan);
