/**
 * @module NewForm
 */
import { Box, Checkbox, FormControlLabel, MenuItem, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { yvRed } from '@youversion/react/styles/colors-v3';
import { useGetLanguages } from 'api/languages';
import MuiLoadingButton from 'components/Buttons/mui-loading-button';
import { Form, useFormikContext } from 'formik';
import { API_STATUS } from 'helpers';
import { titleCase } from 'helpers/title-case';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getLocalizedLanguage } from 'utils/get-localized-language/getLocalizedLanguage';

const useStyles = makeStyles(theme => ({
  // TODO move this to a global css
  '@global': {
    '.btn-yv-red:disabled': {
      backgroundColor: `${yvRed}5a`, // 35% Opacity
    },
  },
  languageInput: {
    textAlign: 'left',
    marginBlockEnd: theme.spacing(2),
  },
  planName: {
    marginBlockEnd: theme.spacing(1),
    '& p': {
      marginInlineStart: '0',
    },
  },
}));

interface FormValues {
  languageId: string | number;
  planTitle: string;
}

export default function CreatePlanForm({ status }: { status: string }) {
  const classes = useStyles();
  const { t, i18n } = useTranslation('plans');
  const { data: languages } = useGetLanguages();
  const { handleChange, values, setFieldValue } = useFormikContext<FormValues>();
  const isLoading = status === API_STATUS.LOADING;

  const { languageId, planTitle } = values;

  const [autoCapitalizeTitle, setAutoCapitalizeTitle] = useState(false);

  function capitalizePlanTitle(planName: string) {
    if (planName) {
      return titleCase(planName);
    }
    return planName;
  }

  function handleCheckAutoCapitalizeTitle() {
    setAutoCapitalizeTitle((prev: boolean) => !prev);
    setFieldValue('planTitle', capitalizePlanTitle(planTitle));
  }

  return (
    <Form>
      <Box display='flex' flexDirection='column'>
        <TextField
          className={classes.languageInput}
          defaultValue={t('col_header.language')}
          fullWidth={true}
          id='language'
          label={t('col_header.language')}
          onChange={event => setFieldValue('languageId', event.target.value)}
          required={true}
          select={true}
          value={languageId || ''}
          variant='outlined'
        >
          {languages
            ? Object.values(languages).map(option => (
                <MenuItem key={option.id} value={option.id}>
                  {getLocalizedLanguage(option.code, i18n.language)}
                </MenuItem>
              ))
            : []}
        </TextField>
        <TextField
          className={classes.planName}
          fullWidth={true}
          helperText={t('create_plan_title_helper')}
          id='planTitle'
          label={t('label.plan_title')}
          onChange={event => {
            const cloneEvent = event;
            if (languageId === 1 || Boolean(autoCapitalizeTitle)) {
              cloneEvent.target.value = capitalizePlanTitle(event.target.value);
            }
            handleChange(cloneEvent);
          }}
          required={true}
          value={planTitle ?? ''}
          variant='outlined'
        />
        {Boolean(languageId) && languageId !== 1 && (
          <FormControlLabel
            control={
              <Checkbox
                checked={autoCapitalizeTitle}
                color='primary'
                name='autoCapitalizeTitle'
                onChange={handleCheckAutoCapitalizeTitle}
              />
            }
            label={t('label.auto_cap_plan_title')}
          />
        )}
      </Box>
      <Box mt={1}>
        <MuiLoadingButton
          className='btn-yv-red'
          color='secondary'
          disabled={!languageId || !planTitle}
          isLoading={isLoading}
          size='large'
          type='submit'
          variant='contained'
        >
          {t('button.create_plan')}
        </MuiLoadingButton>
      </Box>
    </Form>
  );
}
