export const normalizeUrlProtocol = (value: string) => {
  const httpStr = 'http';
  const httpsStr = 'https';

  if (value.length < httpsStr.length) {
    return value;
  }
  if (value.substring(0, httpStr.length) !== httpStr && value.substring(0, httpsStr.length) !== httpStr) {
    return `${httpStr}://${value}`;
  }
  return value;
};
