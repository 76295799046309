import { useQuery } from '@tanstack/react-query';
import { BIBLE_VERSIONS, ENVIRONMENT, QUERY_KEYS } from 'helpers';

export interface BibleVersionOutput {
  abbreviation: string;
  audio: boolean;
  audio_count: number;
  books: Array<{
    abbreviation: string;
    audio: boolean;
    canon: string;
    chapters: Array<{
      canonical: boolean;
      human: string;
      toc: boolean;
      usfm: string;
    }>;
    human: string;
    human_long: string;
    text: boolean;
    usfm: string;
  }>;
  id: number;
  language: {
    iso_639_1: string;
    iso_639_3: string;
    language_tag: string;
    local_name: string;
    name: string;
    secondary_language_tags: null;
    text_direction: 'ltr' | 'rtl';
  };
  local_abbreviation: string;
  local_title: string;
  text: boolean;
  title: string;
}

export async function getBibleVersion(lang: string) {
  const bibleVersionCode = BIBLE_VERSIONS[lang] ?? BIBLE_VERSIONS.en;
  const baseUrl = ENVIRONMENT === 'production' ? 'https://webproxy.bible.com' : 'https://webproxy-staging.bible.com';

  const response = await fetch(`${baseUrl}/bible/version.json?id=${bibleVersionCode}`);

  const res = await response.json();
  return res.response.data as BibleVersionOutput;
}

export const useGetBibleVersion = (lang: string) =>
  useQuery([QUERY_KEYS.BIBLE_VERSIONS, lang], () => getBibleVersion(lang));
