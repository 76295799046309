/**
 * @module CreateApiMiddleware
 */

/**
 * Middleware that wraps `redux-pack` so that we may provide an API client factory.
 */
export default function createApiMiddleware(createClient) {
  return ({ dispatch, getState }) => {
    const client = createClient(dispatch, getState);

    return next => action => {
      if (typeof action.api === 'function') {
        // Stop the middleware chain, continue on with redux-pack,
        const { api: unused, ...newAction } = action;
        return dispatch({
          ...newAction,
          promise: action.api(client, getState),
        });
      }

      return next(action);
    };
  };
}
