import { Backdrop, Box, CircularProgress, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import bibleLensLoadingLottie from 'assets/lottie/bible-lens-loading.min.json';
import Lottie from 'lottie-react';

interface LoaderProps {
  /**
   * Determines if the sidebar should be under the overlay.
   * This can be very helpful in situations where the user should wait and not leave the page.
   */
  coverSidebar?: boolean;
  /** Disables the user's ability to scroll on the page under the overlay while it's loading. */
  disableScroll?: boolean;
  /** The message to display while loading. */
  message?: string;
  /**
   * Shows the Bible Lens animation instead of the MUI Circular Progress component.
   * Use when loading is more than a couple of seconds. */
  showBibleAnimation?: boolean;
}

const useStyles = makeStyles(theme =>
  createStyles({
    '@global': {
      body: {
        overflowY: ({ disableScroll }: LoaderProps) => (disableScroll ? 'hidden' : null),
      },
    },
    backdrop: {
      color: '#fff',
      zIndex: ({ coverSidebar }: LoaderProps) => (coverSidebar ? theme.zIndex.drawer + 1 : 999),
    },
    message: {
      marginBlockStart: theme.spacing(2),
    },
  })
);

/**
 * The Loader Overlay Component.
 */
const LoaderOverlay = ({
  coverSidebar = false,
  disableScroll = false,
  message = '',
  showBibleAnimation = false,
}: LoaderProps) => {
  const classes = useStyles({ coverSidebar, disableScroll });

  return (
    <Backdrop className={classes.backdrop} open={true}>
      <Box alignItems='center' display='flex' flexDirection='column' justifyContent='center'>
        {showBibleAnimation ? (
          <Lottie
            animationData={bibleLensLoadingLottie}
            data-testid='lottie-bible-lens-icon'
            style={{ height: 100, width: 100 }}
          />
        ) : (
          <CircularProgress color='inherit' data-testid='circular-progress-icon' />
        )}
        {message ? (
          <Box maxWidth={400} width='100%'>
            <Typography align='center' className={classes.message} color='inherit' variant='body1'>
              {message}
            </Typography>
          </Box>
        ) : null}
      </Box>
    </Backdrop>
  );
};

export default LoaderOverlay;
