import { MenuItem, Select } from '@mui/material';
import { SelectInputProps } from '@mui/material/Select/SelectInput';
import { useGetLanguages } from 'api/languages';
import { useTranslation } from 'react-i18next';
import { getLocalizedLanguage } from 'utils/get-localized-language/getLocalizedLanguage';

interface Props {
  disabled: boolean;
  planLanguageId: number;
  updatePlanDetails: (planDetailToUpdate: Record<string, any>) => void;
}

export default function LanguageField({ planLanguageId, disabled, updatePlanDetails }: Props) {
  const { data: languages } = useGetLanguages();
  const { t, i18n } = useTranslation('plans');

  const handleUpdatePlanLanguage: SelectInputProps<{}>['onChange'] = event => {
    const languageId = event.target.value;
    updatePlanDetails({ language_id: Number(languageId) });
  };
  return (
    <Select
      aria-label={t('edit_plan_info.plan_language')}
      disabled={disabled}
      fullWidth={true}
      label={t('edit_plan_info.plan_language')}
      onChange={handleUpdatePlanLanguage}
      value={planLanguageId ?? ''}
      variant='outlined'
    >
      <MenuItem value=''>{t('edit_plan_info.choose_plan_language')}</MenuItem>
      {languages
        ? Object.values(languages).map(lang => (
            <MenuItem key={lang.id} value={lang.id}>
              {getLocalizedLanguage(lang.code, i18n.language)}
            </MenuItem>
          ))
        : null}
    </Select>
  );
}
