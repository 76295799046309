/* eslint-disable import/prefer-default-export */
/**
 * @module Row
 */
import styles from 'components/Reports/GlobalReps/Pages/Styles.module.scss';
import moment from 'moment';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  record: PartnerRecord;
}
export const Row: FC<Props> = ({ record }) => {
  return (
    <tr>
      <td />
      <td>
        <Link to={`/organizations/${record.org_id}`}>{record.org_name}</Link>
      </td>
      <td>{moment(record.org_created_at).format('LL')}</td>
      <td className={styles.plansColumn}>{record.org_plans_count}</td>
    </tr>
  );
};
