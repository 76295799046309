/* eslint-disable jsdoc/require-param */
import { useMutation, useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from 'helpers/constants';
import { fetchClient } from 'helpers/transport';
import { NumberLike } from 'types/misc.d';

const ACTIVITIES_ITEM_BASE_URL = '/v1/activity/items';

/**
 * List activity items.
 *
 * @returns {Promise<Array<object>>} - An array of all activity items.
 */
export async function getActivityItems(page: NumberLike) {
  const response = await fetchClient<{
    activity_items: Array<ActivityItem>;
    meta: Meta;
  }>(`${ACTIVITIES_ITEM_BASE_URL}?page=${page}`);

  const res = response.parsedBody;
  const { activity_items: activityItems, meta } = res;

  return {
    activityItems,
    meta,
  };
}

export const useGetActivityItems = (page: NumberLike) =>
  useQuery([QUERY_KEYS.ACTIVITY_ITEMS, page], () => getActivityItems(page));

/**
 * Acknowledge activity.
 *
 * @returns {Promise<object>} - The acknowledged activity.
 */
export async function acknowledgeItem(itemId: NumberLike) {
  const response = await fetchClient<{ activity_item: ActivityItem }>(
    `${ACTIVITIES_ITEM_BASE_URL}/${itemId}/acknowledgment`,
    {
      method: 'POST',
    }
  );

  return response.parsedBody.activity_item;
}

export const useAcknowledgeActivityItem = () => useMutation(acknowledgeItem);

/**
 * Unacknowledge activity.
 *
 * @returns {Promise<object>} - The acknowledged activity.
 */
export async function unAcknowledgeItem(itemId: NumberLike) {
  const response = await fetchClient<{ activity_item: ActivityItem }>(
    `${ACTIVITIES_ITEM_BASE_URL}/${itemId}/acknowledgment`,
    {
      method: 'DELETE',
    }
  );

  return response.parsedBody.activity_item;
}
export const useUnAcknowledgeActivityItem = () => useMutation(unAcknowledgeItem);
