import { useMutation, useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from 'helpers';
import { cleanParams } from 'helpers/remove-nulls';
import { fetchClient } from 'helpers/transport';
import { NumberLike } from 'types/misc';

import { FetchUsers } from './types';

export async function getUser(userId?: NumberLike): Promise<User | undefined> {
  try {
    const response = await fetchClient<{ user: User }>(`/users/${userId}`);
    return response.parsedBody.user;
  } catch (error) {
    if (error instanceof Response) {
      throw new Error('UserNotFound');
    }
    if (error instanceof Error) {
      throw new Error(error.message);
    }
  }
}

export const useGetUser = (userId?: NumberLike) => useQuery([QUERY_KEYS.USERS, userId], () => getUser(userId));

export interface GetUsersProps {
  filters?: FetchUsers.Filter;
  pagination?: Pagination;
  sorter?: Sorter;
}
export interface GetUsersResponse {
  meta: FetchUsers.UsersMeta;
  users: Array<User>;
}
/**
 * GET all users based on a query.
 *
 * @throws {Error} - Throws an error if there's a problem with the API response.
 *
 * @returns {Promise<object>} - The user's data.
 */
async function getUsers({ pagination, sorter, filters }: GetUsersProps): Promise<GetUsersResponse | undefined> {
  const removeAllNullableValues = cleanParams({
    per: pagination?.per,
    page: pagination?.page,
    ...sorter,
    ...filters,
  });
  const params = new URLSearchParams(removeAllNullableValues);
  try {
    const response = await fetchClient<GetUsersResponse>(`/users?${params.toString()}`);

    return response.parsedBody;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    }
  }
}

export const useGetUsers = (query: GetUsersProps) => useQuery([QUERY_KEYS.USERS, query], () => getUsers(query));

export async function getStaffRepresentatives() {
  const response = await fetchClient<Array<StaffRepresentative>>('/representatives');
  return response.parsedBody;
}

export const useGetStaffRepresentatives = () => useQuery([QUERY_KEYS.STAFF_REPRESENTATIVES], getStaffRepresentatives);

interface UpdateUserProps {
  email?: string;
  first_name?: string;
  last_name?: string;
  password?: string;
  password_confirmation?: string;
  profile_photo_id?: NumberLike;
}

export async function updatetUser(props: UpdateUserProps) {
  const response = await fetchClient<{ user: User }>('/account', {
    method: 'PATCH',
    body: JSON.stringify({ user: props }),
  });
  return response.parsedBody.user;
}

export const useUpdateUser = () => useMutation(updatetUser);
