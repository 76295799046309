import c from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

ClassWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  wrapClass: PropTypes.node.isRequired,
  className: PropTypes.string,
};
export default function ClassWrapper(props) {
  const { className, children, wrapClass, ...rest } = props;

  return (
    <div className={c(className, wrapClass)} {...rest}>
      {children}
    </div>
  );
}
