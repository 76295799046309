import { Box, Typography } from '@mui/material';
import { useGetOrganizations } from 'api/organizations';
import { Loader } from 'components';
import { Filters } from 'components/Organizations/Filtering';
import { OrganizationDataGrid } from 'components/Organizations/OrganizationDataGrid';
import { API_STATUS, ORGS_PER_PAGE } from 'helpers';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
  switchActiveOrg as switchActiveOrgAction,
  viewAllOrgs as viewAllOrgsAction,
} from 'state/actions/organizations';
import { State } from 'state/reducers';
import { activeOrgIdSelector, viewingAllOrgsSelector } from 'state/selectors';

interface OrganizationsComponentProps {
  /** The active organization id. */
  activeOrganizationId: string;
  /** Indicates if the active organization is "All Organizations". */
  isViewingAllOrgs: boolean;
  /** Switches active organization. */
  switchActiveOrg: (id: number) => void;
  /** Switches active organization to all organizations. */
  viewAllOrgs: VoidFunction;
}

export function OrganizationsPage({
  activeOrganizationId,
  isViewingAllOrgs,
  switchActiveOrg,
  viewAllOrgs,
}: OrganizationsComponentProps) {
  const { t } = useTranslation('organizations');
  const [query] = useSearchParams();

  const {
    data,
    status: loadingStatus,
    refetch,
  } = useGetOrganizations({
    filters: {
      plan_language: query.get('plan_language') ?? undefined,
      plans_count: query.get('plans_count') ?? undefined,
      plan_updated_date: query.get('plan_updated_date') ?? undefined,
      name: query.get('name') ?? undefined,
    },
    sorter: {
      order: query.get('order') ?? 'desc',
      sort: query.get('sort') ?? 'created_at',
    },
    pagination: {
      page: query.get('page') ?? 1,
      per: ORGS_PER_PAGE,
    },
  });

  const isLoading = loadingStatus === API_STATUS.LOADING;

  return (
    <Box mt={2}>
      {isLoading ? <Loader /> : null}
      <Typography variant='h1'>{t('organizations')}</Typography>
      <Filters />
      <Box mt={2}>
        <OrganizationDataGrid
          activeOrganizationId={activeOrganizationId}
          data={data}
          isViewingAllOrgs={isViewingAllOrgs}
          refetch={refetch}
          switchActiveOrg={switchActiveOrg}
          viewAllOrgs={viewAllOrgs}
        />
      </Box>
    </Box>
  );
}

const stateToProps = (state: State) => ({
  isViewingAllOrgs: viewingAllOrgsSelector(state),
  activeOrganizationId: activeOrgIdSelector(state),
});

const dispatchToProps = (dispatch: any) => {
  return {
    switchActiveOrg: bindActionCreators(switchActiveOrgAction, dispatch),
    viewAllOrgs: bindActionCreators(viewAllOrgsAction, dispatch),
  };
};

export const Organizations = connect(stateToProps, dispatchToProps)(OrganizationsPage);
