/* eslint-disable consistent-return */
/* eslint-disable jsdoc/require-param */
/**
 * @module planApiMethods
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import { Plan } from 'components/Plans/types';
import {
  API_ADDRESS,
  DEFAULT_HEADERS,
  DEFAULT_PLANPORTAL_HEADERS,
  getToken,
  PLAN_PORTAL_API_URL,
  QUERY_KEYS,
} from 'helpers';
import { cleanParams } from 'helpers/remove-nulls';
import { fetchClient } from 'helpers/transport';
import { NullableNumber, NullableString, NumberLike } from 'types/misc';

import { FetchPlans } from './types';

export * from './comments/index';
export * from './narrated-audio';
export * from './plan-days';

export interface GetPlansProps {
  filters?: FetchPlans.Filter;
  pagination?: Pagination;
  sorter?: Sorter;
}

export interface GetPlansResponse {
  data: Array<Plan.Plan>;
  meta: FetchPlans.PlansMeta;
}
/**
 * GET all plans based on a query.
 *
 * @throws {Error} - Throws an error if there's a problem with the API response.
 *
 * @returns {Promise<object>} - The plan's data.
 */
export async function getPlans({ pagination, sorter, filters }: GetPlansProps): Promise<GetPlansResponse | undefined> {
  const removeAllNullableValues = cleanParams({
    per: pagination?.per,
    page: pagination?.page,
    ...sorter,
    ...filters,
  });
  const params = new URLSearchParams(removeAllNullableValues);
  try {
    const authToken = await getToken();
    const response = await fetch(`${PLAN_PORTAL_API_URL}/4.0/plans?${params.toString()}`, {
      headers: DEFAULT_PLANPORTAL_HEADERS(authToken),
      method: 'GET',
    });
    return await response.json();
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    }
  }
}

export const useGetPlans = (query: GetPlansProps) => useQuery([QUERY_KEYS.ALL_PLANS, query], () => getPlans(query));

/**
 * GET the plan's data.
 *
 * @throws {Error} - Throws an error if there's a problem with the API response.
 *
 * @returns {Promise<object>} - The plan's data.
 */
export async function getPlan(planId: NumberLike): Promise<Plan.Plan | undefined> {
  try {
    const response = await fetchClient<Plan.Plan>(`/4.0/plans/${planId}`, {
      baseUrl: PLAN_PORTAL_API_URL,
    });
    return response.parsedBody;
  } catch (error) {
    if (error instanceof Response) {
      throw new Error('PlanNotFound');
    }
    if (error instanceof Error) {
      throw new Error(error.message);
    }
  }
}

export const useGetPlan = (id: NumberLike) => useQuery([QUERY_KEYS.PLAN, id], () => getPlan(id));

/**
 * Delete the plan's data.
 *
 * @throws {Error} - Throws an error if there's a problem with the API response.
 *
 * @returns {Promise<object>} - Nothing.
 */
export async function deletePlan(planId: NumberLike) {
  return fetchClient<Plan.Plan>(`/4.0/plans/${planId}`, {
    baseUrl: PLAN_PORTAL_API_URL,
    method: 'DELETE',
  });
}

export const useDeletePlan = () => useMutation(deletePlan);

export interface UpdatePlanParams {
  alternate_organization_ids?: Array<number>;
  attribution_text?: NullableString;
  categories?: Array<string>;
  description?: NullableString;
  keywords?: Array<string>;
  language_id?: number;
  large_image_id?: NullableNumber;
  launches_at?: Date;
  name?: string;
  partner_url?: NullableString;
  polly_voice_id?: number | string | null;
  primary_organization_id?: number;
  small_image_id?: NullableNumber;
  source_id?: string | number | null;
  status?: Plan.Status;
  system_status?: boolean;
  title_slug?: string;
}
interface UpdatePlanDataParams {
  plan: UpdatePlanParams;
}
/**
 * Updates a plan.
 *
 * @throws {Error} - Throws an error if there's a problem with the API response.
 *
 * @returns {Promise<object>} - The plan's data.
 */
export async function updatePlan(planId: NumberLike, data: UpdatePlanParams) {
  const response = await fetchClient<Plan.Plan>(`/4.0/plans/${planId}`, {
    baseUrl: PLAN_PORTAL_API_URL,
    body: JSON.stringify(data),
    method: 'PATCH',
  });
  return response.parsedBody;
}

export const useUpdatePlan = () =>
  useMutation(({ planId, data }: { data: UpdatePlanParams; planId: NumberLike }) => updatePlan(planId, data));

interface LinkPlanProps {
  planId: NumberLike;
  sourceId: NumberLike | null;
}

async function linkPlanLanguage({ planId, sourceId }: LinkPlanProps) {
  try {
    return await updatePlan(planId, { source_id: sourceId });
  } catch (e) {
    if (e instanceof Response) {
      const res = await e.json();
      if (res?.details?.source_id) {
        throw new Error(JSON.stringify(res?.details?.source_id));
      }

      if (res?.details?.language_id) {
        throw new Error(JSON.stringify(res?.details?.language_id));
      }

      throw new Error(res.error);
    }

    if (e instanceof Error) {
      throw e;
    }
  }
}

export const useLinkPlanLanguage = () => useMutation(linkPlanLanguage);

export const useGetTranslatableWithPlans = (translatable_with: string | undefined) => {
  const getPlansPayload = {
    filters: { translatable_with },
    sorter: {
      order: 'desc',
      sort: 'created_at',
    },
    pagination: {
      page: 1,
      per: 1000,
    },
  };

  return useQuery([QUERY_KEYS.ALL_PLANS, getPlansPayload], () => getPlans(getPlansPayload), {
    enabled: Boolean(translatable_with),
  });
};

export async function transferPlan(planId: NumberLike, data: UpdatePlanDataParams) {
  const response = await fetchClient<{ plan: Plan.Plan }>(`/v1/plans/${planId}/transfer`, {
    body: JSON.stringify(data),
    method: 'PATCH',
  });
  return response.parsedBody.plan;
}

export const useTransferPlan = () =>
  useMutation(({ planId, data }: { data: UpdatePlanDataParams; planId: NumberLike }) => transferPlan(planId, data));

export interface CreatePlanProps {
  languageId: number | string;
  organizationId: number;
  titleValue: string;
}

/**
 * POST the new plan's data.
 *
 * @throws {Error} - Throws an error if there's a problem with the API response.
 *
 * @returns {Promise<object>} - The newly created plan object.
 */
export async function createPlan({ languageId, titleValue, organizationId }: CreatePlanProps): Promise<Plan.Plan> {
  if (!languageId || !titleValue) {
    throw new Error('Plan must have both language and title.');
  }
  const response = await fetchClient<Plan.Plan>('/4.0/plans', {
    baseUrl: PLAN_PORTAL_API_URL,
    method: 'POST',
    body: JSON.stringify({
      draft_name: titleValue,
      language_id: languageId,
      primary_organization_id: organizationId,
    }),
  });
  return response.parsedBody;
}

export const useCreatePlan = () => useMutation(createPlan);

export interface CreateTranslatedPlanProps {
  contentSource: string;
  languageId: number | string;
  planId: NumberLike | undefined;
  titleValue: string;
}

/**
 * POST a new translation of an existing plan.
 *
 * @throws {Error} - Throws an error if there's a problem with the API response.
 *
 * @returns {Promise<object>} - The newly created plan object.
 */
export async function createPlanTranslation({
  planId,
  languageId,
  contentSource,
  titleValue,
}: CreateTranslatedPlanProps): Promise<Plan.Plan | undefined> {
  if (!languageId || !titleValue) {
    throw new Error('Plan must have both language and title.');
  }

  try {
    const response = await fetchClient<Plan.Plan>(`/4.0/plans/${planId}/translations`, {
      baseUrl: PLAN_PORTAL_API_URL,
      method: 'POST',
      body: JSON.stringify({
        language_id: languageId,
        content_source: contentSource,
        name: titleValue,
      }),
    });
    return response.parsedBody;
  } catch (e) {
    if (e instanceof Response) {
      const res = await e.json();
      if (res?.details?.source_id) {
        throw new Error(JSON.stringify(res?.details?.source_id));
      }

      if (res?.details?.language_id) {
        throw new Error(JSON.stringify(res?.details?.language_id));
      }

      throw new Error(res.error);
    }

    if (e instanceof Error) {
      throw e;
    }
  }
}

export const useCreatePlanTranslation = () => useMutation(createPlanTranslation);

interface GetCommentsParams {
  /** The plan day's id. */
  dayId?: string | number;
  /** The plan's id. */
  planId: string | number;
}

/**
 * GET the plan or plan day comments.
 *
 * @throws {Error} - Throws an error if the dayId is incorrect.
 *
 * @returns {Promise<Array<object>>} - The comment's data.
 *
 * @example
 * // gets plan comments
 * getComments({ planId: 12 })
 * gets plan day comments
 * getComments({ planId: 12, dayId: 13 })
 * // throws an error
 * getComments({ planId: 12, dayId: '' })
 */
export async function getComments({ dayId, planId }: GetCommentsParams): Promise<Array<Plan.Comment> | undefined> {
  let path = `plans/${planId}/comments`;

  if (dayId) {
    path = `plans/${planId}/days/${dayId}/comments`;
  }

  try {
    const authToken = await getToken();
    const response = await fetch(`${API_ADDRESS}/${path}`, {
      headers: DEFAULT_HEADERS(authToken),
      method: 'GET',
    });
    const jsonResponse = await response.json();
    return jsonResponse.comments || [];
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    }
  }
}

export const useGetComments = (props: GetCommentsParams) =>
  useQuery([QUERY_KEYS.COMMENTS, props], () => getComments(props));

/**
 * GET the categories.
 *
 * @throws {Error} - Throws an error if the dayId is incorrect.
 *
 * @returns {Promise<object>} - The categories data.
 */

export async function getCategories(): Promise<Array<Plan.ApiCategory> | undefined> {
  try {
    const authToken = await getToken();
    const response = await fetch(`${API_ADDRESS}/categories`, {
      headers: DEFAULT_HEADERS(authToken),
      method: 'GET',
    });

    const jsonResponse = await response.json();
    return jsonResponse.categories || [];
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    }
  }
}

/**
 * Submits for approval.
 *
 * @throws {Error} - Throws an error if there's a problem with the API response.
 *
 * @returns {Promise<Array>} - The submission response.
 */
export async function submitForApproval(planId: NumberLike) {
  try {
    const authToken = await getToken();
    const response = await fetch(`${PLAN_PORTAL_API_URL}/4.0/plans/${planId}/submission`, {
      headers: DEFAULT_PLANPORTAL_HEADERS(authToken),
      method: 'PUT',
      body: JSON.stringify({
        status: 'submitted',
      }),
    });

    return await response.json();
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    }
  }
}

/**
 * Approve entire plan.
 *
 * @throws {Error} - Throws an error if there's a problem with the API response.
 *
 * @returns {Promise<object>} - The response model.
 */
export async function approveEntirePlan(id: NumberLike) {
  if (!id) {
    throw new Error('`id` is required');
  }

  try {
    const authToken = await getToken();
    const response = await fetch(`${PLAN_PORTAL_API_URL}/4.0/plans/${id}/submission`, {
      body: JSON.stringify({
        status: 'approved',
      }),
      headers: DEFAULT_PLANPORTAL_HEADERS(authToken),
      method: 'PATCH',
    });

    return await response.json();
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    }
  }
}
