/* eslint-disable jsdoc/require-param */
/**
 * @module DayOptions
 */
import { ArrowBack, ArrowForward, DeleteOutline, MoreVert } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Theme,
  Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useAlert } from '@youversion/react';
import { utilityColors } from '@youversion/react/styles/colors-v3';
import { useDeletePlanDay } from 'api/plans';
import { Can } from 'auth';
import classNames from 'classnames';
import { Plan } from 'components/Plans/types';
import { usePlan } from 'context';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cancelText: {
      color: '#777A7B',
      fontWeight: 500,
      padding: theme.spacing(0.5),
    },
    dayBoxTopLeftIcon: {
      fill: theme.palette.text.secondary,
      fontSize: 16,
      insetInlineStart: 2,
      position: 'absolute',
      insetBlockStart: 2,
    },
    dayBoxTopRightIcon: {
      fill: theme.palette.text.secondary,
      fontSize: 16,
      position: 'absolute',
      insetInlineEnd: 2,
      insetBlockStart: 2,
    },
    dayMenu: {
      marginInlineStart: theme.spacing(1.5),
      marginBlockStart: theme.spacing(5),
    },
    dayMenuDeleteListItem: {
      '& .MuiListItemIcon-root': {
        marginBlockStart: theme.spacing(0.5),
      },
      alignItems: 'flex-start',
      color: utilityColors.light.alert,
    },
    dayMenuListItem: {
      '& .MuiListItemIcon-root': {
        minWidth: theme.spacing(4),
      },
    },
    deleteText: {
      color: utilityColors.light.alert,
      fontWeight: 500,
      padding: theme.spacing(0.5),
    },
    moreIcon: {
      '&:hover': {
        background: 'transparent',
      },
      insetInlineStart: '-12px',
      insetBlockStart: '-8px',
    },
    moreVertIcon: {
      fontSize: '18px !important',
    },
  })
);

interface DayOptionsProps {
  /** The day object. */
  day: Plan.Day;
  /** The day id from the param. */
  dayId?: string;
  /** The day number based on the index. */
  dayNumber: number;
  /** The day's index from the planDays array. */
  index: number;
  /** The position of the next day. */
  nextDayPosition?: number;
  /** The position of the previous day. */
  prevDayPosition?: number;
  /** The plan days of the plan for the dayscroller. */
  totalDays: number;
}

/**
 * Represents the Day options to be used for the DayScroller component.
 *
 * @returns {React.ReactElement} - The DayOptions component.
 */
export function DayOptions({
  day,
  dayId: paramDayId,
  dayNumber,
  index,
  nextDayPosition,
  prevDayPosition,
  totalDays,
}: DayOptionsProps) {
  const classes = useStyles();
  const { id: planId } = useParams();
  const { throwAlert } = useAlert();
  const { disableNewDay, setPlanDays, setDayNumber, setDayPosition, hasPlanBeenPublished } = usePlan();
  const navigate = useNavigate();
  const { mutateAsync: deletePlanDay } = useDeletePlanDay();
  const { t } = useTranslation();

  const [anchorElement, setAnchorElement] = React.useState<(EventTarget & HTMLButtonElement) | null>(null);
  const [isDialogOpen, setIsDialogOpen] = React.useState<boolean>(false);

  const open = Boolean(anchorElement);
  const isSelectedDay = Boolean(day.id.toString() === paramDayId);
  const numberOfDaysRequired = 3;

  if (hasPlanBeenPublished || !isSelectedDay || paramDayId === 'newDay') {
    return null;
  }

  function addNextDay() {
    try {
      setAnchorElement(null);
      if (!nextDayPosition || nextDayPosition === -1) {
        navigate(`/plans/${planId}/days/new`);
      } else {
        const position = (nextDayPosition + day.position) / 2;
        setDayPosition(position);
        setDayNumber(index + 2);

        setPlanDays(prevState => {
          const daysCopy = [...prevState];
          daysCopy.splice(index + 1, 0, {
            id: 'newDay',
            position,
            status: Plan.DayStatus.DRAFT,
          });
          return daysCopy;
        });
        navigate(`/plans/${planId}/days/newDay`);
      }
    } catch (error) {
      throwAlert({
        id: 'add_next_day_error',
        key: 'add_next_day_error',
        message: t('plans:day.failed_to_add_day_after', { number: dayNumber }),
        timeout: 3000,
        type: 'error',
      });
    }
  }

  function addPreviousDay() {
    try {
      if (prevDayPosition) {
        const position = (prevDayPosition + day.position) / 2;
        setAnchorElement(null);
        setDayPosition(position);
        setDayNumber(index + 1);

        setPlanDays((prevState: Array<Plan.Day>) => {
          const daysCopy = [...prevState];
          daysCopy.splice(index, 0, {
            id: 'newDay',
            position,
            status: Plan.DayStatus.DRAFT,
          });
          return daysCopy;
        });
        navigate(`/plans/${planId}/days/newDay`);
      }
    } catch (error) {
      throwAlert({
        id: 'add_previous_day_error',
        key: 'add_previous_day_error',
        message: t('plans:day.failed_to_add_day_before', { number: dayNumber }),
        timeout: 3000,
        type: 'error',
      });
    }
  }

  async function handleDeleteDay() {
    try {
      if (paramDayId && planId) {
        await deletePlanDay({
          dayId: paramDayId,
          planId,
        });
        window.location.replace(`${window.location.origin}/plans/${planId}`);
      }
    } catch (error) {
      throwAlert({
        id: 'delete_day_error',
        key: 'delete_day_error',
        message: t('plans:day.failed_delete_day', { number: dayNumber }),
        timeout: 3000,
        type: 'error',
      });
    }
  }

  function handleMoreIconClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    setAnchorElement(event.currentTarget);
  }

  function handleDialogClose() {
    setIsDialogOpen(false);
  }

  return (
    <>
      <IconButton
        aria-label={t('plans:day.toggle_day_menu')}
        className={classNames(classes.dayBoxTopLeftIcon, classes.moreIcon)}
        data-day={dayNumber}
        disableRipple={true}
        onClick={handleMoreIconClick}
        size='large'
      >
        <MoreVert className={classes.moreVertIcon} />
      </IconButton>
      <Menu
        anchorEl={anchorElement}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        className={classes.dayMenu}
        id={`day-${dayNumber}-menu`}
        keepMounted={true}
        onClose={() => setAnchorElement(null)}
        open={open}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
      >
        <MenuItem
          className={classes.dayMenuListItem}
          data-day={dayNumber}
          disabled={disableNewDay}
          key={`menu-item-delete-plan-forward-${dayNumber}`}
          onClick={addNextDay}
        >
          <ListItemIcon>
            <ArrowForward fontSize='small' />
          </ListItemIcon>
          <ListItemText>{t('plans:day.add_day_right')}</ListItemText>
        </MenuItem>
        <MenuItem
          className={classes.dayMenuListItem}
          data-day={dayNumber}
          disabled={disableNewDay}
          key={`menu-item-add-plan-day-back-${dayNumber + 1}`}
          onClick={addPreviousDay}
        >
          <ListItemIcon>
            <ArrowBack fontSize='small' />
          </ListItemIcon>
          <ListItemText>{t('plans:day.add_day_left')}</ListItemText>
        </MenuItem>
        {totalDays >= numberOfDaysRequired ? (
          <Can user='delete_unpublished:plan_day'>
            <MenuItem
              className={classNames(classes.dayMenuDeleteListItem, classes.dayMenuListItem)}
              data-day={dayNumber}
              key={`menu-item-delete-plan-day-${dayNumber + 1}`}
              onClick={() => setIsDialogOpen(true)}
            >
              <ListItemIcon>
                <DeleteOutline className={classes.dayMenuDeleteListItem} fontSize='small' />
              </ListItemIcon>
              <ListItemText>
                <Typography>{t('plans:day.delete_day')}</Typography>
                <Typography color='textSecondary' variant='caption'>
                  <>{t('plans:day.cant_be_reversed_br')}</>
                </Typography>
              </ListItemText>
            </MenuItem>
          </Can>
        ) : null}
      </Menu>

      <Dialog onClose={handleDialogClose} open={isDialogOpen}>
        <DialogTitle>{t('plans:day.delete_day_number', { number: dayNumber })}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('plans:day.cant_be_reversed')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>
            <Typography className={classes.cancelText}>{t('plans:day.cancel')}</Typography>
          </Button>
          <Button onClick={handleDeleteDay}>
            <Typography className={classes.deleteText}>{t('plans:day.delete')}</Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

DayOptions.defaultProps = {
  dayId: null,
  nextDayPosition: -1,
  prevDayPosition: -1,
};
