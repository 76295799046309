/**
 * @module PlanDayEdit
 */
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { Box, Button, CircularProgress, IconButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ConfirmDialog } from '@youversion/mui-block-editor';
import FileDropArea from '@youversion/mui-file-droparea';
import { LazyImage, RoundedButton, useAlert } from '@youversion/react';
import { utilityColors } from '@youversion/react/styles/colors-v3';
import { statusTypes } from '@youversion/utils';
import { uploadFile, useAttachFile } from 'api/active-storage';
import { getPlanDay, getPlanDays, useDeleteNarratedAudio, useUpdatePlanDay } from 'api/plans';
import basicPicture from 'assets/basic_picture.png';
import BibleReferences from 'components/bible/bible-references';
import MuiLoadingButton from 'components/Buttons/mui-loading-button';
import LoaderOverlay from 'components/LoaderOverlay';
import DevoContentWrapper from 'components/Plans/devotional-content-wrapper';
import { Plan } from 'components/Plans/types';
import { usePlan } from 'context';
import { urlForImageAsset } from 'helpers';
import { API_STATUS, IMAGEPROXY_URL, QUERY_PARAMS } from 'helpers/constants';
import { BlockError, validateBlocks, validateReferences } from 'helpers/content-blocks/validation';
import { planHasNarratedAudio } from 'helpers/plan-has-narrated-audio';
import _ from 'lodash';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

const useStyles = makeStyles({
  iconProgress: {
    insetInlineStart: '50%',
    marginInlineStart: -12,
    marginBlockStart: -12,
    position: 'absolute',
    insetBlockStart: '50%',
  },
  iconSpinner: {
    fontSize: '0 !important',
    position: 'relative',
  },
  required: {
    color: utilityColors.light.alert,
  },
  secondaryText: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  smallImage: {
    backgroundColor: 'rgb(245, 245, 245)',
    borderRadius: '8px',
  },
});

interface DayMeta {
  number: number;
}

interface PlanMeta extends Plan.PlanMeta {
  hasNarratedAudio?: boolean;
  length?: number;
  smallImageUrl?: string;
}

/**
 * The PlanDayEdit component.
 *
 * @alias module:PlanDayEdit
 *
 * @returns {React.ReactElement} - The PlanDayEdit component.
 */
export default function PlanDayEdit() {
  const { t } = useTranslation(['plan_days', 'common']);
  const navigate = useNavigate();
  const { id: planId, dayId } = useParams();
  const classes = useStyles();
  const { plan, loadingStatus: planLoadingStatus } = usePlan();
  const { mutateAsync: attachNarratedAudio } = useAttachFile();
  const { mutateAsync: deleteNarratedAudio } = useDeleteNarratedAudio();
  const { mutateAsync: updatePlanDay, status: savingStatus } = useUpdatePlanDay();
  const { refreshPlanData } = usePlan();

  const [loadingStatus, setLoadingStatus] = React.useState(statusTypes.PENDING);
  const [planMeta, setPlanMeta] = React.useState<PlanMeta>();
  const [dayMeta, setDayMeta] = React.useState<DayMeta>();
  const [blocks, setBlocks] = React.useState<Array<Plan.DevotionalContentBlocks>>([]);
  const [bibleReferences, setBibleReferences] = React.useState<Array<string>>([]);
  const [narratedAudio, setNarratedAudio] = React.useState<
    (Plan.NarratedAudioAttachment & { delete?: boolean }) | null
  >();
  const [touched, setTouched] = React.useState(false);
  const [showRouteLeaveDialog, setShowRouteLeaveDialog] = React.useState(false);

  const { throwAlert, clearAlerts } = useAlert();

  function handleQueueDeleteNarratedAudio() {
    setNarratedAudio(prevState => {
      if (prevState) {
        return { ...prevState, delete: true };
      }
      return prevState;
    });
  }

  async function handleSave() {
    clearAlerts();

    const contentBlockErrors = validateBlocks(blocks);
    const bibleReferenceErrors = validateReferences(bibleReferences);

    if (bibleReferenceErrors.length || contentBlockErrors.length) {
      bibleReferenceErrors.forEach(error => {
        throwAlert({
          id: Object.keys(error)[0],
          key: Object.keys(error)[0],
          message: Object.values(error)[0],
          timeout: 5000,
          type: statusTypes.ERROR,
        });
      });

      contentBlockErrors.forEach(error => {
        throwAlert({
          id: error.blockId,
          key: error.blockId,
          message: (
            <>
              {t('plan_days:validation.devotional_content')}
              <ul>
                {Object.keys(error.blockErrors).map(key => {
                  return <li key={key}>{error.blockErrors[key as keyof BlockError]}</li>;
                })}
              </ul>
            </>
          ),
          timeout: 5000,
          type: statusTypes.ERROR,
        });
      });
      return;
    }
    try {
      const data = {
        references: bibleReferences,
        devotional_content_blocks: blocks,
      };

      if (dayId && planId) {
        await updatePlanDay({ data, dayId, planId });
        refreshPlanData();
      }

      if (narratedAudio?.delete) {
        await deleteNarratedAudio(narratedAudio.id);
      }

      navigate(`/plans/${planId}/days/${dayId}`);
    } catch (error) {
      if (error instanceof Error) {
        throwAlert({
          id: 'plan_day_save_error',
          key: 'plan_day_save_error',
          message: t('plan_days:plan_day_edit.error_saving_plan_day', {
            message: error.message,
          }),
          timeout: 5000,
          type: statusTypes.ERROR,
        });
      }
    }
  }

  async function handleUploadNarratedAudio(files: Array<File>) {
    if (narratedAudio?.delete) {
      await deleteNarratedAudio(narratedAudio.id);
    }
    const { signedId } = await uploadFile(files[0]);
    if (dayId && planMeta) {
      const attachmentResponse = await attachNarratedAudio({
        fileType: 'narrated_audio',
        dayId: Number(dayId),
        organizationId: planMeta.primaryOrganizationId,
        signedId,
      });
      setNarratedAudio(attachmentResponse);
    }
    setTouched(true);
  }

  React.useEffect(() => {
    async function loadData() {
      try {
        if (planId && dayId) {
          const planDay = await getPlanDay({ planId, dayId });
          const planDays = (await getPlanDays({ planId }))?.data;

          if (planDay && planDays && 'id' in plan) {
            const sortedPlanDays = _.sortBy(planDays, 'position');

            const dayNumber = sortedPlanDays.findIndex(item => item.id === parseInt(dayId, 10)) + 1;

            const smallImageSource = plan.small_image;
            const smallImageUrl = `${IMAGEPROXY_URL}/${urlForImageAsset(smallImageSource)}`;

            // In order of preference for the most current data: Draft > Approved > Published. Fall back to empty array if null.
            setBlocks(planDay.devotional_content_blocks || []);
            setBibleReferences(planDay.references || []);
            setDayMeta({
              number: dayNumber,
            });
            setPlanMeta({
              hasNarratedAudio: planHasNarratedAudio(plan),
              id: planId,
              isPublishable: plan.publishable,
              length: planDays.length,
              name: plan.name,
              primaryOrganizationId: plan.primary_organization_id,
              smallImageUrl,
              status: plan.overall_status,
            });
            setNarratedAudio(planDay.narrated_audio_attachment);
          }
        }

        setLoadingStatus(statusTypes.RESOLVED);
      } catch (error) {
        if (error instanceof Error) {
          throwAlert({
            id: 'plan_day_load_error',
            key: 'plan_day_load_error',
            message: t('plan_days:plan_day_edit.error_loading_plan_day', {
              message: error.message,
            }),
            timeout: 5000,
            type: statusTypes.ERROR,
          });
        }
        setLoadingStatus(statusTypes.REJECTED);
      }
    }
    if (loadingStatus === statusTypes.PENDING && planLoadingStatus === API_STATUS.SUCCESS) {
      loadData();
    }
  }, [dayId, loadingStatus, planId, throwAlert, plan, planLoadingStatus, t]);

  function handleSetBibleReferences(bibleRefs: Array<string>) {
    if (!touched) {
      setTouched(true);
    }
    setBibleReferences(bibleRefs);
  }

  function handleSetBlocks(updatedBlocks: Array<Plan.DevotionalContentBlocks>) {
    // Blocks are set within the BlockEditor component, while the state is synced back upstream to this parent component. Since this function runs to sync the blocks state, it becomes very hard to accurately know when the state of blocks is actually changed. Here's a simple solution that lets me know when blocks state actually changes.
    setBlocks(prevBlocks => {
      // If block state hasn't changed, return the previous blocks.
      if (JSON.stringify(prevBlocks) === JSON.stringify(updatedBlocks)) {
        return prevBlocks;
      }

      // This is only run if the blocks have been changed.
      if (!touched) {
        setTouched(true);
      }

      return updatedBlocks;
    });
  }

  function handleRouteLeaveConfirm() {
    navigate(-1);
  }

  function handleShouldShowRouteLeaveDialog() {
    if (touched) {
      setShowRouteLeaveDialog(true);
    } else {
      navigate(-1);
    }
  }

  const buttonLabel = React.useMemo(() => {
    if (savingStatus === API_STATUS.IDLE) {
      return t('plan_days:plan_day_edit.save_button.idle');
    }

    if (savingStatus === API_STATUS.LOADING) {
      return t('plan_days:plan_day_edit.save_button.loading');
    }

    if (savingStatus === API_STATUS.ERROR) {
      return t('plan_days:plan_day_edit.save_button.error');
    }
  }, [savingStatus, t]);

  if (loadingStatus === statusTypes.PENDING || planLoadingStatus === API_STATUS.LOADING || !planMeta || !dayMeta) {
    return <LoaderOverlay />;
  }

  return (
    <>
      <ConfirmDialog
        confirmButtonLabel={t('plan_days:plan_day_edit.confirm_dialog.label')}
        content={t('plan_days:plan_day_edit.confirm_dialog.content')}
        denyButtonLabel={t('common:cancel')}
        onClose={() => setShowRouteLeaveDialog(false)}
        onConfirm={handleRouteLeaveConfirm}
        onDeny={() => setShowRouteLeaveDialog(false)}
        open={showRouteLeaveDialog}
      />
      {loadingStatus === statusTypes.RESOLVED ? (
        <>
          <Box display='flex' justifyContent='space-between' maxWidth={800} mt={4}>
            <Box mb={2}>
              <Typography variant='h1'>
                {t('plan_days:plan_day_edit.title', {
                  count: dayMeta.number,
                })}
              </Typography>
            </Box>
            <Box display='flex'>
              <Box>
                <MuiLoadingButton
                  color='primary'
                  disabled={savingStatus === API_STATUS.LOADING}
                  onClick={handleSave}
                  startIcon={
                    <span className={classes.iconSpinner}>
                      {savingStatus === statusTypes.PENDING ? (
                        <CircularProgress className={classes.iconProgress} size={24} />
                      ) : null}

                      <SaveIcon />
                    </span>
                  }
                  variant='contained'
                >
                  {buttonLabel}
                </MuiLoadingButton>
              </Box>
              <Box ml={1}>
                <Button color='secondary' onClick={handleShouldShowRouteLeaveDialog} variant='contained'>
                  {t('common:cancel')}
                </Button>
              </Box>
            </Box>
          </Box>

          <Box alignItems='center' display='flex' maxWidth={800} mb={4}>
            {planMeta?.smallImageUrl ? (
              <LazyImage
                className={classes.smallImage}
                fallback={<img alt='' src={basicPicture} />}
                height={64}
                src={planMeta?.smallImageUrl}
                width={64}
              />
            ) : null}

            <Box ml={2}>
              <Typography component='p' variant='h3'>
                {planMeta.name}
              </Typography>
              <Typography variant='subtitle1'>
                {t('plan_days:plan_day_edit.plan_day_count.day', {
                  count: planMeta.length,
                })}
              </Typography>
            </Box>
          </Box>

          <Box maxWidth={800}>
            {!planMeta.hasNarratedAudio ? (
              <Box mb={2}>
                <Typography variant='subtitle1'>
                  <Trans
                    components={{
                      a: (
                        // eslint-disable-next-line jsx-a11y/anchor-has-content
                        <a href={`/plans/${planId}/edit?${QUERY_PARAMS.uploadAudio}`} />
                      ),
                    }}
                    i18nKey='plan_days:plan_day_edit.automated_audio_notice'
                    t={t}
                  />
                </Typography>
              </Box>
            ) : null}

            <Box mb={4}>
              <Box mb={1}>
                <Typography variant='h3'>
                  <Trans
                    components={{
                      span: <span className={classes.required} />,
                    }}
                    i18nKey='plan_days:plan_day_edit.bible_reference.title'
                    t={t}
                  />
                </Typography>
                {!bibleReferences.length ? (
                  <Typography variant='subtitle1'>
                    {t('plan_days:plan_day_edit.bible_reference.no_bible_references')}
                  </Typography>
                ) : null}
              </Box>
              <BibleReferences onChange={handleSetBibleReferences} usfmList={bibleReferences} />
            </Box>

            {planMeta.hasNarratedAudio ? (
              <Box mb={4}>
                <Typography variant='h3'>
                  <Box alignItems='center' display='flex'>
                    <Box>{t('plan_days:plan_day_edit.narrated_audio.title')}</Box>
                    <Box alignItems='center' display='flex' ml={0.75}>
                      <VolumeUpIcon fontSize='inherit' />
                    </Box>
                  </Box>
                </Typography>
                {narratedAudio && narratedAudio?.file_url && !narratedAudio?.delete ? (
                  <Box display='flex' mt={1}>
                    <Box>
                      <audio
                        aria-label={t('plan_days:plan_day_edit.narrated_audio.narrated_audio_player.label')}
                        controls={true}
                        src={narratedAudio.file_url}
                      >
                        {t('plan_days:plan_day_edit.narrated_audio.narrated_audio_player.notice')}
                      </audio>
                    </Box>
                    <Box>
                      <IconButton
                        aria-label={t('plan_days:plan_day_edit.narrated_audio.delete_label')}
                        onClick={handleQueueDeleteNarratedAudio}
                        size='large'
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Box>
                ) : (
                  <>
                    <Typography variant='subtitle1'>
                      {t('plan_days:plan_day_edit.narrated_audio.upload_custom_audio')}
                    </Typography>
                    <Box mt={1} />
                    <FileDropArea
                      acceptedFileTypes='audio/*'
                      // RoundedButton is running on an old react version
                      button={RoundedButton as any}
                      disableUpdateOnSuccess={true}
                      onDrop={handleUploadNarratedAudio}
                    />
                  </>
                )}
              </Box>
            ) : null}

            <DevoContentWrapper blocks={blocks} onChange={handleSetBlocks} planMeta={planMeta} />
          </Box>
        </>
      ) : null}
    </>
  );
}
