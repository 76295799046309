/**
 * @module AppRoutes
 */
import { ConnectedAuthContextProvider } from 'auth';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { State } from 'state/reducers';
import { isLoggedInSelector } from 'state/selectors';

import RoutesAuthenticated from './authenticated';
import RoutesUnauthenticated from './unauthenticated';

const stateToProps = (state: State) => isLoggedInSelector(state);

interface Props {
  /** The app route changed function. */
  appRouteChanged: Function;
}
/**
 * The AppRoutes component.
 *
 * @alias module:AppRoutes
 *
 * @returns {React.ReactElement} - The AppRoutes component.
 */
function AppRoutes({ appRouteChanged }: Props) {
  const { pathname } = useLocation();
  const isAuthenticated = useSelector(stateToProps);

  React.useEffect(() => {
    appRouteChanged();
  }, [appRouteChanged, isAuthenticated, pathname]);

  return (
    <>
      {isAuthenticated ? (
        <ConnectedAuthContextProvider>
          <RoutesAuthenticated />
        </ConnectedAuthContextProvider>
      ) : (
        <RoutesUnauthenticated />
      )}
    </>
  );
}

export default AppRoutes;
